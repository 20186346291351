import { ReactNode } from "react";
import { Box } from "../box";
import { Typography } from "../typography";
import { useTheme } from "../theme";

import { ReactComponent as ErrorSvg } from "../assets/img/error.svg";

export const Error = (props: { errorMessage?: string | ReactNode; errorTitle?: string }): JSX.Element => {
  const theme = useTheme();
  const { errorMessage, errorTitle } = props;
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",

        "& svg": {
          maxWidth: "100%",
          height: "auto",
        },

        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
        },

        "@keyframes blink-1": {
          "0%": {
            opacity: 1,
          },
          "25%": {
            opacity: 0,
          },
          "75%": {
            opacity: 0,
          },
          "100%": {
            opacity: 1,
          },
        },
        "@keyframes blink-2": {
          "10%": {
            opacity: 1,
          },
          "30%": {
            opacity: 0.2,
          },
          "70%": {
            opacity: 0.2,
          },
          "90%": {
            opacity: 1,
          },
        },
        "@keyframes blink-3": {
          "0%": {
            opacity: 1,
          },
          "40%": {
            opacity: 0.2,
          },
          "60%": {
            opacity: 0.2,
          },
          "100%": {
            opacity: 1,
          },
        },

        ".blink": {
          animationTimingFunction: "linear",
          animationIterationCount: "infinite",
          animationDuration: "2.4s",

          "&.circle-blink": {
            animationName: "blink-1",
          },
          "&.blink-2": {
            animationName: "blink-2",
          },
          "&.blink-3": {
            animationName: "blink-3",
          },
        },
      }}
    >
      <ErrorSvg />
      <Box>
        <Typography
          sx={{
            mb: 3,
            fontSize: "32px",
            lineHeight: "40px",
            fontWeight: 600,
          }}
        >
          {errorTitle || "Something went wrong"}
        </Typography>
        <Typography sx={{ fontWeight: 400, maxWidth: "400px" }}>
          {errorMessage || "An error has occurred. Restart the procedure or reload the page."}
        </Typography>
      </Box>
    </Box>
  );
};
