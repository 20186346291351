import { Paper } from "@frontend/ui";
import { useMemo } from "react";
import { usePublicSimulationsData } from "src/pages/parameter-recommendations-page/hooks/use-public-simulations-data";
import { GetExperimentAggregationQuery } from "src/pages/parameter-recommendations-page/public-simulations-types";
import { ParameterModelResult } from "src/pages/ccar-lending-page/generated";
import ObservationsPairScatterChart from "./observations-pair-scatter-chart";
import ObservationsDistributionHistograms from "./observations-distribution-histograms";

type Props = {
  paramModelResult: ParameterModelResult;
};

const ExperimentsObservationsCharts = ({ paramModelResult }: Props) => {
  const experimentAggregationQuery: GetExperimentAggregationQuery = useMemo(
    () => ({
      simulationId: paramModelResult.modelRunId,
      experimentId: paramModelResult.current.id,
    }),
    [paramModelResult],
  );

  const { isLoading: isLoadingExperimentAggregation, response: experimentAggregation } = usePublicSimulationsData({
    endpoint: "experiment/aggregation",
    query: experimentAggregationQuery,
  });
  const observerDropdownOptions = useMemo(
    () =>
      experimentAggregation?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [experimentAggregation],
  );

  if (isLoadingExperimentAggregation || !observerDropdownOptions) return null;

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <ObservationsDistributionHistograms
        simulationResultId={paramModelResult.modelRunId}
        currentStateExperimentId={paramModelResult.current.id}
        recommendedStateExperimentId={paramModelResult.recommended!.id}
        observerDropdownOptions={observerDropdownOptions || []}
      />
      <ObservationsPairScatterChart
        simulationResultId={paramModelResult.modelRunId}
        currentStateExperimentId={paramModelResult.current.id}
        recommendedStateExperimentId={paramModelResult.recommended!.id}
        observerDropdownOptions={observerDropdownOptions || []}
      />
    </Paper>
  );
};

export default ExperimentsObservationsCharts;
