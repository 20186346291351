import { CustomMultiReactSelect, CustomReactSelect, Grid, MultiOptionType } from "@frontend/ui";
import { useEffect, useState } from "react";
import { usePriceFeedsContext } from "src/pages/oracle-portal/context/price-feeds-context";
import { useProvidersSelectContext } from "src/pages/oracle-portal/context/providers-select-context";
import { getProviderIconByName } from "../utils";

const ProvidersSelect = () => {
  const { isLoading } = usePriceFeedsContext();
  const {
    baselineProviderId,
    oracleProviders,
    exchangeProviders,
    providers,
    providerNameById,
    setBaselineProviderId,
    setProvidersIds,
  } = useProvidersSelectContext();

  const [selectedProvidersIds, setSelectedProvidersIds] = useState<string[]>();

  useEffect(() => {
    const handler = setTimeout(() => {
      setProvidersIds(selectedProvidersIds);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedProvidersIds, setProvidersIds]);

  const oracleProvidersOptions = (oracleProviders || []).map((p) => ({
    value: p.id,
    label: providerNameById?.[p.id] || p.name,
    cryptoIcon: p.name,
  }));

  const providersOptions = (providers || []).map((p) => ({
    value: p.id,
    label: providerNameById?.[p.id] || p.name,
    icon: p.name,
    cryptoIcon: getProviderIconByName(p.name),
  }));

  const exchangesGroupOption: MultiOptionType = {
    value: "exchanges",
    label: "All Exchanges",
    groupValues: exchangeProviders?.map((p) => p.id),
    bold: true,
    customIcon: "exchange",
  };

  const oraclesGroupOption: MultiOptionType = {
    value: "oracles",
    label: "All Oracles",
    groupValues: oracleProviders?.filter((o) => o.id !== baselineProviderId)?.map((p) => p.id),
    bold: true,
    customIcon: "oracle",
  };

  const selectedOptions = providersOptions.filter(
    (opt) => opt.value !== baselineProviderId && (selectedProvidersIds || []).some((p) => p === opt.value),
  );

  const optionsWithoutBaseline = providersOptions.filter((opt) => opt.value !== baselineProviderId);
  const defaultOption = optionsWithoutBaseline[0];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <CustomReactSelect
          label="Benchmark Oracle"
          placeholder="Select Baseline Providers"
          tooltip="The benchmark price feed that serves as a comparative measure for all other providers."
          options={oracleProvidersOptions}
          value={oracleProvidersOptions.filter((opt) => opt.value === baselineProviderId)}
          onChange={(e) => {
            setBaselineProviderId(e?.value);
          }}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomMultiReactSelect
          controlShouldRenderValue
          label="Providers"
          placeholder="Select Providers"
          tooltip="Select from available providers to compare their price data in the charts below."
          options={[
            exchangesGroupOption,
            oraclesGroupOption,
            ...providersOptions.filter((p) => p.value !== baselineProviderId),
          ]}
          selectAllLabel="All Providers"
          value={selectedOptions.length ? selectedOptions : optionsWithoutBaseline}
          onChange={(e) => {
            if (e.length === 0) {
              setSelectedProvidersIds([defaultOption.value]);
              return;
            }
            setSelectedProvidersIds(e.map((a) => a.value));
          }}
          isLoading={isLoading}
          allowSelectAll
        />
      </Grid>
    </Grid>
  );
};

export default ProvidersSelect;
