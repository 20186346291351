import { Chain } from "../../generated";
import { CCARClientConfig } from "../types";

const chain = Chain.ArbitrumGoerli;

export const tapiocaConfig: CCARClientConfig = {
  chains: [Chain.ArbitrumGoerli, Chain.ArbitrumGoerli],
  riskThreshold: 1.2,
  eligibleThreshold: 1,
  isMultichain: false,
  isMultiMarket: true,
  marketType: "BorrowCollateralAssetMarket",
  endpointsPrefix: "tapioca",
  overview: {
    cards: [
      "totalSupplyUsd",
      "totalBorrowUsd",
      "totalValueLockedUsd",
      // 'totalValueAvailableForLiquidationUsd',
      "totalValueAtRiskUsd",
      // 'numberOfWalletsAtRisk',
      // 'numberOfWalletsAvailableForLiquidation',
      // 'activeUsers',
    ],
    charts: ["historyStatsTvl", "chainHistoryStats"],
  },
  liquidations: {
    showLiquidationPie: false,
    showLiquidationEvents: false,
  },
  markets: {
    isSingularMarket: true,
    borrowAsset: "USDO",
    hiddenColumns: {
      chain: true,
    },
    overview: {
      showCollateralChart: true,
      showEventsTable: true,
    },
  },
  marketDetails: {
    tables: ["wallets"],
    charts: ["borrowWalletsHealth", "topWallets"],
  },
  wallets: {
    hiddenRows: {
      assetSupply: true,
      assetBorrows: true,
    },
  },
  walletDetails: {
    tables: ["totalSupplyAndBorrow", "recentEvents"],
  },
  riskExplorer: {
    liquidationThresholdLabel: "Liquidation Threshold",
    showAdvancedSettings: true,
  },
  stableCoin: {
    asset: "USDO",
    marketType: "BigBang",
    beta: true,
    chain,
    hasPools: false,
    cards: ["price", "totalCollateral", "totalMinted", "collateraDebtRatio"],
    charts: ["collateralComposition", "mintBurn", "assetPriceLiquidation"],
    collateral: {
      subtitle: "Analysis of collateral used to mint USDO across Big Bang markets.",
      charts: ["assetBreakdown", "assetClassBreakdown", "collateralRatio"],
    },
  },
};
