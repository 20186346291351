import { Box } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { useAssetRecommendations } from "../use-asset-recommendations";
import {
  ExperimentSectionHeader,
  MetricsTable,
  ExperimentCards,
  HealthDistribution,
  RepetitionsTable,
  ObservationsDistribution,
} from "./components";
import { ExperimentType } from "./types";

type Props = {
  type: ExperimentType;
};

const ExperimentResults = ({ type }: Props) => {
  const { isLoading, assetRecommendations, ltParam } = useAssetRecommendations();

  if (isLoading || !assetRecommendations) return <Loader />;

  if (!ltParam) return null;

  const { result } = ltParam;
  const { current, recommended } = result;
  const isCurrent = type === "current";

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <ExperimentCards experimentType={type} metrics={result.metrics} />
      <ExperimentSectionHeader />
      <MetricsTable experimentType={type} metrics={result.metrics} />
      <HealthDistribution liquidationThreshold={isCurrent ? current.value : recommended?.value || 0} />
      <RepetitionsTable paramModelResult={result} experimentType={type} />
      <ObservationsDistribution
        simulationResultId={result.modelRunId}
        experimentId={isCurrent ? result.current.id : result.recommended!.id}
      />
    </Box>
  );
};

export default ExperimentResults;
