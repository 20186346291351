import { Paper, Typography, Box } from "@frontend/ui";
import { ChartWidget, Metric } from "@frontend/ui/chart-widget";
import { palette } from "@frontend/ui/theme/palette";
import dayjs from "dayjs";
import { ProposedAssetData } from "src/pages/asset-listing-portal/generated";
import { formatPercent } from "@frontend/ui/utils/formatters";
import { valueOverTimeToArr } from "../utils";
import { getStandardDeviation } from "./utils";

type Props = {
  coinGeckoId: string;
  proposedAssetData: ProposedAssetData;
};

const timeTooltipHeaderFormatter = (value: number | string) => dayjs(value).format("DD/MM/YYYY");

const TokenOverview = ({ proposedAssetData }: Props) => {
  const { marketCapUsdHistory, priceUsdHistory, volumeUsdHistory, priceChangeHistory } = proposedAssetData;

  const showOverview = marketCapUsdHistory || priceUsdHistory || volumeUsdHistory || priceChangeHistory;

  if (!showOverview) return null;

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h3">Overview</Typography>
      {(volumeUsdHistory || priceChangeHistory) && (
        <Box display="flex" gap={2}>
          {volumeUsdHistory && (
            <Paper variant="widget" sx={{ flex: 1, width: 0 }}>
              <ChartWidget
                series={{
                  data: valueOverTimeToArr(volumeUsdHistory),
                  name: "Trading Volume",
                  color: palette.green.main,
                  showArea: true,
                }}
                metrics={[Metric.AVG, Metric.MIN, Metric.MAX]}
                type="line"
                title="Hourly CEX Trading Volume"
                currency="USD"
                tooltipHeaderFormatter={timeTooltipHeaderFormatter}
                xAxisOptions={{
                  axisLabel: {
                    fontSize: 12,
                    formatter: (v: string) => dayjs(Number(v)).format("DD/MM"),
                  },
                }}
              />
            </Paper>
          )}
          {priceChangeHistory && (
            <Paper variant="widget" sx={{ flex: 1, width: 0 }}>
              <ChartWidget
                series={{
                  data:
                    priceChangeHistory.map(({ timestamp, value: priceChangePercent }) => [
                      timestamp,
                      priceChangePercent,
                    ]) || [],
                  name: "Price Change",
                  color: palette.green.main,
                }}
                metrics={[
                  {
                    name: "Daily Volatility",
                    func: (data: number[]) => getStandardDeviation(data) * Math.sqrt(365),
                    tooltip: "Daily Annualized Volatility",
                  },
                  {
                    name: "30-Day Volatility",
                    func: (data: number[]) => getStandardDeviation(data.slice(-30)) * Math.sqrt(365),
                    tooltip: "30-Day Daily Annualized Volatility",
                  },
                  Metric.MIN,
                  Metric.P99,
                  Metric.P95,
                  Metric.MAX,
                  Metric.AVG,
                ]}
                type="line"
                title="Daily Price Change"
                isYAxisPercentage
                tooltipValueFormatter={(v: string | number) => formatPercent(Number(v))}
                tooltipHeaderFormatter={timeTooltipHeaderFormatter}
                xAxisOptions={{
                  axisLabel: {
                    fontSize: 12,
                    formatter: (v: string) => dayjs(Number(v)).format("DD/MM"),
                  },
                }}
              />
            </Paper>
          )}
        </Box>
      )}
      {(marketCapUsdHistory || priceUsdHistory) && (
        <Paper variant="widget" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Box display="flex" gap={3}>
            {marketCapUsdHistory && (
              <Box flex={1} width={0}>
                <ChartWidget
                  series={{
                    data: valueOverTimeToArr(marketCapUsdHistory),
                    name: "Market Cap",
                    color: "#23AED2",
                  }}
                  metrics={[Metric.AVG, Metric.MIN, Metric.MAX]}
                  type="line"
                  title="Daily Market Cap"
                  titleVariant="h5"
                  chartHeight={100}
                  tooltipHeaderFormatter={timeTooltipHeaderFormatter}
                  grid={{
                    right: 15,
                  }}
                  xAxisOptions={{
                    axisLabel: {
                      fontSize: 12,
                      formatter: (v: string) => dayjs(Number(v)).format("DD/MM"),
                    },
                  }}
                  currency="USD"
                  yAxisOptions={{
                    min: "dataMin",
                    max: "dataMax",
                  }}
                />
              </Box>
            )}
            {priceUsdHistory && (
              <Box flex={1} width={0}>
                <ChartWidget
                  series={{
                    data: valueOverTimeToArr(priceUsdHistory),
                    name: "Price",
                    color: "#23AED2",
                  }}
                  metrics={[Metric.AVG, Metric.MIN, Metric.MAX]}
                  type="line"
                  title="Daily Price"
                  titleVariant="h5"
                  chartHeight={100}
                  tooltipHeaderFormatter={timeTooltipHeaderFormatter}
                  grid={{
                    right: 15,
                  }}
                  xAxisOptions={{
                    axisLabel: {
                      fontSize: 12,
                      formatter: (v: string) => dayjs(Number(v)).format("DD/MM"),
                    },
                  }}
                  currency="USD"
                  yAxisOptions={{
                    min: "dataMin",
                    max: "dataMax",
                  }}
                />
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </Paper>
  );
};

export default TokenOverview;
