import { FC } from "react";
import { Typography } from "@frontend/ui/typography";
import { Box } from "@frontend/ui/box";
import { Paper } from "@frontend/ui/paper";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { Tooltip } from "@frontend/ui/tooltip";
import { DemandAssessment as IChaosDemandScore } from "../types";
import { DemandScores } from "./demand-scores";
import { AssetPieChart } from "../charts/asset-pie-chart";

interface ChaosDemandScoreProps {
  data?: IChaosDemandScore;
}

const DemandScoreTooltip = (
  <Box maxWidth={800} bgcolor="background.modal">
    <Typography variant="body2">
      The demand score is composed of 2 weighted pillars, ordered by their weight - trading volume indicators and price
      change indicators.{" "}
    </Typography>
    <br />
    <Box>
      <Typography display="inline" variant="body2" fontWeight="bold" whiteSpace="nowrap">
        Trading volume -
      </Typography>
      &nbsp;
      <Typography display="inline" variant="body2">
        Total trading volume & trend of derivatives based trusted exchanges.
      </Typography>
    </Box>
    <br />
    <Box>
      <Typography display="inline" variant="body2" fontWeight="bold" whiteSpace="nowrap">
        Price -
      </Typography>
      &nbsp;
      <Typography display="inline" variant="body2">
        Price trend compared to the overall market
      </Typography>
    </Box>
    <br />
  </Box>
);

export const ChaosDemandScore: FC<ChaosDemandScoreProps> = ({ data }) => (
  <Paper variant="card" sx={{ height: "100%" }}>
    <Box display="flex" alignItems="center" gap={2} mb={3}>
      <Typography variant="h2">Chaos Demand Score</Typography>
      <Tooltip
        classes={{ tooltip: "dark", arrow: "dark" }}
        placement="bottom-end"
        arrow
        sx={{ maxWidth: 800 }}
        title={DemandScoreTooltip}
        enterTouchDelay={0}
        leaveTouchDelay={10000}
      >
        <Box height={20}>
          <CustomIcon icon="info" sx={{ svg: { width: 20, height: 20 } }} />
        </Box>
      </Tooltip>
    </Box>
    <Box
      sx={{
        gap: 3,
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "start",
        justifyContent: "start",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
    >
      <DemandScores data={data} />
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", lg: "start" },
          ml: { lg: "auto" },
        }}
      >
        <AssetPieChart
          data={
            data
              ? [
                  { x: "", y: 100 - Number(data.demandScore), background: true },
                  { x: "Security Score", y: Number(data.demandScore) },
                ]
              : [{ x: "", y: "" }]
          }
          title={data ? "Demand Score" : "Not enough data"}
          titleValue={data?.demandScore}
          chartHeight={200}
          isLabelsHidden
          isCornersRounded
        />
      </Box>
    </Box>
  </Paper>
);
