import { Box, Typography, ECharts, Paper } from "@frontend/ui";
import type { SeriesOption } from "echarts";
import { chaosChartTypes, ChaosChartType } from "@frontend/ui/charts";
import { FC, useState, useMemo, memo } from "react";
import { ScriptResource, ChartLegends } from "@frontend/types";
import { useTabsControl } from "@frontend/ui/hooks/use-tabs-control";
import { Tabs } from "@frontend/ui/tabs";
import { ObservationChartDataMapper, ObservationMultiChartsDataMapper } from "./utils/observation-chart-data-mapper";
import { ObserverWidgetChartValue } from "./types";
import { getObserverChartOptions } from "./utils/observer-chart-options";

export type GroupChildObserver = {
  id: string;
  title: string;
  secondaryAxis?: boolean;
};
export interface ObserverWidgetProps {
  id?: string;
  instanceId?: string;
  observation: number[] | number[][];
  title?: string;
  groupChildObservers?: GroupChildObserver[];
  observerConfig?: Partial<ScriptResource>;
  description?: string;
  startBlock?: number;
  displayOrder?: number;
  iterationsRange: [number, number];
  legends?: ChartLegends;
  currentIteration?: number;
}

export const ObserverWidgetComponent: FC<ObserverWidgetProps> = ({
  observation,
  title,
  observerConfig,
  groupChildObservers,
  description,
  startBlock,
  iterationsRange,
  legends,
}: ObserverWidgetProps) => {
  const { dataType, chartType: defaultChartType } = observerConfig || {};

  const showAggregation = dataType === "event";

  const [isAggregationChecked, setIsAggregationChecked] = useState(showAggregation);

  const defaultChartIndex = chaosChartTypes.indexOf(defaultChartType || "AREA");

  const { dataForRender: chartType } = useTabsControl<ChaosChartType>([...chaosChartTypes], defaultChartIndex);

  const isMultipleCharts = typeof observation[0] === "object";

  const chartData = useMemo(() => {
    if (isMultipleCharts) {
      return ObservationMultiChartsDataMapper(
        chartType,
        observation as number[][],
        isAggregationChecked,
        iterationsRange,
      );
    }

    return ObservationChartDataMapper(chartType, observation as number[], isAggregationChecked, iterationsRange);
  }, [chartType, isAggregationChecked, isMultipleCharts, observation, iterationsRange]);

  const chartValues: ObserverWidgetChartValue[][] = useMemo(
    () => (isMultipleCharts ? chartData.values : [chartData.values]) as ObserverWidgetChartValue[][],
    [chartData.values, isMultipleCharts],
  );

  const chartSeries: SeriesOption[] = useMemo(
    () =>
      chartValues.map((values, index) => {
        const childObserver = groupChildObservers?.[index];
        const isSecondaryAxis = childObserver?.secondaryAxis;
        const isArea = chartType === "AREA";
        return {
          data: values.map((value) => value.y),
          type: "line",
          symbol: "none",
          yAxisIndex: isSecondaryAxis ? 1 : 0,
          name: childObserver?.title || title || "",
          areaStyle: isArea
            ? {
                origin: "auto",
                opacity: 0.2,
              }
            : undefined,
        } as SeriesOption;
      }),
    [chartType, chartValues, groupChildObservers, title],
  );

  return (
    <Paper variant="widget">
      <Box display="flex" flexDirection="row" flexGrow={1} width="100%" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{title}</Typography>
          <Typography>{description}</Typography>
        </Box>
        {showAggregation && (
          <Box alignItems="center">
            <Tabs
              tabs={[
                {
                  label: "Per-Block",
                },
                {
                  label: "Aggregated",
                },
              ]}
              value={isAggregationChecked ? 1 : 0}
              onChange={(_, index) => setIsAggregationChecked(index === 1)}
            />
          </Box>
        )}
      </Box>
      <Box width="100%" height={400}>
        <ECharts option={getObserverChartOptions(chartSeries, legends, startBlock, observerConfig)} zoomable />
      </Box>
    </Paper>
  );
};

export const ObserverWidget = memo(ObserverWidgetComponent);
