import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { formatAmount, formatDay } from "../utils/formatters";
import { TimeSpan } from "./types";

dayjs.extend(utc);

dayjs.extend(localizedFormat);
export const formatEChartValue =
  ({
    isPercent = false,
    currency = "USD",
    notation = "compact",
    cryptoCurrency,
  }: {
    isPercent?: boolean;
    currency?: string;
    notation?: "compact" | "standard";
    cryptoCurrency?: string;
  }) =>
  (v: number) =>
    `${formatAmount(Number(v), { notation, isPercent, currency: currency && !cryptoCurrency ? currency : undefined })}${
      cryptoCurrency ? ` ${cryptoCurrency}` : ""
    }`;

export const formatXAxisDate = (timestamp: number, timeSpan?: TimeSpan) => {
  switch (timeSpan) {
    case TimeSpan.Day:
      return dayjs(timestamp).format("LT");
    default:
      return formatDay(new Date(timestamp), true);
  }
};

export const formatTooltipDate = (timestamp: number, timeSpan?: TimeSpan) => {
  switch (timeSpan) {
    case TimeSpan.Day:
    case TimeSpan.Week:
      return dayjs(timestamp).format("MMM D, YYYY h:mm A");
    default:
      return dayjs(timestamp).utc().format("MMM D, YYYY");
  }
};

export const daysAgoByTimeSpan: Record<TimeSpan, number> = {
  [TimeSpan.Day]: 1,
  [TimeSpan.Week]: 7,
  [TimeSpan.Month]: 30,
  [TimeSpan.Quarter]: 90,
  [TimeSpan.Year]: 365,
};
