import { useParams } from "react-router-dom";
import { ParamName } from "src/pages/ccar-lending-page/generated";
import { useMarketAssetsRecommendationsContext } from "../../context";

export const useAssetRecommendations = () => {
  const { asset } = useParams();
  const { isLoading, assetsRecommendations, isUnderReview } = useMarketAssetsRecommendationsContext();
  const assetRecommendations = assetsRecommendations?.find((item) => item.assetName === asset);

  const { parameters } = assetRecommendations || {};

  const ltParam =
    parameters?.find((p) => p.name === ParamName.LiquidationThreshold) ||
    parameters?.find((p) => p.name === ParamName.CollateralFactor);

  return {
    isLoading,
    assetRecommendations,
    isUnderReview,
    ltParam,
  };
};
