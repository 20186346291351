import { Box, NotificationBanner } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { ParamName } from "src/pages/ccar-lending-page/generated";
import { CapsDetails, SimulationResultsSection, ParamsRecommendationsTable } from "./components";
import { useAssetRecommendations } from "./use-asset-recommendations";

const AssetRecommendations = () => {
  const { isLoading, assetRecommendations, isUnderReview, ltParam } = useAssetRecommendations();

  if (isLoading || !assetRecommendations) return <Loader />;

  const { parameters } = assetRecommendations;

  const paramsHasChange = parameters.some(
    ({ result }) =>
      result.recommended !== undefined &&
      result.recommended !== null &&
      result.recommended?.value !== result.current.value,
  );

  const borrowCap = parameters.find((p) => p.name === ParamName.BorrowCap);
  const supplyCap = parameters.find((p) => p.name === ParamName.SupplyCap);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {!paramsHasChange && (
        <NotificationBanner
          bgcolor="blue.opacity50"
          message="Chaos Labs currently recommends no change for this asset"
          icon="chaos"
        />
      )}
      <ParamsRecommendationsTable parameters={parameters} />
      {(borrowCap || supplyCap) && <CapsDetails borrowCap={borrowCap?.result} supplyCap={supplyCap?.result} />}
      {ltParam && <SimulationResultsSection paramModelResult={ltParam.result} isUnderReview={isUnderReview} />}
    </Box>
  );
};

export default AssetRecommendations;
