import { CompositionOverTimeChart } from "@frontend/ui";
import { useCollateralInflowOutflowHistoryQuery } from "../../../../generated";

const CollateralInflowOutflowChart = () => {
  const { loading, data } = useCollateralInflowOutflowHistoryQuery();

  const inflowSeriesData = data?.collateralInflowOutflowHistory.map((d) => [d.timestamp * 1000, d.inflowUsd]);
  const outflowSeriesData = data?.collateralInflowOutflowHistory.map((d) => [d.timestamp * 1000, -d.outflowUsd]);

  return (
    <CompositionOverTimeChart
      title="Collateral Inflow / Outflow Over Time"
      series={[
        {
          label: "Inflow",
          data: (inflowSeriesData || []) as [number, number][],
          type: "bar",
        },
        {
          label: "outflow",
          data: (outflowSeriesData || []) as [number, number][],
          type: "bar",
        },
      ]}
      isLoading={loading}
    />
  );
};

export default CollateralInflowOutflowChart;
