import { AssetInfo } from "@frontend/types";
import { TickSize as TickSizeCard } from "src/pages/asset-page/tick-size/tick-size";

type Props = {
  tick: NonNullable<AssetInfo["tick"]>;
};

const TickSize = ({ tick }: Props) => (
  <TickSizeCard
    unit="$"
    optimalTick={tick.optimalTick !== "N/A" ? Number(tick.optimalTick) : undefined}
    currentTick={tick.currentTick !== "N/A" ? Number(tick.currentTick) : undefined}
  />
);

export default TickSize;
