import { AssetPage } from "src/pages/asset-listing-portal/asset-page";
import { ChaosApolloProvider } from "src/utils/graphql";

const ProposedAssets = () => (
  <ChaosApolloProvider product="asset-listing">
    <AssetPage hideHeader />
  </ChaosApolloProvider>
);

export default ProposedAssets;
