import { FC } from "react";
import { Typography } from "@frontend/ui/typography";
import { Box } from "@frontend/ui/box";
import { Paper } from "@frontend/ui/paper";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { Tooltip } from "@frontend/ui/tooltip";
import { ChaosRiskScore as IChaosRiskScore } from "../types";
import { RiskScores } from "./risk-scores";
import { AssetPieChart } from "../charts/asset-pie-chart";

interface ChaosRiskScoreProps {
  data?: IChaosRiskScore;
}

const RiskScoreTooltip = (
  <Box maxWidth={800} bgcolor="background.modal">
    <Typography variant="body2">
      The risk score is composed of 3 weighted pillars, ordered by their weight - market assessment, social sentiment,
      and existing audits & security assessments. Market assessment holds the the lion's share in the final score.{" "}
    </Typography>
    <br />
    <Box>
      <Typography display="inline" variant="body2" fontWeight="bold" whiteSpace="nowrap">
        Market -
      </Typography>
      &nbsp;
      <Typography display="inline" variant="body2">
        measures total trading volume & ranking, market cap & ranking, price volatility for 30/90 day trends, and
        overall liquidity in markets.
      </Typography>
    </Box>
    <br />
    <Box>
      <Typography display="inline" variant="body2" fontWeight="bold" whiteSpace="nowrap">
        Social -
      </Typography>
      &nbsp;
      <Typography display="inline" variant="body2">
        token’s trend and engagement in social media, aggregation of user votes on their sentiment.
      </Typography>
    </Box>
    <br />
    <Box>
      <Typography display="inline" variant="body2" fontWeight="bold" whiteSpace="nowrap">
        Safety -
      </Typography>
      &nbsp;
      <Typography display="inline" variant="body2">
        existence and quantity of audits and risk analysis for the token and token’s related protocol if relevant.
      </Typography>
    </Box>
  </Box>
);

export const ChaosRiskScore: FC<ChaosRiskScoreProps> = ({ data }) => (
  <Paper variant="card" sx={{ height: "100%" }}>
    <Box display="flex" alignItems="center" gap={2} mb={3}>
      <Typography variant="h2">Chaos Risk Score</Typography>
      <Tooltip
        classes={{ tooltip: "dark", arrow: "dark" }}
        placement="bottom-end"
        arrow
        sx={{ maxWidth: 800 }}
        title={RiskScoreTooltip}
        enterTouchDelay={0}
        leaveTouchDelay={10000}
      >
        <Box height={20}>
          <CustomIcon icon="info" sx={{ svg: { width: 20, height: 20 } }} />
        </Box>
      </Tooltip>
    </Box>
    <Box
      sx={{
        gap: 3,
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "start",
        justifyContent: "start",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
    >
      <RiskScores data={data} />
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", lg: "start" },
          ml: { lg: "auto" },
        }}
      >
        <AssetPieChart
          data={
            data
              ? [
                  { x: "", y: 100 - Number(data.total), background: true },
                  { x: "Security Score", y: Number(data.total) },
                ]
              : [{ x: "", y: "" }]
          }
          title={data ? "Risk Score" : "Not enough data"}
          titleValue={data?.total}
          chartHeight={200}
          isLabelsHidden
          isCornersRounded
        />
      </Box>
    </Box>
  </Paper>
);
