import { FC } from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { Typography } from "../typography";
import { Tooltip } from "../tooltip";
import { formatAmount } from "../utils/formatters";

export type TrendProps = {
  delta: number;
  deltaTrend: number;
  tooltip?: string;
  deltaColor?: number;
  currency?: string;
  isPercent?: boolean;
  maximumFractionDigits?: number;
};

export function getDeltaFields(
  current?: number,
  previous?: number,
  isAlignedWithTrend?: boolean,
  isDeltaPercent?: boolean,
) {
  if (current !== undefined && previous !== undefined) {
    const change = current - previous;
    const changePercent = (current - previous) / current;
    const delta = Math.abs(isDeltaPercent ? changePercent : change);
    const deltaTrend = Math.sign(current - previous);
    const deltaColor = isAlignedWithTrend ? deltaTrend : -deltaTrend;

    return { delta, deltaTrend, deltaColor, isDeltaPercent };
  }

  return { delta: undefined, deltaTrend: undefined, deltaColor: undefined };
}

export function getDeltaFieldsFromChangePercent(changePercent?: number, isAlignedWithTrend?: boolean) {
  if (changePercent !== undefined) {
    const delta = Math.abs(changePercent);
    const deltaTrend = Math.sign(changePercent);
    const deltaColor = isAlignedWithTrend ? deltaTrend : -deltaTrend;

    return { delta, deltaTrend, deltaColor, isDeltaPercent: true };
  }

  return { delta: undefined, deltaTrend: undefined, deltaColor: undefined };
}

export const Trend: FC<TrendProps> = ({
  delta,
  deltaTrend,
  deltaColor = 0,
  currency,
  isPercent,
  tooltip = "Change over the last 24h",
  maximumFractionDigits,
}) => {
  const trendIndicator =
    deltaTrend === 0 ? (
      ""
    ) : deltaTrend === -1 ? (
      <ArrowDropDown sx={{ fontSize: "16px" }} />
    ) : (
      <ArrowDropUp sx={{ fontSize: "16px" }} />
    );
  const trendColor = deltaTrend === 0 ? undefined : deltaColor < 0 ? "error.main" : "success.main";

  if (delta === 0) return null;

  return (
    <Tooltip title={tooltip} arrow placement="bottom-start">
      <Typography color={trendColor} display="flex" alignItems="center" whiteSpace="nowrap" variant="body2">
        {trendIndicator} {formatAmount(Math.abs(delta), { isPercent, currency, maximumFractionDigits })}
      </Typography>
    </Tooltip>
  );
};
