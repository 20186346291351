import { ChaosChartType } from "@frontend/ui/charts";
import { ObserverWidgetChartValue, ObserverWidgetChartData } from "../types";

export function ObservationChartDataMapper(
  chartType: ChaosChartType,
  observation: number[],
  isAggregationChecked: boolean,
  iterationsRange: [number, number],
): ObserverWidgetChartData {
  const defaultReturn = {
    min: 0,
    max: 0,
    avg: 0,
    start: 0,
    end: 0,
    values: [],
  };

  if (observation.length === 0) {
    return defaultReturn;
  }

  const startVal = Number(observation[Math.floor(iterationsRange[0])]);
  const endVal = Number(observation[Math.min(Math.floor(iterationsRange[1]) - 1, observation.length - 1)]);

  const data: ObserverWidgetChartValue[] = (() => {
    switch (chartType) {
      case "LINE":
      case "AREA":
        return observation
          .map((value, i) => ({ x: i, y: Number(value) }))
          .filter((d) => d.x >= iterationsRange[0] && d.x <= iterationsRange[1]);
      case "HISTOGRAM":
        return observation.reduce((acc: ObserverWidgetChartValue[], currentValue: number) => {
          const newAcc = [...acc];
          const roundValue = Number(Number(currentValue).toFixed(2));
          const currentChartValueIdx = newAcc.findIndex((value) => Number(Number(value.x).toFixed(2)) === roundValue);

          if (currentChartValueIdx < 0) {
            newAcc.push({ x: roundValue, y: 1 }); // x - value, y - number of blocks
            return newAcc;
          }

          const currentChartValue = newAcc[currentChartValueIdx];
          const newChartValue = {
            x: roundValue,
            y: currentChartValue.y + 1,
          };
          newAcc[currentChartValueIdx] = newChartValue;

          return newAcc;
        }, []);
      default:
        return [];
    }
  })();

  const { aggregatedValues: aggData } = data.reduce(
    ({ sum, aggregatedValues }: { sum: number; aggregatedValues: ObserverWidgetChartValue[] }, currentValue) => {
      const newSum = sum + currentValue.y;
      const newValue = {
        x: currentValue.x,
        y: newSum,
      };
      const newAggregatedValues = [...aggregatedValues, newValue];

      return { sum: newSum, aggregatedValues: newAggregatedValues };
    },
    {
      sum: 0,
      aggregatedValues: [],
    },
  );

  const values = !isAggregationChecked ? data : aggData;

  return {
    start: startVal,
    end: endVal,
    min: 0,
    max: 0,
    avg: 0,
    values,
  };
}
export function ObservationMultiChartsDataMapper(
  chartType: ChaosChartType,
  observations: number[][],
  isAggregationChecked: boolean,
  iterationsRange: [number, number],
): ObserverWidgetChartData {
  const defaultReturn = {
    min: 0,
    max: 0,
    avg: 0,
    start: 0,
    end: 0,
    values: [],
  };

  if (observations.length === 0) {
    return defaultReturn;
  }

  const chartsData = observations.map((chartValues: number[]) =>
    ObservationChartDataMapper(chartType, chartValues, isAggregationChecked, iterationsRange),
  );

  const startVal = 0;
  const endVal = 0;
  const values = chartsData.map((data) => data.values as ObserverWidgetChartValue[]);

  return {
    start: startVal,
    end: endVal,
    min: 0,
    max: 0,
    avg: 0,
    values,
  };
}
