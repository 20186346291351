import { useState } from "react";
import { Box, CustomSwitch, Paper } from "@frontend/ui";
import { useUserEngagementData } from "./use-user-engagement-data";
import {
  ActiveUsersByProtocol,
  NewUsersHistory,
  UserEngagementCards,
  UsersMonthlyRetentionHeatmap,
} from "./components";
import ChainsHistoryChart from "../components/chains-history-chart";
import { TimeSpan } from "../../generated";

const UserEngagement = () => {
  const [isNumberOfUsersHistoryMonthly, setIsNumberOfUsersHistoryMonthly] = useState(true);
  const data = useUserEngagementData(isNumberOfUsersHistoryMonthly);
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <UserEngagementCards data={data} />
      <Paper sx={{ display: "flex", flexDirection: "column", gap: 3 }} variant="card">
        <ChainsHistoryChart
          title={`${isNumberOfUsersHistoryMonthly ? "Monthly" : "Daily"} Active Users`}
          yAxisLabel="Number of Active Users"
          description={`Number of ${
            isNumberOfUsersHistoryMonthly ? "monthly" : "daily"
          } addresses initiating at least one transaction`}
          data={data.chainsNumberOfUsersHistory}
          isLoading={data.isLoadingChainsNumberOfUsersHistory}
          isCurrency={false}
          isBarChart
          suffix={
            <CustomSwitch
              onChange={() => setIsNumberOfUsersHistoryMonthly((prev) => !prev)}
              checked={isNumberOfUsersHistoryMonthly}
              checkedLabel="MAU"
              uncheckedLabel="DAU"
            />
          }
          timeSpan={TimeSpan.Year}
          showMonthTooltip={isNumberOfUsersHistoryMonthly}
        />
        <ActiveUsersByProtocol data={data} />
        <NewUsersHistory data={data} />
      </Paper>
      <UsersMonthlyRetentionHeatmap data={data} />
    </Box>
  );
};

export default UserEngagement;
