import { Box, CompositionOverTimeChart, Paper, Typography } from "@frontend/ui";
import { useFeatureFlag } from "src/utils/feature-flags";
import { useClientConfig } from "../../clients-config";
import { OverviewChartsStats } from "./types";
import {
  CrossChainSupplyBorrowDistribution,
  SupplyBorrowDistribution,
  CollateralOverTime,
  ValueAtRisk,
  ChainHistoryStats,
  StablecoinPoolsComposition,
  TvlOverTimeChart,
} from "./components";
import { StablecoinCollateralComposition } from "./components/stablecoin-collateral-composition";
import { StablecoinMintBurnChart } from "../stablecoin/components/mint-burn-chart";
import { StablecoinDataProvider } from "../stablecoin/provider";
import { TopTokenHolders } from "./components/top-holders";
import { TimeSpan } from "../../generated";
import { AggSupplyBorrowEventsChart } from "../../components/agg-supply-borrow-events.ts";

type Props = {
  data?: OverviewChartsStats;
  isLoadingHistoryStats?: boolean;
  selectedTimeSpan?: TimeSpan;
};

const OverviewCharts = ({ data, isLoadingHistoryStats, selectedTimeSpan }: Props) => {
  const { overview, stableCoin, isMultiMarket, chains } = useClientConfig();
  const collateralAtRiskHistoryEnabled = useFeatureFlag("collateral-at-risk-history");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Paper
        variant="card"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {overview?.chartsHeader && (
          <Box>
            <Typography variant="h3">{overview.chartsHeader.title}</Typography>
            <Typography>{overview.chartsHeader.subtitle}</Typography>
          </Box>
        )}
        {overview?.charts.includes("historyStatsTvl") && (
          <TvlOverTimeChart
            timeSpan={selectedTimeSpan}
            data={data?.historyStatsTvl}
            isLoading={isLoadingHistoryStats}
          />
        )}
        {overview?.charts.includes("crossChainSupplyAndBorrowDistribution") &&
          data?.supplyDistribution &&
          data?.borrowDistribution && <CrossChainSupplyBorrowDistribution data={data} />}
        {overview?.charts.includes("collateralOverTime") && <CollateralOverTime timeSpan={selectedTimeSpan} />}
        {overview?.charts.includes("valueAtRisk") && collateralAtRiskHistoryEnabled && (
          <ValueAtRisk isLoading={isLoadingHistoryStats} data={data?.collateralAtRisk} timeSpan={selectedTimeSpan} />
        )}
        {overview?.charts.includes("chainHistoryStats") && (
          <ChainHistoryStats timeSpan={selectedTimeSpan} data={data} isLoading={isLoadingHistoryStats} />
        )}
        {overview?.charts.includes("aggSupplyBorrowEvents") && !isMultiMarket && chains && selectedTimeSpan && (
          <AggSupplyBorrowEventsChart marketId={chains[0]} timeSpan={selectedTimeSpan} />
        )}
        {overview?.charts.includes("supplyAndBorrowDistribution") &&
          data?.supplyDistribution &&
          data?.borrowDistribution && <SupplyBorrowDistribution data={data} />}
        {overview?.charts.includes("mintHistogram") && data?.mintHistogram && (
          <Paper variant="widget">
            <CompositionOverTimeChart
              title="Daily Total of Mint Events (USD)"
              series={Object.entries(data.mintHistogram).map(([key, val]) => ({
                type: "bar",
                data: val.map(({ x, y }) => [new Date(x).getTime(), Number(y)]),
                label: key,
              }))}
            />
          </Paper>
        )}
        {stableCoin?.showInOverview && (
          <StablecoinDataProvider>
            {overview?.charts.includes("stablecoinCollateralComposition") && <StablecoinCollateralComposition />}
            {overview?.charts.includes("stablecoinMintBurn") && <StablecoinMintBurnChart hasPaper={false} />}
          </StablecoinDataProvider>
        )}
      </Paper>
      {overview?.charts.includes("poolsComposition") && <StablecoinPoolsComposition />}
      {overview?.charts.includes("topHolders") && <TopTokenHolders />}
    </Box>
  );
};

export default OverviewCharts;
