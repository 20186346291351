import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";

const WithdrawalsCards = () => {
  const cards: ValueCardProps[] = [
    {
      title: "Liquidity Pool Size",
      value: 390100,
      icon: "pie-slice",
      cryptoCurrency: "ETH",
    },
    {
      title: "Withdrawal Queue Length",
      value: 436,
      icon: "users",
      cryptoCurrency: "ETH",
    },
    {
      title: "Withdrawal Queue Waiting Time",
      value: "28H",
      icon: "clock",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default WithdrawalsCards;
