import { RoutePath } from "src/config/routes";

export const getRouteByTabPath = (path: string) => {
  if (path.includes("listed-assets")) {
    return RoutePath.Risk.ChainDetailsListedAssets;
  }
  if (path.includes("proposed-assets")) {
    return RoutePath.Risk.ChainDetailsProposedAssets;
  }
  if (path.includes("recommendations")) {
    return RoutePath.Risk.ChainDetailsRecommendations;
  }
  if (path.includes("e-mode")) {
    return RoutePath.Risk.ChainDetailsEMode;
  }

  return RoutePath.Risk.ChainDetails;
};
