import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { Client, AlpClientConfig } from "./types";

const aaveConfig: AlpClientConfig = {
  mainPage: {
    path: RoutePath.ParameterRecommendations.Home.replace(RouteParams.ClientName, "aave"),
    name: "Parameter Recommendations",
  },
};

const dydxConfig: AlpClientConfig = {};

export const clientsConfig: Record<Client, AlpClientConfig> = {
  aave: aaveConfig,
  dydx: dydxConfig,
};

export const useClientConfig = () => {
  const { clientName } = useParams<{ clientName: Client }>();
  return clientsConfig[clientName!];
};
