import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { formatPercent } from "@frontend/ui/utils/formatters";
import { Metric, MetricResult } from "src/pages/ccar-lending-page/generated";
import { ExperimentType } from "../types";

type Props = {
  metrics: Metric[];
  experimentType: ExperimentType;
};

const ExperimentCards = ({ metrics, experimentType }: Props) => {
  const metricResultByName = metrics.reduce(
    (acc: Record<string, MetricResult>, metric) => ({
      ...acc,
      [metric.name]: metric.result,
    }),
    {},
  );

  const { aaveUtility, borrowUsage, valueAtRisk, extremeValueAtRisk } = metricResultByName;

  const utilityRatio = aaveUtility?.[experimentType]?.value;
  const cards: ValueCardProps[] = [
    {
      title: "Borrow Usage",
      value: borrowUsage?.[experimentType]?.value,
      isPercent: true,
      icon: "waterfall-up",
      tooltip: "The percent of the supplied collateral that is currently being borrowed against.",
    },
    {
      title: "VaR",
      value: valueAtRisk?.[experimentType]?.value,
      currency: "USD",
      icon: "waterfall-down",
      tooltip: "Value at Risk - 95th percentile of bad debt observed through simulations of extreme market conditions.",
    },
    {
      title: "Extreme VaR",
      value: extremeValueAtRisk?.[experimentType]?.value,
      currency: "USD",
      icon: "waterfall-down",
      tooltip:
        "Extreme Value at Risk - 99th percentile of bad debt observed through simulations of extreme market conditions.",
    },
    {
      title: "Utility Ratio",
      value: utilityRatio ? `${utilityRatio >= 0 ? "+" : ""}${formatPercent(utilityRatio)}` : undefined,
      isPercent: true,
      icon: "chaos",
      titleVariant: "h5",
      titleColor: palette.white.main,
      bgColor: palette.blue.opacity15,
      iconOriginalColor: true,
      tooltip: "Potential improvement in asset Risk / Return ratio compared to the current state.",
    },
  ];

  return (
    <Grid container spacing={3}>
      {cards.map((cardProps) => (
        <Grid key={cardProps.title} item xs={12} sm={3}>
          <ValueCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ExperimentCards;
