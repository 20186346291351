import { AssetConfig, ProposedAssetData, ValueOverTime } from "src/pages/asset-listing-portal/generated";
import { Rating } from "../../../components/score-badge";
import { getDaysSince, getRatingsAverage, getAvgVolume } from "./utils";

export const calcSmartContractRisk = (contractDeploymentDate: number, txCount: number) => {
  const daysSinceDeployed = getDaysSince(contractDeploymentDate);

  if (daysSinceDeployed > 730) {
    if (txCount > 500000000) return Rating["A+"];
    if (txCount > 100000000) return Rating.A;
    if (txCount > 10000000) return Rating["A-"];
  }

  if (daysSinceDeployed > 365) {
    if (txCount > 1000000) return Rating["B+"];
    if (txCount > 350000) return Rating.B;
    if (txCount > 100000) return Rating["C+"];
    if (txCount > 25000) return Rating["C-"];

    return Rating.D;
  }

  if (txCount > 350000) return Rating["B-"];
  if (txCount > 100000) return Rating["C-"];
  if (txCount > 25000) return Rating["D+"];

  return Rating.D;
};

export const getCounterPartyRisk = (holdersCount: number): Rating => {
  if (holdersCount > 100000000) return Rating["A+"];
  if (holdersCount > 1000000) return Rating.A;
  if (holdersCount > 500000) return Rating["A-"];
  if (holdersCount > 100000) return Rating["B+"];
  if (holdersCount > 50000) return Rating.B;
  if (holdersCount > 25000) return Rating["B-"];
  if (holdersCount > 10000) return Rating["C+"];
  if (holdersCount > 2500) return Rating["C-"];

  return Rating["D+"];
};

const getMarketCapRating = (marketCap: number): Rating => {
  if (marketCap > 14900000000) return Rating["A+"];
  if (marketCap > 10000000000) return Rating.A;
  if (marketCap > 5000000000) return Rating["A-"];
  if (marketCap > 500000000) return Rating["B+"];
  if (marketCap > 250000000) return Rating.B;
  if (marketCap > 100000000) return Rating["B-"];
  if (marketCap > 50000000) return Rating["C+"];
  if (marketCap > 15000000) return Rating["C-"];
  if (marketCap > 10000000) return Rating["D+"];

  return Rating.D;
};

const getAvgVolumeRating = (volumeUsdHistory: ValueOverTime[]): Rating => {
  const { volumeAverage30d, volumeAverage90d } = getAvgVolume(volumeUsdHistory);
  const avgVolumesGt = (value: number) => volumeAverage30d > value && volumeAverage90d > value;

  if (avgVolumesGt(20000000000)) return Rating["A+"];
  if (avgVolumesGt(10000000000)) return Rating.A;
  if (avgVolumesGt(2500000000)) return Rating["A-"];
  if (avgVolumesGt(700000000)) return Rating["B+"];
  if (avgVolumesGt(500000000)) return Rating.B;
  if (avgVolumesGt(100000000)) return Rating["B-"];
  if (avgVolumesGt(20000000)) return Rating["C+"];
  if (avgVolumesGt(5000000)) return Rating.C;
  if (avgVolumesGt(1000000)) return Rating["C-"];
  if (avgVolumesGt(500000)) return Rating["D+"];
  if (avgVolumesGt(250000)) return Rating.D;

  return Rating["D-"];
};

export const getMarketRisk = (marketCap: number, dailyVolumeUsd90d: ValueOverTime[]): Rating => {
  const marketCapRating = getMarketCapRating(marketCap);
  const avgVolumeRating = getAvgVolumeRating(dailyVolumeUsd90d);

  return getRatingsAverage([marketCapRating, avgVolumeRating]);
};

type AaveOverallRiskType = {
  smartContractRisk: Rating;
  counterpartyRisk: Rating;
  marketRisk: Rating;
};
export const getAaveOverallRisk = (
  proposedAssetData: ProposedAssetData,
  assetConfig: AssetConfig,
): AaveOverallRiskType => {
  const { contractDeploymentDate, txCount, holdersCount } = assetConfig;
  const { marketCapUsd, volumeUsdHistory } = proposedAssetData;

  const smartContractRisk = calcSmartContractRisk(contractDeploymentDate || 0, txCount || 0);
  const counterpartyRisk = getCounterPartyRisk(holdersCount || 0);
  const marketRisk = getMarketRisk(marketCapUsd || 0, volumeUsdHistory || []);

  return {
    smartContractRisk,
    counterpartyRisk,
    marketRisk,
  };
};
