import { FC } from "react";
import { Typography } from "@frontend/ui/typography";
import { Box } from "@frontend/ui/box";
import { Paper } from "@frontend/ui/paper";
import { formatAmount } from "@frontend/ui/utils/formatters";

interface RevenueEstimationProps {
  value: number;
  title: string;
  subtitle: string;
}

export const formatAssetRevenueEstimation = (revenueEstimation: number) => `~
${formatAmount(revenueEstimation < 1e5 ? revenueEstimation : Math.round(revenueEstimation / 1e5) * 1e5, {
  currency: "usd",
  notation: "compact",
})}`;

export const RevenueEstimation: FC<RevenueEstimationProps> = ({ value, title, subtitle }) => (
  <Paper
    variant="card"
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 3,
    }}
  >
    <Typography variant="h2">{title}</Typography>
    <Paper
      variant="widget"
      sx={{
        height: "100%",
        display: "flex",
        gap: 3,
        alignItems: "center",
        flex: 1,
        justifyContent: "space-between",
      }}
    >
      <Box gap={3} paddingRight={5}>
        <Typography whiteSpace="pre-wrap">{subtitle}</Typography>
      </Box>

      <Typography variant="h1" whiteSpace="nowrap">
        {formatAssetRevenueEstimation(value)}
      </Typography>
    </Paper>
  </Paper>
);
