import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { RoutePath } from "../../../config/routes";
import { RouteParams } from "../../../config/route-params";
import { CommunityDashboardLayout } from "../../../components/layouts";

type Props = {
  children: ReactNode;
};

const ChainAnalyticsTabsLayout = ({ children }: Props) => {
  const { clientName } = useParams();
  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);
  return (
    <CommunityDashboardLayout
      showClientToolsDropdown={false}
      tabs={[
        {
          label: "Overview",
          path: replaceParams(RoutePath.Risk.Overview),
        },
        {
          label: "Ecosystem Performance",
          path: replaceParams(RoutePath.Risk.EcosystemPerformance),
        },
        {
          label: "User Engagement",
          path: replaceParams(RoutePath.Risk.UserEngagement),
        },
      ]}
    >
      {children}
    </CommunityDashboardLayout>
  );
};

export const renderTabsLayout = (children: ReactNode) => (
  <ChainAnalyticsTabsLayout>{children}</ChainAnalyticsTabsLayout>
);
export default ChainAnalyticsTabsLayout;
