import dayjs from "dayjs";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { TooltipComponentFormatterCallbackParams } from "echarts";
import { Box, HeatmapChart, HeatmapRowData, Paper, Typography } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { UserEngagementData } from "../use-user-engagement-data";

type Props = {
  data: UserEngagementData;
};

const UsersMonthlyRetentionHeatmap = ({ data }: Props) => {
  const heatmapData: HeatmapRowData[] = (data.usersMonthlyRetentionRate || []).map((d) => ({
    rowId: dayjs(d.cohort).format("YYYY-MM"),
    cells: d.retention.map((r, i) => ({
      cellId: i.toString(),
      value: r.count / d.count,
    })),
  }));

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Paper variant="widget" sx={{ overflowX: "auto", display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h4">Monthly Retention Rate</Typography>
        <Box overflow="auto">
          {data.isLoadingUsersMonthlyRetentionRate ? (
            <ShimmerLoader sx={{ height: 400 }} />
          ) : (
            <Box height={700} minWidth={800}>
              <HeatmapChart
                xAxisLabel="Month of Journey"
                yAxisLabel="Cohort"
                heatmapRowsData={heatmapData}
                isPercent
                customTooltip={(params: TooltipComponentFormatterCallbackParams) => {
                  if (Array.isArray(params)) return null;
                  const [xIndex, yIndex] = params.value as [number, number, number];
                  const retention = (data.usersMonthlyRetentionRate || [])[yIndex].retention[xIndex];

                  if (!retention) return null;

                  return (
                    <Box padding={1}>
                      <Typography variant="body2" fontWeight={600}>
                        {dayjs(retention.date).format("YYYY-MM")}
                      </Typography>
                      <Typography variant="body2" fontWeight={400} fontSize={12}>
                        Users: {formatAmount(retention.count)}
                      </Typography>
                    </Box>
                  );
                }}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </Paper>
  );
};

export default UsersMonthlyRetentionHeatmap;
