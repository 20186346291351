import { Box, Button, Paper, Typography, Link } from "@frontend/ui";

type Props = {
  snapshotLink: string;
};

const Voting = ({ snapshotLink }: Props) => (
  <Paper variant="card" sx={{ height: 240 }}>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={3} height="100%">
      <Typography variant="h3">Active Voting for This Asset is Ongoing</Typography>
      <Box>
        <Link href={snapshotLink} target="_blank" underline="none" color="inherit">
          <Button variant="contained" color="primary">
            Go to Voting
          </Button>
        </Link>
      </Box>
    </Box>
  </Paper>
);

export default Voting;
