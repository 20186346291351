import { ExternalLinkButton } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable } from "@frontend/ui/chaos-table";
import { useIsInUnifiedProduct } from "src/hooks/use-is-in-unified-product";
import { formatCurrency, formatDate } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useProposedAssetsData } from "./use-proposed-asset-data";

type Props = {
  showLoader?: boolean;
  chain?: string;
};

const ProposedAssetsTable = ({ showLoader, chain: chainProp }: Props) => {
  const { clientName, chain: chainParam } = useParams<"clientName" | "chain">();
  const { data, isLoading } = useProposedAssetsData(chainParam || chainProp);
  const { isInUnifiedProduct, isInMarketsTab } = useIsInUnifiedProduct();

  if (!showLoader && (!data?.length || isLoading)) return null;

  const headers: Header[] = [
    ...(chainParam || chainProp
      ? []
      : ([
          {
            renderType: "TEXT",
            text: "Market",
          },
        ] as Header[])),
    {
      renderType: "TEXT",
      text: "Asset",
    },
    {
      renderType: "TEXT",
      text: "Rec.",
    },
    {
      renderType: "TEXT",
      text: "Market Cap",
      prefix: "$",
    },
    {
      renderType: "TEXT",
      text: "Trading Volume",
      prefix: "$",
    },
    {
      renderType: "TEXT",
      text: "Proposed On",
    },
    {
      renderType: "TEXT",
      text: "Proposal",
      width: "200px",
    },
  ];

  const handleRowClick = (rowId: number) => {
    const asset = data?.[rowId];

    if (!asset) return "";

    const { chain, assetSymbol } = asset;

    if (isInUnifiedProduct) {
      return RoutePath.Risk[isInMarketsTab ? "MarketAssetProposed" : "ChainAssetProposed"]
        .replace(RouteParams.ClientName, clientName!)
        .replace(RouteParams.Chain, chain!)
        .replace(RouteParams.MarketId, chain!)
        .replace(RouteParams.Asset, asset.assetSymbol);
    }

    const assetPathParam = chain ? `${chain}-${assetSymbol}` : assetSymbol;

    return RoutePath.AssetListing.Asset.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.MarketAsset,
      assetPathParam,
    );
  };

  const tableData: RenderData[][] = (data || []).map(
    ({ chain, assetSymbol, marketCap, volume, proposalDate, proposalLink, recommendations }) =>
      [
        ...(chainParam || chainProp
          ? []
          : [
              {
                renderType: "TEXT",
                text: chain,
                token1: chain,
              },
            ]),
        {
          renderType: "TEXT",
          text: assetSymbol,
          token1: assetSymbol,
        },
        {
          renderType: "TEXT",
          text: "",
          isMarked: !!recommendations?.length,
        },
        {
          renderType: "TEXT",
          text: marketCap ? formatCurrency(marketCap) : "-",
          value: marketCap || 0,
        },
        {
          renderType: "TEXT",
          text: volume ? formatCurrency(volume) : "-",
          value: volume || 0,
        },
        {
          renderType: "TEXT",
          text: proposalDate ? formatDate(new Date(proposalDate)) : "-",
        },
        {
          renderType: "CUSTOM",
          component: proposalLink ? <ExternalLinkButton text="Proposal" cryptoIcon="aave" link={proposalLink} /> : "-",
        },
      ] as RenderData[],
  );

  return (
    <ChaosTable
      title="New Asset Listings"
      isLoading={isLoading}
      headers={headers}
      data={tableData}
      isSettingsHidden
      rowHref={handleRowClick}
      initialSortBy={1}
      isInitialSortDesc
      pageSize={data?.length}
      isFilterHidden
      emptyState={{
        title: "No Assets to Show",
      }}
      isFullHeight
    />
  );
};

export default ProposedAssetsTable;
