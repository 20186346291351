import { ReactElement } from "react";
import { RoutePath } from "src/config/routes";
import { CommunityDashboardLayout } from "src/components/layouts";
import { Box, Button } from "@mui/material";
import { CustomIcon } from "@frontend/ui";

export const OraclePortalLayout = ({ children }: { children: ReactElement }) => (
  <CommunityDashboardLayout
    homePath={RoutePath.OraclePortal.PriceFeeds}
    showClientToolsDropdown={false}
    shareText="Discover the heartbeat of DeFi with @chaos_labs Oracle Risk Portal! Explore real-time Oracle data now!"
    shareHashtags={["Defi", "Oracles", "ChaosLabs"]}
    headerButtonsPrefix={
      <Box>
        <Button
          color="secondary"
          href="https://chaoslabs.xyz/blog?tag=Oracles"
          target="_blank"
          startIcon={<CustomIcon icon="external-link" />}
        >
          Oracle Research Blog
        </Button>
      </Box>
    }
    tabs={[
      {
        label: "Price Feeds",
        path: RoutePath.OraclePortal.PriceFeeds,
      },
      // {
      //   label: "Research",
      //   path: RoutePath.OraclePortal.Research,
      // },
    ]}
  >
    {children}
  </CommunityDashboardLayout>
);
