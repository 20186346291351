import { Link, Box, Chip, Typography, Tooltip, Button, CustomIcon } from "@frontend/ui";
import { NotificationsChannelConfig } from "../../types";

type TitleCellProps = {
  title: string;
  isOpen?: boolean;
};

export const TitleCell = ({ title, isOpen = false }: TitleCellProps) => (
  <Typography
    sx={{
      gap: 1,
      display: "flex",
      alignItems: "center",
      width: "100%",
    }}
  >
    <Tooltip title={title}>
      <Box component="span" sx={{ display: "block", overflow: "hidden", textOverflow: "ellipsis" }}>
        {title}
      </Box>
    </Tooltip>
    {isOpen && <Chip size="small" color="error" label="Open" />}
  </Typography>
);

type LinkCellProps = {
  href?: string;
  title?: string;
};

export const LinkCell = ({ title, href }: LinkCellProps) => {
  if (!href) {
    return null;
  }
  return (
    <Link
      href={href}
      target="_blank"
      color="white.main"
      sx={{
        gap: 1,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        fontSize: 14,
      }}
    >
      <CustomIcon icon="external-link" size="small" />
      {title}
    </Link>
  );
};

type NotificationChannelLinksProps = {
  notificationsChannels?: NotificationsChannelConfig[];
};

export const NotificationChannelLinks = ({ notificationsChannels = [] }: NotificationChannelLinksProps) => {
  if (!notificationsChannels.length) {
    return null;
  }
  return (
    <Box display="flex" gap={1}>
      {notificationsChannels.map(({ channel, link }) => (
        <Box key={channel}>
          <Box>
            <Button
              color="secondary"
              onClick={() => {
                const url = link;
                window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600");
              }}
              startIcon={<CustomIcon icon={`${channel}-color`} sx={{ svg: { width: 24, height: 24 } }} />}
            >
              Join {channel.charAt(0).toUpperCase() + channel.slice(1)} Channel
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
