import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { Fragment } from "react";
import { toTitleCase } from "@frontend/ui/utils/formatters";
import Markets from "../markets";
import { RiskTabsLayout } from "../../../components/risk-tabs-layout";
import {
  replaceHomePath,
  renderMarketPageLayout,
  renderAssetPageLayout,
  renderEModePageLayout,
  DefaultMarketPageTabRedirect,
} from "./layout";
import {
  MarketOverview,
  MarketListedAssets,
  MarketProposedAssets,
  EModeList,
  MarketAmm,
  MarketAssetsRecommendations,
} from "../tabs";
import {
  AssetDetails,
  AssetPools,
  ProposedAsset,
  EModeDetails,
  AssetRecommendations,
  ExperimentResults,
  SimulationResults,
} from "../pages";
import { MarketsProvider, MarketListedAssetsProvider, MarketAssetsRecommendationsProvider } from "../context";
import { experimentTypes } from "../pages/asset-recommendations/experiment-results/types";

const MarketsRoutes = () => {
  const { clientName } = useParams();
  const { isMultiMarket, chains } = useClientConfig();

  return (
    <MarketsProvider>
      <MarketListedAssetsProvider>
        <MarketAssetsRecommendationsProvider>
          <Routes>
            <Route
              index
              element={
                isMultiMarket ? (
                  <RiskTabsLayout>
                    <Markets />
                  </RiskTabsLayout>
                ) : (
                  <Navigate
                    to={RoutePath.Risk.MarketDetailsListedAssets.replace(RouteParams.ClientName, clientName!).replace(
                      RouteParams.MarketId,
                      chains[0],
                    )}
                    replace
                  />
                )
              }
            />
            <Route path={replaceHomePath(RoutePath.Risk.MarketDetails)} element={<DefaultMarketPageTabRedirect />} />

            <Route
              path={replaceHomePath(RoutePath.Risk.MarketDetailsOverview)}
              element={renderMarketPageLayout(<MarketOverview />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketDetailsListedAssets)}
              element={renderMarketPageLayout(<MarketListedAssets />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketAssetPools)}
              element={renderAssetPageLayout(<AssetPools />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketAssetOverview)}
              element={renderAssetPageLayout(<AssetDetails />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketDetailsProposedAssets)}
              element={renderMarketPageLayout(<MarketProposedAssets />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketAssetProposed)}
              element={renderAssetPageLayout(<ProposedAsset />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketEmodeDetails)}
              element={renderEModePageLayout(<EModeDetails />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketEModes)}
              element={renderMarketPageLayout(<EModeList />)}
            />
            <Route path={replaceHomePath(RoutePath.Risk.MarketAmm)} element={renderMarketPageLayout(<MarketAmm />)} />

            {/* Recommendations */}
            {[true, false].map((isUnderReview: boolean) => (
              <Fragment key={`isUnderReview-${isUnderReview.toString()}`}>
                <Route
                  path={replaceHomePath(RoutePath.Risk.MarketDetailsRecommendations(isUnderReview))}
                  element={renderMarketPageLayout(<MarketAssetsRecommendations />)}
                />
                <Route
                  path={replaceHomePath(RoutePath.Risk.MarketAssetRecommendations(isUnderReview))}
                  element={renderAssetPageLayout(<AssetRecommendations />)}
                />
                {experimentTypes.map((experimentType) => {
                  const experimentBreadcrumbTitle = `Experiment Results ${toTitleCase(experimentType)}`;

                  const experimentPathKey =
                    experimentType === "current" ? "ExperimentResultsCurrent" : "ExperimentResultsRecommended";
                  const simulationResultsPathKey =
                    experimentType === "current" ? "SimulationResultsCurrent" : "SimulationResultsRecommended";

                  const experimentPath = RoutePath.Risk[experimentPathKey](isUnderReview);

                  const getBreadcrumbs = (isSimulationResults?: boolean) => (marketId: string, asset: string) => [
                    {
                      href: RoutePath.Risk.MarketAssetRecommendations(isUnderReview)
                        .replace(RouteParams.ClientName, clientName!)
                        .replace(RouteParams.MarketId, marketId)
                        .replace(RouteParams.Asset, asset),
                      title: asset,
                    },
                    ...(isSimulationResults
                      ? [
                          {
                            href: experimentPath
                              .replace(RouteParams.ClientName, clientName!)
                              .replace(RouteParams.MarketId, marketId)
                              .replace(RouteParams.Asset, asset),
                            title: experimentBreadcrumbTitle,
                          },
                        ]
                      : []),
                  ];

                  return (
                    <Fragment key={`${experimentType}-experiment-route`}>
                      <Route
                        path={replaceHomePath(experimentPath)}
                        element={renderAssetPageLayout(<ExperimentResults type={experimentType} />, {
                          currentBreadcrumbTitle: experimentBreadcrumbTitle,
                          getAdditionalBreadcrumbs: getBreadcrumbs(),
                        })}
                      />
                      <Route
                        path={replaceHomePath(RoutePath.Risk[simulationResultsPathKey](isUnderReview))}
                        element={renderAssetPageLayout(<SimulationResults />, {
                          currentBreadcrumbTitle: "Simulation Results",
                          getAdditionalBreadcrumbs: getBreadcrumbs(true),
                        })}
                      />
                    </Fragment>
                  );
                })}
              </Fragment>
            ))}
          </Routes>
        </MarketAssetsRecommendationsProvider>
      </MarketListedAssetsProvider>
    </MarketsProvider>
  );
};
export default MarketsRoutes;
