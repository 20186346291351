import { EChartsOption } from "echarts";
import { palette } from "../../../theme/palette";
import { formatAxis } from "../../../utils/formatters";
import { HistogramChartProps } from "./types";
import { getFractionDigitsByDiff, valueFormatter } from "./utils";

export const getHistogramOptions = ({
  name,
  bins,
  totalSamples,
  barColor,
  yAxisOptions,
  xAxisOptions,
  isZerosFiltered,
  dontFormatValues,
  barWidth,
  barStyle,
}: HistogramChartProps): EChartsOption => {
  const formatBinValue = (value: number, fractionDigits: number) =>
    formatAxis(
      value,
      fractionDigits,
      xAxisOptions?.isCurrency ? xAxisOptions?.currencyCode || "USD" : undefined,
      false,
      0,
    );

  const isLastBinOutlier = bins[bins.length - 1]?.end === Number.MAX_VALUE;

  let values: [string, number][];

  if (dontFormatValues) {
    values = bins.map((bin) => [valueFormatter(false)(bin.start), bin.value]);
  } else {
    ({ values } = bins.reduce(
      ({ values: accValues, prevValue }, { value, start, end }, idx) => {
        const isFirstBin = idx === 0;
        const isOutliersBin = end === Number.MAX_VALUE;
        const binSize = bins[0].end - bins[0].start;
        const binStartValue = binSize < 1 ? start : Math.floor(start);
        const binEndValue = binSize < 1 ? end : Math.floor(end);
        const isStartZero = binStartValue === 0;
        const isFirstBinValueBucketEnd = isFirstBin && isZerosFiltered && isStartZero;
        const prefix = isOutliersBin ? "> " : isFirstBinValueBucketEnd ? "< " : "";
        const valueToFormat = isFirstBinValueBucketEnd ? binEndValue : binStartValue;

        const fractionDigits = getFractionDigitsByDiff(
          prevValue === undefined ? valueToFormat : prevValue,
          valueToFormat,
        );
        const formattedValue = `${prefix}${formatBinValue(valueToFormat, fractionDigits)}`;

        const newValues = [...accValues];
        newValues.push([formattedValue, value]);
        return {
          values: newValues,
          prevValue: valueToFormat,
        };
      },
      {
        values: [],
      } as {
        values: [string, number][];
        prevValue?: number;
      },
    ));
  }

  const totalObservations = totalSamples || bins.reduce((acc, { value }) => acc + value, 0);

  const data: [string, number][] = !yAxisOptions?.isPercent
    ? values
    : values.map(([value, count]) => [value, count / totalObservations]);

  return {
    tooltip: {
      renderMode: "auto",
      verticalAlign: "middle",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: valueFormatter(yAxisOptions?.isPercent),
    },
    grid: {
      left: yAxisOptions?.label ? 45 : 1,
      right: 15,
      bottom: 40,
    },
    xAxis: {
      name,
      nameLocation: "middle",
      nameGap: 50,
      type: "category",
      data: values.map(([value]) => value),
      nameTextStyle: {
        color: palette.white.main,
      },
      axisLabel: {
        showMaxLabel: true,
        formatter: (value: string, index: number) => {
          if (isLastBinOutlier && data.length > 2 && index === data.length - 2) {
            return "";
          }
          return valueFormatter(false)(value);
        },
      },
    },
    yAxis: {
      name: yAxisOptions?.label,
      nameLocation: "middle",
      nameGap: 60,
      type: "value",
      axisLabel: {
        formatter: valueFormatter(yAxisOptions?.isPercent),
      },
      nameTextStyle: {
        color: palette.white.main,
      },
      min: 0,
      max: yAxisOptions?.maxValue,
      ...(yAxisOptions?.isPercent
        ? {
            max: yAxisOptions?.maxValue ? yAxisOptions.maxValue / totalObservations : 1,
          }
        : {}),
    },
    series: {
      name: "",
      type: "bar",
      barWidth: barWidth ?? 8,
      barGap: 1,
      data: data.map(([, count]) => count),
      animation: false,
      color: barColor,
      itemStyle: {
        borderRadius: [50, 50, 0, 0],
        ...barStyle,
      },
    },
  };
};
