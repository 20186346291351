import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "../../../config/routes";
import { replaceHomePath } from "./utils";
import { OverviewTab, ChainsTab, PoolsTab, CreditAccountsTab } from "../tabs";
import { ChainDetailsPage, PoolDetailsPage, CreditManagerDetailsPage } from "../pages";
import { renderChainPageLayout, renderTabsLayout } from "./layout";
import { ChaosApolloProvider } from "../../../utils/graphql";
import { FeatureFlagsProvider } from "../../../utils/feature-flags";

const RiskMonitoringLeverageRoutes = () => {
  const { clientName } = useParams();
  const defaultRoute = RoutePath.Risk.Overview.replace(RouteParams.ClientName, clientName!);

  return (
    <ChaosApolloProvider product="leverage">
      <FeatureFlagsProvider>
        <Routes>
          <Route index element={<Navigate to={defaultRoute} replace />} />
          <Route path={replaceHomePath(RoutePath.Risk.Overview)} element={renderTabsLayout(<OverviewTab />)} />
          <Route path={replaceHomePath(RoutePath.Risk.Chains)} element={renderTabsLayout(<ChainsTab />)} />
          <Route path={replaceHomePath(RoutePath.Risk.Pools)} element={renderTabsLayout(<PoolsTab />)} />
          <Route
            path={replaceHomePath(RoutePath.Risk.CreditAccounts)}
            element={renderTabsLayout(<CreditAccountsTab />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.ChainDetails)}
            element={renderChainPageLayout(<ChainDetailsPage />)}
          />
          <Route path={replaceHomePath(RoutePath.Risk.Pool)} element={renderTabsLayout(<PoolDetailsPage />)} />
          <Route
            path={replaceHomePath(RoutePath.Risk.CreditManager)}
            element={renderTabsLayout(<CreditManagerDetailsPage />)}
          />
          <Route path="*" element={<Navigate to={defaultRoute} replace />} />
        </Routes>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
};

export default RiskMonitoringLeverageRoutes;
