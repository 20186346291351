import { ValueOverTimeChart } from "@frontend/ui";

const TvrChart = () => {
  const seriesData = [] as [number, number][];

  return (
    <ValueOverTimeChart
      title="TVR Over Time"
      series={[
        {
          label: "TVR",
          data: seriesData,
        },
      ]}
      emptyState
      emptyStateText="TBD"
    />
  );
};

export default TvrChart;
