import { ChaosTable } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { formatDate } from "@frontend/ui/utils/formatters";
import { AssetAudit } from "../../generated";

type Props = {
  data: AssetAudit[];
};

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Auditor",
  },
  {
    renderType: "TEXT",
    text: "Date",
  },
  {
    renderType: "TEXT",
    text: "Report",
  },
];

const AuditHistory = ({ data }: Props) => {
  const tableData: RenderData[][] = data.map(({ auditor, auditDate, reportLink, reportLinkLabel }) => [
    {
      renderType: "TEXT",
      text: auditor,
    },
    {
      renderType: "TEXT",
      text: formatDate(new Date(auditDate)),
      value: auditDate,
    },
    ...((reportLink
      ? [
          {
            renderType: "LINK",
            text: reportLinkLabel || "View Report",
            href: reportLink,
          },
        ]
      : [
          {
            renderType: "TEXT",
            text: "-",
          },
        ]) as RenderData[]),
  ]);

  return (
    <ChaosTable
      title="Audit History"
      description="Procedural and smart contract security audits"
      data={tableData}
      headers={headers}
    />
  );
};

export default AuditHistory;
