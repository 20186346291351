import { useLocation } from "react-router-dom";
import { useAssetsConfigQuery } from "src/pages/asset-listing-portal/generated";
import { useMarketListedAssetsContext, useMarketAssetsRecommendationsContext } from "../context";

export const useAssetsDropdownOptionsByTab = (marketId: string) => {
  const location = useLocation();

  const { listedAssets, isLoading: isLoadingListedAssets } = useMarketListedAssetsContext();

  const { assetsRecommendations, isLoading: isLoadingAssetsRecommendations } = useMarketAssetsRecommendationsContext();

  const isProposedTab = location.pathname.includes("proposed");
  const isRecommendationsTab = location.pathname.includes("recommendations");

  const { loading: isLoadingProposedAssets, data: proposedAssetsData } = useAssetsConfigQuery({
    variables: { chain: marketId || null },
    skip: !isProposedTab,
  });

  const isLoading = isLoadingListedAssets || isLoadingProposedAssets || isLoadingAssetsRecommendations;

  const listedAssetsNames = listedAssets?.map((a) => a.asset);
  const proposedAssetNames = proposedAssetsData?.assetsConfig?.map((a) => a.assetSymbol);
  const recommendedAssetsNames = assetsRecommendations?.map((d) => d.assetName);

  if (isProposedTab) {
    return { isLoading, assets: proposedAssetNames };
  }

  if (isRecommendationsTab) {
    return { isLoading, assets: recommendedAssetsNames };
  }

  return { isLoading, assets: listedAssetsNames };
};
