import { palette } from "@frontend/ui/theme/palette";
import { formatAmount, formatCurrency } from "@frontend/ui/utils/formatters";
import { EChartsOption } from "echarts";
import dayjs from "dayjs";
import { echartsTooltipFormatter } from "@frontend/ui";
import { useStablecoinDataContext } from "../../provider";

export const useMinBurnChartOption = (): EChartsOption | null => {
  const { mintBurn = [] } = useStablecoinDataContext();

  if (!mintBurn) {
    return null;
  }

  const sorted = mintBurn;
  const totalBorrow = sorted.map((m) => [m.date * 1000, m.totalBorrowAmount]);
  const totalRepay = sorted.map((m) => [m.date * 1000, m.totalRepayAmount]);

  return {
    tooltip: {
      renderMode: "auto",
      verticalAlign: "middle",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (v) => formatCurrency(Number(v)),
      formatter: echartsTooltipFormatter({
        headerFormatter: (value) => dayjs(Number(value)).format("MMM D, YYYY h:mm A"),
      }),
    },
    grid: {
      right: 15,
      bottom: 0,
      top: 50,
      left: 10,
    },
    legend: {
      data: ["Mint", "Redeem"],
    },
    xAxis: {
      nameLocation: "middle",
      nameGap: 50,
      type: "category",
      axisLabel: {
        formatter: (v: string) => dayjs(Number(v)).format("DD/MM"),
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      axisLabel: {
        formatter: (v: number) => formatAmount(v, { currency: "USD", notation: "compact" }),
      },
    },
    series: [
      {
        name: "Mint",
        type: "bar",
        barWidth: 8,
        barGap: 1,
        data: totalBorrow,
        color: palette.orange.main,
        itemStyle: {
          borderRadius: [50, 50, 0, 0],
        },
      },
      {
        name: "Redeem",
        type: "bar",
        barWidth: 8,
        barGap: 1,
        data: totalRepay,
        color: palette.aqua.main,
        itemStyle: {
          borderRadius: [50, 50, 0, 0],
        },
      },
    ],
  };
};
