import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AssetListingTable } from "./components";
import { useClientConfig } from "./clients-config";

const OverviewPage = () => {
  const { mainPage } = useClientConfig();
  const navigate = useNavigate();

  useEffect(() => {
    if (mainPage) {
      navigate(mainPage.path);
    }
  }, [mainPage, navigate]);

  return <AssetListingTable />;
};

export default OverviewPage;
