import { Box, Paper, Tooltip, Typography, Link } from "@frontend/ui";
import { AssetConfig, ProposedAssetData } from "src/pages/asset-listing-portal/generated";
import { formatAmount, formatCurrency } from "@frontend/ui/utils/formatters";
import { InfoItem } from "src/components/info-item";
import { ScoreBadge } from "../../../components";
import { getDaysSince, getAvgVolume, getRatingsAverage } from "./utils";
import { getAaveOverallRisk } from "./aave-risk-calculation";

const riskInfoContainerStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  p: 2,
};

type Props = {
  proposedAssetData: ProposedAssetData;
  assetConfig: AssetConfig;
};

const OverallRisk = ({ proposedAssetData, assetConfig }: Props) => {
  const { smartContractRisk, counterpartyRisk, marketRisk } = getAaveOverallRisk(proposedAssetData, assetConfig);

  const { txCount, holdersCount, contractDeploymentDate } = assetConfig;
  const { marketCapUsd, volumeUsdHistory } = proposedAssetData;
  const { volumeAverage30d, volumeAverage90d } = getAvgVolume(volumeUsdHistory || []);

  if (!marketCapUsd) return null;

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h3">Overall Risk</Typography>
          <ScoreBadge rating={getRatingsAverage([smartContractRisk, counterpartyRisk, marketRisk])} />
        </Box>
        <Typography>
          Asset risk score based on the risk scale created by Aave team as a reference guide, which can be used by the
          Aave community, for asset risk analysis.{" "}
          <Link
            target="_blank"
            href="https://docs.aave.com/risk/asset-risk/methodology"
            sx={{ textDecoration: "none", display: "inline-block" }}
          >
            <Typography variant="h5" color="turqouise.main">
              Discover Methodology
            </Typography>
          </Link>
        </Typography>
      </Box>
      <Box display="flex" gap={3} flexWrap="wrap">
        <Paper variant="widget" sx={riskInfoContainerStyle}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h4">Smart Contract Risk</Typography>
            <ScoreBadge rating={smartContractRisk} />
          </Box>
          <Box display="flex" gap={3}>
            <InfoItem
              title="Days"
              value={formatAmount(getDaysSince(contractDeploymentDate || 0), { notation: "standard" })}
            />
            <InfoItem title="Transactions" value={formatAmount(txCount || 0, { notation: "standard" })} />
          </Box>
        </Paper>
        <Paper variant="widget" sx={riskInfoContainerStyle}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h4">Counter-party Risk</Typography>
            <ScoreBadge rating={counterpartyRisk} />
          </Box>
          <Box display="flex" gap={3}>
            <InfoItem title="Holders" value={formatAmount(holdersCount || 0, { notation: "standard" })} />
            <InfoItem title="Permissions" value="-" />
          </Box>
        </Paper>
        <Paper variant="widget" sx={riskInfoContainerStyle}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h4">Market Risk</Typography>
            <ScoreBadge rating={marketRisk} />
          </Box>
          <Box display="flex" gap={3}>
            <InfoItem title="Market Cap" value={formatCurrency(marketCapUsd)} />
            <InfoItem
              title="Average Daily Volume"
              value={
                <Box display="flex" gap={2}>
                  <Tooltip title="1 Month Avg">
                    <Box display="flex" gap={1}>
                      <Typography variant="caption">1M:</Typography>
                      <Typography>{formatAmount(volumeAverage30d, { currency: "USD" })}</Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip title="3 Months Avg">
                    <Box display="flex" gap={1}>
                      <Typography variant="caption">3M:</Typography>
                      <Typography>{formatAmount(volumeAverage90d, { currency: "USD" })}</Typography>
                    </Box>
                  </Tooltip>
                </Box>
              }
            />
            <InfoItem title="Normalized Volatility" value="-" />
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};
export default OverallRisk;
