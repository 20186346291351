import { Box, Paper, StackedBarChart, Typography } from "@frontend/ui";
import { StringChartSeries } from "@frontend/ui/echarts/types";
import { formatPercent } from "@frontend/ui/utils/formatters";
import { ProviderDeviationDistribution } from "src/pages/oracle-portal/types";
import {
  PriceFeedTimeSpan,
  priceFeedTimeSpanOptions,
  useProvidersSelectContext,
} from "../../../../context/providers-select-context";
import { useFeedData } from "../../use-price-feed-details";
import { Endpoints } from "../../../../api";
import { TimeSpanPicker } from "../../../../../ccar-lending-page/components/time-span-picker";
import BenchmarkLabel from "../benchmark-label";

export const formatBucketRange = (min: number, max: number | null) => {
  const minPercent = formatPercent(min / 100, { maximumFractionDigits: 4 });
  const maxPercent = max ? formatPercent(max / 100, { maximumFractionDigits: 4 }) : null;

  return maxPercent ? `${minPercent} - ${maxPercent}` : `> ${minPercent}`;
};

const DeviationDistributionChart = () => {
  const { providerNameById } = useProvidersSelectContext();
  const {
    response: deviationDistribution,
    isLoading,
    timeSpan,
    setTimeSpan,
  } = useFeedData(Endpoints.PriceFeedDeviationDistribution);

  const series = (deviationDistribution || []).map((providerDistribution: ProviderDeviationDistribution) => {
    const { buckets } = providerDistribution;
    const seriesData: [string, number][] = buckets.map((bucket) => {
      const bucketId = formatBucketRange(bucket.min_range, bucket.max_range);

      return [bucketId, Number(bucket.value)] as [string, number];
    });

    const protocolSeries: StringChartSeries = {
      label: providerNameById?.[providerDistribution.provider_id],
      type: "bar",
      data: seriesData,
    };
    return protocolSeries;
  });

  return (
    <Paper variant="card">
      <Paper variant="widget" sx={{ flex: 1, display: "flex" }}>
        <Box flex={1} width={0}>
          <StackedBarChart
            chartHeight={370}
            showXAxisLine={false}
            title="Distribution of Provider Deviations from the Benchmark Oracle Price"
            description={
              <Box>
                <Typography>
                  Distribution of price deviations from the benchmark oracle price for each provider within defined
                  ranges
                </Typography>
                <BenchmarkLabel />
              </Box>
            }
            isLoading={isLoading}
            series={series || []}
            currency=""
            isPercent
            yAxisLabel="Observed Sample Proportion (%)"
            xAxisLabel="Deviation Buckets"
            headerSuffix={
              <TimeSpanPicker<PriceFeedTimeSpan>
                selectedTimeSpan={timeSpan}
                onSelectTimeSpan={setTimeSpan}
                timeSpanOptions={priceFeedTimeSpanOptions}
                hideLabel
              />
            }
          />
        </Box>
      </Paper>
    </Paper>
  );
};

export default DeviationDistributionChart;
