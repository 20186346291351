import { OverviewData } from "../use-overview-data";
import ChainCategoryHistoryChart from "../../components/chain-category-history-chart";
import { TimeSpan } from "../../../generated";

type Props = {
  data: OverviewData;
};
const StablecoinAmountHistory = ({ data }: Props) => (
  <ChainCategoryHistoryChart
    data={data?.stablecoinAmountHistory}
    title="Number of Tokens Transferred by Stablecoin"
    yAxisLabel="Number of Tokens"
    isLoading={data.isLoadingStablecoinAmountHistory}
    timeSpan={TimeSpan.Month}
  />
);

export default StablecoinAmountHistory;
