import { Navigate, useParams } from "react-router-dom";
import { Box, NotificationBanner, Paper } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { RouteParams, RoutePath } from "src/config/routes";
import { useShouldPerformUnifiedRedirect } from "src/utils/feature-flags";
import { FC } from "react";
import { Chain } from "../../generated";
import { useClientConfig } from "../../clients-config";
import { useMarketDetails } from "./use-market-details";
import {
  MarketStatsCards,
  WalletsTable,
  MarketAssetDistribution,
  MarketWalletDistribution,
  MarketTotalValueAtRisk,
  MarketDistributionOverTime,
  HistoricalPriceChart,
} from "./components";
import { BorrowHealthHistogram, SupplyHealthHistogram } from "./components/health-histograms";
import { Client } from "../../types";
import AssetMultiValueStatsCard from "./components/asset-multivalue-stats-card";
import { OnChainLiquidity } from "../../../../components/on-chain-liquidity";
import { TopWallets } from "../../components/top-wallets";
import EventsTable from "../../components/events-table/events-table";
import { useMarketsContext } from "../markets-new/context/markets-context";
import { createMarketCanonicalName } from "./utils";
import IsolationModeBar from "./components/isolation-mode-bar";
import { MarketHistoryCharts } from "../markets-new/components/market-history-charts";

export const extractChainAndToken = (asset: string): string[] => {
  const splitted: string[] = asset
    .trim()
    .split("-")
    .filter((el: string) => !!el);
  if (splitted.length !== 2) return [];
  return splitted;
};

type Props = {
  marketId: string;
  chain: Chain;
  tokenSymbol: string;
  hideHeader?: boolean;
  isNonBorrowableAsset?: boolean;
};

const MarketDetailsInner = ({ hideHeader, marketId, chain, tokenSymbol, isNonBorrowableAsset }: Props) => {
  const { clientName } = useParams<{ clientName: Client }>();

  const { marketType, marketDetails, stableCoin, markets: marketsConfig } = useClientConfig();

  const isProtocolStablecoin = tokenSymbol?.toLowerCase() === stableCoin?.asset.toLowerCase();

  const marketDetailsTables = (marketDetails?.tables ?? []).filter(
    (table) => !isProtocolStablecoin || stableCoin.riskTab?.excludedTables?.includes(table) !== true,
  );

  const marketDetailsCharts = (marketDetails?.charts ?? []).filter(
    (chart) => !isProtocolStablecoin || stableCoin.riskTab?.excludedCharts?.includes(chart) !== true,
  );

  const market = marketsConfig?.borrowAsset
    ? createMarketCanonicalName(
        {
          borrowAsset: marketsConfig?.borrowAsset,
          collateralAsset: tokenSymbol,
        },
        marketType,
      )
    : undefined;

  const {
    isLoading: isLoadingMarket,
    marketWalletsBorrowersHealth,
    marketWalletsSuppliersHealth,
    marketStats,
    marketAssetsDistribution,
    marketTotalValueAtRisk,
    marketWalletsDistribution,
    assetHistoricalPrices,
  } = useMarketDetails(clientName!, chain, tokenSymbol, marketId ? undefined : market, marketId);
  const { markets, isLoading: isLoadingMarkets } = useMarketsContext();
  const marketName = markets?.find((m) => m.id === marketId)?.name;
  const breadcrumbsLinks = [
    {
      title: "Markets",
      href: RoutePath.Risk.Markets.replace(RouteParams.ClientName, clientName!),
    },
  ];

  const isLoading = !!isLoadingMarkets || !!isLoadingMarket;
  const showSupplyHealth =
    marketStats?.current.totalSupply !== 0 && marketDetailsCharts.includes("supplyWalletsHealth");
  const showBorrowHealth =
    marketStats?.current.totalBorrow !== 0 && marketDetailsCharts.includes("borrowWalletsHealth");
  const showCollateralAtRisk = marketDetailsCharts.includes("collateralAtRisk") && !isProtocolStablecoin;
  const showMarketsDistribution =
    !(marketStats?.current.totalBorrow === 0) && marketDetailsCharts.includes("marketsDistributionOverTime");

  return (
    <Box display="flex" flexWrap="nowrap" flexDirection="column" height="100%" width="100%">
      {!hideHeader && (
        <PageHeader
          pageTitle={tokenSymbol}
          breadcrumbTitle={marketsConfig?.borrowAsset ?? chain}
          breadCrumbTitleIcon={marketsConfig?.borrowAsset ?? chain}
          breadcrumbsLinks={breadcrumbsLinks}
          containerStyle={{ mt: 0, mb: 4 }}
          icon={wrappedSymbolToIconSymbol(tokenSymbol || "")}
          suffixComponent={<AssetMultiValueStatsCard isLoading={isLoading} marketStats={marketStats} />}
        />
      )}
      <Box display="flex" flexDirection="column" flex={1}>
        {!isProtocolStablecoin && (
          <Box mb={[1, 2, 3]} display="flex" flexWrap="wrap" gap={1}>
            <MarketStatsCards stats={marketStats} isNonBorrowableAsset={isNonBorrowableAsset} />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={[1, 2, 3]}>
          {marketStats?.current.isolationModeInformation?.isIsolated && (
            <NotificationBanner
              bgcolor="orange.opacity50"
              message="Isolation Mode - Asset can be used as collateral in isolation mode only."
              tooltip="In Isolation mode you cannot supply other assets as collateral for borrowing. Assets used as collateral in Isolation mode can only be borrowed to a specific debt ceiling."
            />
          )}
          {marketDetailsCharts.includes("historicalPrices") && marketStats?.current.coinGeckoPrice && (
            <HistoricalPriceChart
              isLoading={isLoading}
              assetHistoricalPrices={assetHistoricalPrices}
              currentPrice={marketStats?.current.coinGeckoPrice}
            />
          )}
          {marketDetailsTables.includes("wallets") && (
            <WalletsTable
              asset={tokenSymbol}
              chain={chain}
              market={market}
              marketName={marketName}
              marketId={marketId}
            />
          )}
          <Paper variant="card">
            <MarketHistoryCharts
              chain={chain}
              tokenSymbol={marketsConfig?.borrowAsset ?? tokenSymbol}
              market={market}
              marketId={marketId}
              hideTotalBorrowChart={marketStats?.current.totalBorrow === 0}
              hideTotalSupplyChart={marketStats?.current.totalSupply === 0}
              showTimeSpanPicker
            />
          </Paper>
          {marketStats?.current.isolationModeInformation?.isIsolated && (
            <IsolationModeBar
              totalDebtUsd={marketStats.current.isolationModeInformation.isolationModeTotalDebtUsd!}
              debtCeilingUsd={marketStats.current.isolationModeInformation.debtCeilingUsd!}
            />
          )}
          {marketDetailsCharts.includes("topWallets") && marketStats && (
            <Paper variant="card">
              <TopWallets
                chain={chain}
                marketId={marketId}
                marketName={marketName}
                tokenSymbol={tokenSymbol}
                hideTopBorrowers={marketStats.current.totalBorrow === 0}
                hideTopSuppliers={marketStats.current.totalSupply === 0}
              />
            </Paper>
          )}
          {marketDetailsCharts.includes("priceImpact") && <OnChainLiquidity chain={chain} token={tokenSymbol} />}

          {(showSupplyHealth || showBorrowHealth || showCollateralAtRisk || showMarketsDistribution) && (
            <Paper variant="card">
              <Box
                sx={{
                  display: "grid",
                  gap: [1, 2, 3],
                  gridTemplateColumns: {
                    xs: "1fr",
                    md: "1fr 1fr",
                  },
                  " .MuiPaper-root:nth-of-type(odd):last-child": {
                    gridColumn: {
                      md: "span 2",
                    },
                  },
                }}
              >
                {showSupplyHealth && (
                  <SupplyHealthHistogram
                    walletsHealth={marketWalletsSuppliersHealth}
                    tokenSymbol={tokenSymbol}
                    marketName={marketName}
                  />
                )}
                {showBorrowHealth && (
                  <BorrowHealthHistogram
                    walletsHealth={marketWalletsBorrowersHealth}
                    tokenSymbol={marketsConfig?.borrowAsset ?? tokenSymbol}
                    marketName={marketName}
                  />
                )}
                {showCollateralAtRisk && (
                  <MarketTotalValueAtRisk asset={tokenSymbol} totalValueAtRisk={marketTotalValueAtRisk} />
                )}
                {showMarketsDistribution && (
                  <Box sx={{ gridColumn: "1 / span 2" }}>
                    <MarketDistributionOverTime chain={chain} tokenSymbol={tokenSymbol} />
                  </Box>
                )}
              </Box>
            </Paper>
          )}
          {marketDetailsCharts.includes("marketsDistribution") && marketAssetsDistribution && (
            <MarketAssetDistribution asset={tokenSymbol} assetsDistribution={marketAssetsDistribution} />
          )}
          {marketDetailsCharts.includes("walletsDistribution") && marketWalletsDistribution?.data && (
            <MarketWalletDistribution asset={tokenSymbol} walletsDistribution={marketWalletsDistribution.data} />
          )}
          {marketDetailsTables.includes("assetEvents") && (
            <EventsTable eventsQuery={{ marketId, assetSymbol: tokenSymbol }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const useMarketRedirectRoute = () => {
  const { clientName, market: marketParam = "" } = useParams<{
    clientName: Client;
    market?: string;
  }>();
  const { marketType, isMultichain, chains } = useClientConfig();
  let route = "";
  const [marketParamToken, marketParamChain] = extractChainAndToken(marketParam);
  if (marketType === "ChainMarket") {
    if (!isMultichain) {
      route = RoutePath.Risk.ChainAssetOverview.replace(RouteParams.ClientName, clientName!)
        .replace(RouteParams.Chain, chains[0])
        .replace(RouteParams.Asset, marketParam);
    } else {
      route = RoutePath.Risk.MarketAssetOverview.replace(RouteParams.ClientName, clientName!)
        .replace(RouteParams.MarketId, marketParamChain)
        .replace(RouteParams.Asset, marketParamToken);
    }
  } else if (marketType === "ChainAssetMarket") {
    route = RoutePath.Risk.MarketDetailsOverview.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.MarketId, `${marketParamChain}-${marketParamToken}`)
      .replace(RouteParams.Asset, marketParamToken);
  }

  return route;
};

const WithUnifiedProducMarketRedirect: FC = ({ children }) => {
  const route = useMarketRedirectRoute();
  const shouldRedirect = useShouldPerformUnifiedRedirect();
  if (shouldRedirect) {
    return <Navigate to={route} replace />;
  }

  return <div className="unified-product-market-redirect">{children}</div>;
};

const MarketDetails = (props: Props) => (
  <WithUnifiedProducMarketRedirect>
    <MarketDetailsInner {...props} />
  </WithUnifiedProducMarketRedirect>
);

export default MarketDetails;
