import { Header } from "@frontend/types";
import { Box, ChaosTable, Typography, textCell } from "@frontend/ui";
import { useProvidersSelectContext } from "src/pages/oracle-portal/context/providers-select-context";
import { LatencyStatisticsResponse, LatencyStatistics, providerTypes } from "src/pages/oracle-portal/types";
import { useParams } from "react-router-dom";
import { getProviderIconByName } from "../../utils";
import BenchmarkLabel from "../benchmark-label";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Provider",
  },
  {
    renderType: "DELTA",
    text: "Average",
    tooltip: "The average across all latency measurements",
  },
  {
    renderType: "DELTA",
    text: "Median",
    tooltip: "The median across all latency measurements",
  },
  {
    renderType: "DELTA",
    text: "P95",
    tooltip:
      "The 95th percentile across all latency measurements to help identify the periods with the highest latency observed",
  },
];

const LegendItem = ({ color, label }: { color: string; label: string }) => (
  <Box display="flex" alignItems="center" gap={0.5}>
    <Box bgcolor={color} width={8} height={8} borderRadius="50%" />
    <Typography variant="caption">{label}</Typography>
  </Box>
);

const Legend = () => (
  <Box display="flex" alignItems="center" gap={2}>
    <LegendItem color="error.main" label="Slower than Benchmark" />
    <LegendItem color="success.main" label="Faster than Benchmark" />
    <LegendItem color="almostWhite.main" label="Equal to Benchmark" />
  </Box>
);

const getTableData = (latencyStatistics: LatencyStatistics[], providerNameById?: Record<string, string>) =>
  latencyStatistics.map((p) => [
    textCell(providerNameById?.[p.provider_id] || p.provider, undefined, getProviderIconByName(p.provider)),
    textCell(`${Math.round(Math.abs(p.avg_latency_ms))} ms`, p.avg_latency_ms),
    textCell(`${Math.round(Math.abs(p.median_latency_ms))} ms`, p.median_latency_ms),
    textCell(`${Math.round(Math.abs(p.p95_latency_ms))} ms`, p.p95_latency_ms),
  ]);

type Props = {
  latencyStatisticsResponse?: LatencyStatisticsResponse;
  isLoading: boolean;
};

const LatencyTable = ({ latencyStatisticsResponse, isLoading }: Props) => {
  const { feedId } = useParams();
  const { providerNameById, selectedProviders, selectedBaselineProvider } = useProvidersSelectContext();
  const { data, error_code: errorCode } = latencyStatisticsResponse || {};

  return (
    <>
      {providerTypes.map((type) => {
        const providersData = data?.filter((l) => l.type === type);

        return (
          (!selectedProviders?.length || selectedProviders?.some((p) => p.type === type)) && (
            <ChaosTable
              key={`latency-table-${type}`}
              title={type === "oracle" ? "Oracle" : "Exchange"}
              description={
                <Box>
                  <BenchmarkLabel />
                  <Legend />
                </Box>
              }
              headers={headers}
              data={getTableData(
                providersData?.filter((p) => p.error_code !== "LOW_SAMPLING_PROVIDER") || [],
                providerNameById,
              )}
              isLoading={isLoading}
              isFilterHidden
              isSettingsHidden
              footerContent={
                providersData?.some((l) => l.error_code === "LOW_SAMPLING_PROVIDER") ? (
                  <Typography variant="caption">
                    Note: Providers lacking frequent price updates for {feedId!} are omitted to maintain comparison
                    accuracy and prevent misleading conclusions
                  </Typography>
                ) : undefined
              }
              emptyState={
                errorCode
                  ? {
                      icon: "stop",
                      title: `Analysis unavailable: The infrequent price updates from ${
                        selectedBaselineProvider?.display_name || "benchmark"
                      } for ${feedId!} prevent accurate comparisons with high-frequency price update providers`,
                    }
                  : undefined
              }
            />
          )
        );
      })}
    </>
  );
};

export default LatencyTable;
