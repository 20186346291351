import { ObserverGroupMetadata, ScriptResource } from "@frontend/types";
import { Box, Paper, Typography } from "@frontend/ui";
import { FilterBar } from "@frontend/ui/filter-bar";
import { useMemo, useState } from "react";
import { ObservationResult } from "src/pages/parameter-recommendations-page/public-simulations-types";
import { simplifyForSearch as simplify } from "src/utils/search";
import { ObserversView } from "./observers-view";

type Props = {
  observations: ObservationResult[];
  observers: Partial<ScriptResource>[];
  observerGroupsMetadata?: ObserverGroupMetadata[];
  iterationsRange: [number, number];
};

const ObserversSection = ({ observations, observerGroupsMetadata, iterationsRange, observers }: Props) => {
  const [observationsSearchValue, setObservationsSearchValue] = useState("");

  const searchedObservations: ObservationResult[] = useMemo(() => {
    if (!observations) return [];
    if (!observationsSearchValue) return observations;

    const isMatch = (value: string) => simplify(value).includes(simplify(observationsSearchValue));

    const filteredObservations = observations.filter(({ name }) => isMatch(name));
    return filteredObservations;
  }, [observations, observationsSearchValue]);

  return (
    <Paper
      variant="card"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: 3,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        flexGrow={1}
        width="100%"
      >
        <Typography variant="h2">Observations</Typography>
        <FilterBar
          value={observationsSearchValue}
          onChange={setObservationsSearchValue}
          placeholder="Search for observer"
          sx={{ minWidth: 344, ml: "auto" }}
        />
      </Box>
      <ObserversView
        data={searchedObservations}
        observers={observers}
        observerGroupsMetadata={observerGroupsMetadata}
        iterationsRange={iterationsRange}
      />
    </Paper>
  );
};

export default ObserversSection;
