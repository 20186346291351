import { Header, RenderData } from "@frontend/types";
import { Box, ChaosTable, Typography, numberCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import {
  PriceFeedTimeSpan,
  priceFeedTimeSpanOptions,
  useProvidersSelectContext,
} from "../../../../context/providers-select-context";
import { Endpoints } from "../../../../api";
import { useFeedData } from "../../use-price-feed-details";
import { TimeSpanPicker } from "../../../../../ccar-lending-page/components/time-span-picker";
import { getProviderIconByName } from "../../utils";
import BenchmarkLabel from "../benchmark-label";

const deviationStatCell = (percent: number) =>
  numberCell(percent / 100, {
    maximumFractionDigits: 4,
    isPercent: true,
  });

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Provider",
  },
  {
    renderType: "TEXT",
    text: "Average Deviation",
    tooltip: `The average across all deviation measurements from the benchmark oracle price`,
  },
  {
    renderType: "TEXT",
    text: "Median Deviation",
    tooltip: `The median across all deviation measurements from the benchmark oracle price`,
  },
  {
    renderType: "TEXT",
    text: "P75 Deviation",
    tooltip: `The 75th percentile across all deviation measurements from the benchmark oracle price`,
  },
  {
    renderType: "TEXT",
    text: "P95 Deviation",
    tooltip: `TThe 95th percentile across all latency measurements from the benchmark oracle price`,
  },
];

const DeviationTable = () => {
  const { feedId } = useParams();
  const { providerNameById } = useProvidersSelectContext();

  const {
    response: deviationStatistics,
    isLoading,
    timeSpan,
    setTimeSpan,
  } = useFeedData(Endpoints.PriceFeedDeviationStatistics);

  const data: RenderData[][] = (deviationStatistics || []).map((s) => [
    textCell(providerNameById?.[s.provider_id] || s.provider, undefined, getProviderIconByName(s.provider)),
    deviationStatCell(s.avg_abs_diff_percent),
    deviationStatCell(s.median_abs_diff_percent),
    deviationStatCell(s.p75_abs_diff_percent),
    deviationStatCell(s.p95_abs_diff_percent),
  ]);

  return (
    <ChaosTable
      title="Price Deviation Metrics by Provider"
      description={
        <Box>
          <Typography>
            Statistical deviations of {feedId!} prices from the benchmark oracle price across providers
          </Typography>
          <BenchmarkLabel />
        </Box>
      }
      headers={headers}
      data={data}
      isLoading={isLoading}
      isFilterHidden
      isSettingsHidden
      headerSuffixComponent={
        <Box height="100%" alignSelf="flex-start">
          <TimeSpanPicker<PriceFeedTimeSpan>
            selectedTimeSpan={timeSpan}
            onSelectTimeSpan={setTimeSpan}
            timeSpanOptions={priceFeedTimeSpanOptions}
            hideLabel
          />
        </Box>
      }
    />
  );
};

export default DeviationTable;
