import { Box, Paper, StackedBarChart, Typography } from "@frontend/ui";
import { useProvidersSelectContext } from "src/pages/oracle-portal/context/providers-select-context";
import groupBy from "lodash/groupBy";
import { StringChartSeries } from "@frontend/ui/echarts/types";
import { GrangerCausality } from "src/pages/oracle-portal/types";
import BenchmarkLabel from "../benchmark-label";

type Props = {
  valueType: "pValue" | "likelihood";
  grangerCausality?: GrangerCausality[];
  isLoading: boolean;
};

const GrangerCausalityChart = ({ valueType, grangerCausality, isLoading }: Props) => {
  const { selectedBaselineProvider, providerNameById } = useProvidersSelectContext();

  const grangerCausalityByProvider = groupBy(grangerCausality || [], "provider_id");
  const series = Object.entries(grangerCausalityByProvider).map(([provider, data]: [string, GrangerCausality[]]) => {
    const seriesData: [string, number][] = data.map((d) => [
      `${d.latency_ms / 1000} s`,
      valueType === "likelihood" ? d.median_likelihood_ratio : d.median_p_value,
    ]);
    const protocolSeries: StringChartSeries = {
      label: providerNameById?.[provider] || provider,
      type: "bar",
      data: seriesData,
      stack: provider,
    };
    return protocolSeries;
  });

  return (
    <Paper variant="widget" sx={{ flex: 1, display: "flex" }}>
      <Box flex={1} width={0}>
        <StackedBarChart
          chartHeight={340}
          showXAxisLine={false}
          title={valueType === "likelihood" ? "Likelihood Ratio" : "P Value"}
          description={
            <Box>
              <Typography>
                {valueType === "likelihood"
                  ? "Measures the predictive power of one lagged times series over the other"
                  : "Statistical significance value"}
              </Typography>
              <BenchmarkLabel />
            </Box>
          }
          isLoading={isLoading}
          series={series}
          currency=""
          yAxisLabel={valueType === "likelihood" ? "Likelihood Ratio" : "P-Value"}
          xAxisLabel={`${selectedBaselineProvider?.display_name || "Benchmark"} Lead Time in seconds`}
        />
      </Box>
    </Paper>
  );
};

export default GrangerCausalityChart;
