import { useParams } from "react-router-dom";
import { aptosConfig } from "./clients.ts";
import { ChainAnalyticsClientConfig, Client } from "./types";

export const clientsConfig: Record<Client, ChainAnalyticsClientConfig> = {
  aptos: aptosConfig,
};
export const useClientConfig = (): ChainAnalyticsClientConfig => {
  const { clientName } = useParams<{ clientName: Client }>();
  return clientsConfig[clientName!];
};
