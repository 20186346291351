import { Grid } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import OverallRisk from "./overall-risk";
import RevenueEstimation from "./revenue-estimation";
import TickSize from "./tick-size";
import { useDydxAssetInfo } from "./use-dydx-asset-info";
import { useAssetPageParams } from "../../hooks";

const DydxSection = () => {
  const { marketAsset } = useAssetPageParams();
  const { isLoading, assetInfo } = useDydxAssetInfo(marketAsset);

  if (isLoading) return <Loader />;

  if (!assetInfo) return null;

  const { revenue, tick } = assetInfo;
  const tickGaugeDisplayed = tick?.currentTick !== "N/A" && tick?.optimalTick !== "N/A";

  const gridItemsSize = tickGaugeDisplayed
    ? {
        xs: 12,
        lg: 6,
      }
    : {};

  return (
    <>
      <OverallRisk assetInfo={assetInfo} />
      <Grid container spacing={2}>
        {revenue && (
          <Grid item flex={1} {...gridItemsSize}>
            <RevenueEstimation revenueEstimation={revenue} />
          </Grid>
        )}
        {tick && (
          <Grid item {...gridItemsSize}>
            <TickSize tick={tick} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DydxSection;
