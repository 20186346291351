import { RevenueEstimation as RevenueEstimationCard } from "src/pages/asset-page/revenue-estimation/revenue-estimation";

type Props = {
  revenueEstimation: number;
};

const RevenueEstimation = ({ revenueEstimation }: Props) => (
  <RevenueEstimationCard
    title="Revenue Estimation"
    subtitle="Estimated monthly revenue for this asset in USD if listed,
    calculated based on derivatives trading volumes,
    price changes and price volatility."
    value={revenueEstimation}
  />
);

export default RevenueEstimation;
