import { useParams, useSearchParams } from "react-router-dom";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, apyCell, assetCell, currencyCell, numberCell } from "@frontend/ui/chaos-table";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useMarketListedAssetsContext } from "../../context";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Asset",
    width: "2%",
    isAutocomplete: true,
  },
  {
    renderType: "TEXT",
    text: "Price",
    prefix: "$",
    width: "5%",
  },
  {
    renderType: "TEXT",
    text: "Supply APY",
    suffix: "%",
    width: "5%",
  },
  {
    renderType: "TEXT",
    text: "Borrow APY",
    suffix: "%",
    width: "5%",
  },
  {
    renderType: "TEXT",
    text: "Total Supply",
    prefix: "$",
    width: "5%",
  },
  {
    renderType: "TEXT",
    text: "Total Borrow",
    prefix: "$",
    width: "5%",
  },
  {
    renderType: "TEXT",
    text: "Collateral at Risk",
    width: "5%",
  },
  {
    renderType: "TEXT",
    text: "Wallets at Risk",
    width: "5%",
  },
];

const MarketAssetsTable = () => {
  const { clientName, marketId } = useParams();
  const marketListedAssetsContext = useMarketListedAssetsContext();
  const { markets, stableCoin } = useClientConfig();
  const [searchParams] = useSearchParams();

  const { assetDrilldown } = markets || {};

  const marketAssets = marketListedAssetsContext.listedAssets;
  const { isLoading } = marketListedAssetsContext;

  const handleOnClickRow = (rowIdx: number) => {
    const marketAsset = marketAssets?.[rowIdx];
    const { asset } = marketAsset || {};

    if (!asset) return "";

    if (stableCoin && stableCoin.riskTab && stableCoin.riskTab.enabled && stableCoin.asset === asset) {
      return RoutePath.Risk.StablecoinOverview(stableCoin.asset).replace(RouteParams.ClientName, clientName!);
    }

    return RoutePath.Risk.MarketAssetOverview.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.MarketId, marketId!)
      .replace(RouteParams.Asset, asset);
  };
  const tableData: RenderData[][] | undefined = (marketAssets || []).map(
    (asset) =>
      [
        assetCell(asset.asset),
        currencyCell(asset.price, {
          minValue: 0.001,
        }),
        apyCell(asset.supplyAPY, 0.01),
        apyCell(asset.borrowAPY, 0.01),
        currencyCell(asset.totalSupply),
        currencyCell(asset.totalBorrow),
        currencyCell(asset.totalValueAtRisk),
        numberCell(asset.walletsAtRisk),
      ] as RenderData[],
  );

  return (
    <ChaosTable
      title="Assets"
      headers={headers}
      data={tableData}
      isLoading={isLoading}
      pageSize={15}
      showSearch
      isFilterHidden={false}
      initialSortBy={5}
      isInitialSortDesc
      serchbarPlaceholder="Search"
      rowHref={assetDrilldown?.disabled ? undefined : handleOnClickRow}
      showRowChevron={!assetDrilldown?.disabled}
      isFullHeight
      defaultSearch={searchParams.get("search") || undefined}
    />
  );
};

export default MarketAssetsTable;
