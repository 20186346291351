import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { CompositionOverTimeChart } from "@frontend/ui";
import { useRewardsHistoryQuery } from "../../../../generated";

const RewardsChart = () => {
  const { loading, data } = useRewardsHistoryQuery();

  const seriesByAsset = (data?.rewardsHistory || []).reduce((acc: Record<string, NumberChartSeries>, h) => {
    const newSeriesEntries: [string, NumberChartSeries][] = h.rewards.map((r) => {
      const prevSeries = acc[r.symbol];
      const prevData = prevSeries?.data || [];
      const newPoint = [h.timestamp * 1000, r.amountUsd] as [number, number];
      const newData = [...prevData, newPoint];

      const series: NumberChartSeries = {
        label: r.symbol,
        data: newData,
      };
      return [r.symbol, series];
    });

    const newSeriesObj = Object.fromEntries(newSeriesEntries);

    return { ...acc, ...newSeriesObj };
  }, {});

  return (
    <CompositionOverTimeChart
      title="Total Rewards Over Time"
      series={Object.values(seriesByAsset)}
      isLoading={loading}
    />
  );
};

export default RewardsChart;
