import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import dayjs from "dayjs";
import { UserEngagementData } from "../use-user-engagement-data";
import { ChainHistoryValue, TimeSpan } from "../../../generated";
import { useXAxisSplit, xAxisDateFormatter } from "../../../chart-utils";

type Props = {
  data: UserEngagementData;
};
const NewUsersHistory = ({ data }: Props) => {
  const xAxisSplit = useXAxisSplit();

  const chartData = (data?.chainNewUsersHistory || []).reduce(
    (acc: Record<string, NumberChartSeries>, d: ChainHistoryValue) => {
      const prevSeries = acc[d.chain];
      const prevData = prevSeries?.data || [];
      const newData = [...prevData, [d.timestamp, d.value]] as [number, number][];
      const newSeries: NumberChartSeries = {
        label: d.chain,
        data: newData,
        type: "bar",
      };
      return { ...acc, [d.chain]: newSeries };
    },
    {},
  );
  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        chartHeight={325}
        title="Monthly New Users"
        series={Object.values(chartData)}
        yAxisLabel="Number of New Users"
        currency=""
        isLoading={data.isLoadingChainNewUsersHistory}
        dateFormatter={(timestamp: number) => xAxisDateFormatter(timestamp, TimeSpan.Year)}
        xAxisSplitNumber={xAxisSplit}
        tooltipHeaderFormatter={(timestamp: number | string) => dayjs(Number(timestamp)).format("MMMM")}
      />
    </Paper>
  );
};

export default NewUsersHistory;
