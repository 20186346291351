import { TimeSpan } from "@frontend/ui/echarts/types";
import { useTvlHistoryQuery } from "../../../../generated";
import { HistoryChart } from "../../../../components/history-chart";

type Props = {
  timeSpan: TimeSpan;
};

const TvlChart = ({ timeSpan }: Props) => {
  const { loading, data } = useTvlHistoryQuery({
    variables: {
      timeSpan,
    },
  });

  return (
    <HistoryChart
      title="TVL Over Time"
      label="TVL"
      loading={loading}
      data={data?.tvlHistory}
      cryptoCurrency="ETH"
      timeSpan={timeSpan}
    />
  );
};

export default TvlChart;
