import { useParams } from "react-router-dom";

type ReturnType = string[];

const getSplitParam = (assetParam?: string): ReturnType => {
  const splitArr = assetParam?.split("-") || [];
  if (!splitArr.length || splitArr.length > 2) return [];

  return splitArr;
};

const useSplitPathParam = (paramName = "marketAsset"): ReturnType => {
  const paramsObj = useParams();
  return getSplitParam(paramsObj[paramName]);
};

export default useSplitPathParam;
