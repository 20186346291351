import { Box, CryptoIcon, Typography } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";

type AssetBreadcrumbProps = {
  market?: string;
  showMarketIcon?: boolean;
  asset: string;
  isActive?: boolean;
};
export const AssetBreadcrumb = ({ market, showMarketIcon, asset, isActive }: AssetBreadcrumbProps) => (
  <Box display="flex" gap={1}>
    {market && (
      <>
        {showMarketIcon && (
          <CryptoIcon
            icon={market.toLowerCase()}
            sx={{
              img: { width: "24px", height: "24px" },
            }}
          />
        )}
        <Typography color={isActive ? "white.main" : "almostWhite.main"}>{market}</Typography>
        <Typography color={isActive ? "white.main" : "almostWhite.main"}>/</Typography>
      </>
    )}
    <CryptoIcon
      icon={wrappedSymbolToIconSymbol(asset)}
      sx={{
        img: { width: "24px", height: "24px" },
      }}
    />
    <Typography color={isActive ? "white.main" : "almostWhite.main"}>{asset}</Typography>
  </Box>
);
