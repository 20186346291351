import { FC } from "react";
import { colorScaleWarm } from "@frontend/ui/utils/color-scale";
import { VictoryPie, VictoryLabel } from "victory";
import { Box } from "@frontend/ui/box";

export interface AssetGagueChartProps {
  optimalValue: number;
  currentValue: number;
  unit?: string;
  colors?: string[];
  chartHeight?: number;
}

export const AssetGagueChart: FC<AssetGagueChartProps> = ({
  unit,
  optimalValue,
  currentValue,
  colors,
  chartHeight,
}) => {
  const colorScale = colors || colorScaleWarm;
  const height = chartHeight || 248;
  const x1 = height / 2;
  const y1 = height / 2;
  // moving the gague angle between 5 and 175 degrees
  // according to the difference between wanted and actual
  let diff = currentValue - optimalValue;
  diff = Math.min(diff, 0.95 * optimalValue);
  diff = Math.max(diff, -0.95 * optimalValue);
  const deg = (diff / optimalValue) * 90;
  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box>
          <svg style={{ width: "100%" }} viewBox="-50 -50 350 210">
            <defs>
              <linearGradient id="lineGradient">
                <stop offset="0%" stopColor="##FFFFFF" stopOpacity="0" />
                <stop offset="35%" stopColor="##FFFFFF" stopOpacity="0.5" />
              </linearGradient>
              <radialGradient id="gagueGradient" cx="50%" cy="0%" r="80%">
                <stop offset="12.34%" stopColor="#24B3D0" />
                <stop offset="100%" stopColor="#DE4469" />
              </radialGradient>
            </defs>
            <VictoryPie
              standalone={false}
              startAngle={90}
              endAngle={-90}
              data={[
                {
                  x: 1,
                  y: 1,
                },
              ]}
              width={height}
              height={height}
              padding={0}
              innerRadius={chartHeight ? chartHeight / 3 : 80}
              labelRadius={chartHeight ? chartHeight / 3 : 80}
              colorScale={colorScale}
              style={{
                labels: { display: "none" },
                data: { fill: "url(#gagueGradient)" },
              }}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{
                fontSize: 24,
                fontWeight: 500,
                fill: "#24B3D0",
                fontFamily: "Archivo, sans-serif",
              }}
              x={x1}
              y={-30}
              text={`${unit || ""}${optimalValue ?? "N/A"}`}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{
                fontSize: 32,
                fontWeight: 600,
                fill: "#fff",
                fontFamily: "Archivo, sans-serif",
              }}
              x={x1}
              y={y1 + 20}
              text={`${unit || ""}${currentValue ?? "N/A"}`}
            />

            <g transform={`rotate(${deg} ${x1} ${y1})`}>
              <line x1={x1} y1={y1} x2={x1} y2={0} stroke="#30343B" strokeWidth="6" />
              <line x1={x1} y1={y1} x2={x1} y2={y1 - 38} stroke="white" strokeWidth="6" strokeOpacity="0.9" />
            </g>
            <line x1={x1} y1={5} x2={x1} y2={-10} stroke="white" strokeWidth="4" />
          </svg>
        </Box>
      </Box>
    </Box>
  );
};
