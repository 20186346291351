import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { useMemo } from "react";
import { matchPath, useLocation, Location } from "react-router-dom";
import { RouteParams } from "../config/routes";
import { dataQueryURL } from "./data-query-url";

type Product =
  | "ccar-lending"
  | "ccar-perpetuals"
  | "chain-analytics"
  | "leverage"
  | "staking"
  | "asset-listing"
  | "common"
  | "dydx"
  | "osmosis"
  | "uniswap";

const DATA_QUERY_URL = dataQueryURL();

const getClientProtocolHeader = (location: Location) => {
  const match = matchPath(
    {
      path: `/${RouteParams.ClientName}`,
      end: false,
    },
    location.pathname,
  );
  return match?.params.clientName || "";
};

type Props = {
  product: Product;
  children: JSX.Element | JSX.Element[];
};

export const ChaosApolloProvider = ({ product, children }: Props) => {
  const location = useLocation();
  const protocol = getClientProtocolHeader(location);
  const apolloClient = useMemo(
    () =>
      new ApolloClient<NormalizedCacheObject>({
        uri: `${DATA_QUERY_URL}query/${product}`,
        cache: new InMemoryCache(),
        credentials: "include",
        headers: {
          protocol,
          authkey: localStorage.getItem("authkey") || "",
        },
        name: protocol,
      }),
    [protocol, product],
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
