import { useMemo } from "react";
import { ChaosTable } from "@frontend/ui";
import { formatAmount, formatPercent } from "@frontend/ui/utils/formatters";
import { Header, RenderData } from "@frontend/types";
import { ParamRecommendationType } from "./types";

type Props = {
  data: ParamRecommendationType[];
  beforeChangeColTitle?: string;
  isLoading?: boolean;
};

const ParamRecommendationsTable = ({ data, beforeChangeColTitle, isLoading }: Props) => {
  const hasCurrentValues = data.some(({ currentValue }) => currentValue !== undefined && currentValue !== null);

  const headers: Header[] = useMemo(
    () => [
      {
        renderType: "TEXT",
        text: "Parameter",
      },
      ...((hasCurrentValues
        ? [
            {
              renderType: "TEXT",
              text: beforeChangeColTitle || "Current",
            },
          ]
        : []) as Header[]),
      {
        renderType: "TEXT",
        text: "Recommended",
        icon: "chaos",
      },
      ...((hasCurrentValues
        ? [
            {
              renderType: "TEXT",
              text: "Change",
            },
          ]
        : []) as Header[]),
    ],
    [beforeChangeColTitle, hasCurrentValues],
  );

  const tableData: RenderData[][] = useMemo(
    () =>
      data.map(({ paramName, paramTooltip, currentValue, recommendedValue, isPercent, currency }) => {
        const formatValue = (val: number) =>
          isPercent
            ? formatPercent(val)
            : formatAmount(val, { currency: currency ?? undefined, maximumFractionDigits: 2 });

        const valuesDiff = recommendedValue && currentValue ? recommendedValue - currentValue : undefined;
        const changeSign = valuesDiff && valuesDiff > 0 ? "+" : "";

        return [
          {
            renderType: "TEXT",
            text: paramName,
            tooltipText: paramTooltip,
          },
          ...((hasCurrentValues
            ? [
                {
                  renderType: "TEXT",
                  text: currentValue !== undefined && currentValue !== null ? formatValue(currentValue) : "-",
                },
              ]
            : []) as RenderData[]),
          {
            renderType: "LABELS",
            text:
              recommendedValue !== undefined
                ? formatValue(recommendedValue)
                : currentValue !== undefined && currentValue !== null
                  ? formatValue(currentValue)
                  : "-",
            status: "RECOMMENDED",
          },
          ...((hasCurrentValues
            ? [
                {
                  renderType: "TEXT",
                  text: valuesDiff ? `${changeSign}${formatValue(valuesDiff)}` : "",
                },
              ]
            : []) as RenderData[]),
        ];
      }),
    [data, hasCurrentValues],
  );
  return (
    <ChaosTable
      headers={headers}
      data={tableData}
      isLoading={isLoading}
      title="Recommendations"
      isSettingsHidden
      isFilterHidden
    />
  );
};

export default ParamRecommendationsTable;
