import { useAuth } from "@frontend/ui/auth";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "../../../config/routes";
import { replaceHomePath } from "./utils";
import { OverviewTab, WithdrawalsTab } from "../tabs";
import { renderTabsLayout } from "./layout";
import { ChaosApolloProvider } from "../../../utils/graphql";
import { FeatureFlagsProvider } from "../../../utils/feature-flags";
import { NotPermittedError } from "../../../components/not-permitted-error";

const RiskMonitoringStakingRoutes = () => {
  const { clientName } = useParams();
  const auth = useAuth();
  const defaultRoute = RoutePath.Risk.Overview.replace(RouteParams.ClientName, clientName!);

  if (!auth.hasRole("chaos-labs-member")) {
    return <NotPermittedError />;
  }

  return (
    <ChaosApolloProvider product="staking">
      <FeatureFlagsProvider>
        <Routes>
          <Route index element={<Navigate to={defaultRoute} replace />} />
          <Route path={replaceHomePath(RoutePath.Risk.Overview)} element={renderTabsLayout(<OverviewTab />)} />
          <Route path={replaceHomePath(RoutePath.Risk.Withdrawals)} element={renderTabsLayout(<WithdrawalsTab />)} />
          <Route path="*" element={<Navigate to={defaultRoute} replace />} />
        </Routes>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
};

export default RiskMonitoringStakingRoutes;
