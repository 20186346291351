import { PieChart } from "@frontend/ui/charts";
import { useState } from "react";
import { Box, CustomSwitch, Paper, Typography } from "@frontend/ui";
import {
  AggregateAssetStatsFragment,
  useAllMarketsQuery,
  useMarketAssetsBreakdownQuery,
} from "src/pages/ccar-lending-page/generated";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";

type Props = {
  asset?: AggregateAssetStatsFragment;
};

type AggregatedAssetsByMarketId = {
  [marketId: string]: {
    total: number;
    valueByAsset: Record<string, number>;
  };
};
export const AssetCrossChainDistribution = ({ asset }: Props) => {
  const navigate = useNavigate();
  const { clientName } = useParams();
  const { marketType } = useClientConfig();
  const { loading: isLoadingMarkets, data: marketsData } = useAllMarketsQuery();

  const { loading, data } = useMarketAssetsBreakdownQuery({
    variables: {
      chain: null,
      tokenSymbol: asset?.symbol ?? null,
      marketId: null,
    },
    skip: !asset,
  });
  const isCollateralizable = asset?.isCollateralizable;
  const [field, setField] = useState<"total" | "valueAtRisk">("total");
  const checked = field === "valueAtRisk";
  const aggregated: AggregatedAssetsByMarketId = (data?.assetsBreakdown || [])
    .filter((a) => a.underlyingSymbol.toLowerCase() === asset?.symbol.toLowerCase())
    .map((a) => {
      const value = field === "total" ? a.totalSupply : a.totalValueAtRisk || 0;

      return {
        marketId: a.marketId || a.chain,
        value,
        symbol: a.asset,
        underlyingSymbol: a.underlyingSymbol,
      };
    })
    .reduce((acc: AggregatedAssetsByMarketId, curr) => {
      const { total, valueByAsset } = acc[curr.marketId] || {};
      const newTotal = (total || 0) + curr.value;
      const newValueByAsset = { ...(valueByAsset || {}), [curr.symbol]: curr.value };
      const newChainValue = {
        total: newTotal,
        valueByAsset: newValueByAsset,
      };

      return { ...acc, [curr.marketId]: newChainValue };
    }, {});

  const toggleDistribution = () => {
    setField(field === "total" ? "valueAtRisk" : "total");
  };

  const onClickMarketItem = (marketId: string, valueByAsset: Record<string, number>) => {
    const entries = Object.entries(valueByAsset);

    if (entries.length > 1) {
      const assetPath = RoutePath.Risk.MarketDetailsListedAssets.replace(RouteParams.ClientName, clientName!).replace(
        RouteParams.MarketId,
        marketId,
      );
      return navigate({
        pathname: assetPath,
        search: `?${createSearchParams({ search: asset?.symbol || "" }).toString()}`,
      });
    }

    const [assetSymbol] = entries[0];

    const assetPath = RoutePath.Risk.MarketAssetOverview.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.MarketId, marketId)
      .replace(RouteParams.Asset, assetSymbol);

    return navigate(assetPath);
  };

  const getMarketNameById = (marketId: string) => {
    const market = marketsData?.allMarkets.find((m) => m.id === marketId);
    return market?.name ?? marketId;
  };

  return (
    <Paper variant="widget">
      {loading || isLoadingMarkets ? (
        <ShimmerLoader sx={{ borderRadius: 2, height: 300 }} />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Box>
              <Typography variant="h3">Exposure Distribution</Typography>
              <Typography>Showing aggregated exposure across all markets</Typography>
            </Box>
            {isCollateralizable && (
              <CustomSwitch
                uncheckedLabel="Total Collateral"
                checkedLabel="Collateral at Risk"
                onChange={toggleDistribution}
                checked={checked}
              />
            )}
          </Box>
          <Box display="flex" gap={6}>
            <PieChart
              format="currency"
              showLegendIcon={marketType === "ChainMarket"}
              legendPrefix="Exposure"
              size={240}
              data={Object.entries(aggregated).map(([marketId, value]) => ({
                x: getMarketNameById(marketId),
                y: value.total,
                onClick: () => onClickMarketItem(marketId, value.valueByAsset),
              }))}
              pieLabelFormatNotation="compact"
            />
            <Box />
          </Box>
        </>
      )}
    </Paper>
  );
};
