import { ProposedAssetsTable } from "src/pages/parameter-recommendations-page/components/proposed-assets-table";
import { ChaosApolloProvider } from "src/utils/graphql";

const ProposedAssets = () => (
  <ChaosApolloProvider product="asset-listing">
    <ProposedAssetsTable showLoader />
  </ChaosApolloProvider>
);

export default ProposedAssets;
