import { CompositionOverTimeChart, Grid, Paper } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { daysAgoByTimeSpan } from "@frontend/ui/echarts/utils";
import { useState } from "react";
import { TimeSpanPicker } from "src/pages/ccar-lending-page/components/time-span-picker";
import {
  PoolCompositionChart,
  TradingVolumeChart,
  DailyFeesChart,
  OpenInterestChart,
  InterestImbalanceChart,
  TvlCompositionChart,
} from "./components";
import { useTradingOverviewQuery } from "../../generated";
import { useClientConfig } from "../../clients-config";
import { TvlChart } from "./components/tvl-chart";
import { MarketsOpenInterestChart } from "./components/markets-open-interest-chart";
import { MarketsFundingRateChart } from "./components/markets-funding-rate-chart";
import { useFeatureFlag } from "../../../../utils/feature-flags";
import { AggOrdersCharts } from "./components/agg-orders-charts";
import { RealizedPnLChart } from "../markets/components/realized-pnl-chart";
import { UniqueUsersChart } from "../markets/components/unique-users-chart";

export const OverviewCharts = () => {
  const [daysAgo, setDaysAgo] = useState(TimeSpan.Quarter);
  const { overview, hourlyHistorySupport } = useClientConfig();
  const aggOrdersChartsEnabled = useFeatureFlag("agg-orders-charts");
  const { data, loading } = useTradingOverviewQuery({
    variables: {
      daysAgo: daysAgoByTimeSpan[daysAgo],
      shouldFetchTVL: !!overview?.charts.includes("tvl"),
      shouldFetchOrderBookDepth: !!overview?.charts.includes("orderBookDepth"),
      hourlySupport: hourlyHistorySupport,
    },
  });

  return (
    <Paper variant="card" key={daysAgo}>
      <Grid container spacing={[1, 2, 3]}>
        <Grid item xs={12} display="flex" justifyContent="end">
          <TimeSpanPicker
            timeSpanOptions={[TimeSpan.Week, TimeSpan.Month, TimeSpan.Quarter]}
            selectedTimeSpan={daysAgo}
            onSelectTimeSpan={setDaysAgo}
          />
        </Grid>
        {overview?.charts.includes("aggOrders") && aggOrdersChartsEnabled && (
          <Grid item xs={12}>
            <AggOrdersCharts daysAgo={daysAgoByTimeSpan[daysAgo]} />
          </Grid>
        )}
        {overview?.charts.includes("marketsOpenInterest") && (
          <Grid item xs={12}>
            <MarketsOpenInterestChart marketsStatsOverTime={data?.marketStatsOverTime} loading={loading} />
          </Grid>
        )}
        {overview?.charts.includes("pool-value") && (
          <Grid item xs={12} md={6}>
            <TradingVolumeChart data={data?.poolValueOverTime} loading={loading} />
          </Grid>
        )}
        {overview?.charts.includes("pool-composition") && (
          <Grid item xs={12} md={6}>
            <PoolCompositionChart data={data?.poolCompositionOverTime} loading={loading} />
          </Grid>
        )}
        {!overview?.charts.includes("marketsOpenInterest") && (
          <>
            <Grid item xs={12}>
              <OpenInterestChart
                shortInterestUsd={data?.statsOverTime?.shortInterestUsd}
                longInterestUsd={data?.statsOverTime?.longInterestUsd}
                loading={loading}
                timeSpan={daysAgo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper variant="widget">
                <CompositionOverTimeChart
                  isLoading={loading}
                  series={Object.values(data?.marketStatsOverTime || []).map((d) => ({
                    label: d.marketId,
                    data: d.shortInterestUsd.map((c) => [c.time, c.value]),
                  }))}
                  title="Short Open Interest"
                  description="Total sum of open short positions per chain over time in USD value."
                  showLegend
                  percentToggle
                  timeSpan={daysAgo}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper variant="widget">
                <CompositionOverTimeChart
                  isLoading={loading}
                  series={Object.values(data?.marketStatsOverTime || []).map((d) => ({
                    label: d.marketId,
                    data: d.longInterestUsd.map((c) => [c.time, c.value]),
                  }))}
                  title="Long Open Interest"
                  description="Total sum of open long positions per chain over time in USD value."
                  showLegend
                  percentToggle
                  timeSpan={daysAgo}
                />
              </Paper>
            </Grid>
            {overview?.charts.includes("realizedPnL") && (
              <Grid item xs={12}>
                <RealizedPnLChart isLoading={loading} realizedPnL={data?.statsOverTime.realizedPnL} />
              </Grid>
            )}
            {overview?.charts.includes("oi-imbalance") && (
              <Grid item xs={12} md={overview?.charts.includes("daily-fees") ? 6 : 12}>
                <InterestImbalanceChart
                  data={data?.statsOverTime.longInterestUsd.map(({ time, value }, i) => {
                    const interestDiff = Math.abs(value - data.statsOverTime.shortInterestUsd[i].value);
                    const totalInterest = value + data.statsOverTime.shortInterestUsd[i].value;
                    return {
                      time,
                      value: totalInterest === 0 ? 0 : interestDiff / totalInterest,
                    };
                  })}
                  loading={loading}
                />
              </Grid>
            )}
          </>
        )}
        {overview?.charts.includes("daily-fees") && (
          <Grid item xs={12} md={6}>
            <DailyFeesChart data={data?.statsOverTime.feesUsd} loading={loading} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper variant="widget">
            <CompositionOverTimeChart
              isLoading={loading}
              series={Object.values(data?.marketStatsOverTime || []).map((d) => ({
                label: d.marketId,
                data: d.volumeUsd.map((c) => [c.time, c.value]),
              }))}
              title="Trading Volume"
              showLegend
              percentToggle
              showAggregateOther
              timeSpan={daysAgo}
            />
          </Paper>
        </Grid>
        {overview?.charts.includes("orderBookDepth") && (
          <Grid item xs={12}>
            <Paper variant="widget">
              <CompositionOverTimeChart
                isLoading={loading}
                series={Array.from(new Set((data?.orderBookSpreads || []).map((s) => s.base_asset))).map((asset) => ({
                  label: asset,
                  data: (data?.orderBookSpreads || [])
                    .filter((s) => s.base_asset === asset)
                    .map((s) => [s.current_time * 1000, s.p50 * s.price]),
                }))}
                title="Order Book Depth Per Market"
                description="Showing the P50 of liquidity within 40bps spread per market, over time."
                showLegend
                percentToggle
              />
            </Paper>
          </Grid>
        )}
        {overview?.charts.includes("fundingRate") && (
          <Grid item xs={12}>
            <MarketsFundingRateChart marketsStatsOverTime={data?.marketStatsOverTime} loading={loading} />
          </Grid>
        )}
        {overview?.charts.includes("tvl") && (
          <Grid item xs={12}>
            <TvlChart data={data?.statsOverTime.tvl ?? []} loading={loading} timeSpan={daysAgo} />
          </Grid>
        )}
        {overview?.charts.includes("tvlComposition") && (
          <Grid item xs={12}>
            <TvlCompositionChart data={data?.statsOverTime.tvlComposition ?? []} loading={loading} timeSpan={daysAgo} />
          </Grid>
        )}
        {overview?.charts.includes("uniqueUsers") && (
          <Grid item xs={12}>
            <UniqueUsersChart isLoading={loading} data={data?.statsOverTime.uniqueUsers} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
