import { useParams } from "react-router-dom";
import { Box } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import useSplitPathParam from "src/hooks/use-split-path-param";
import {
  PageHeader,
  Links,
  Description,
  NotificationsBanners,
  CustomSectionByClient,
  ParamRecommendationsTable,
  TokenOverview,
  MarketsTable,
  AuditHistory,
  SentimentAnalysis,
  Voting,
} from "./sections";
import { useAssetPageData } from "./hooks";

type Props = {
  hideHeader?: boolean;
  chain?: string;
};

const AssetPage = ({ hideHeader, chain: chainProp }: Props) => {
  const { asset: assetParam, chain: chainParam } = useParams();
  const [firstEl, secondEl] = useSplitPathParam();

  const chain = chainProp || chainParam;

  // multichain - {market}-{asset} => asset = secondEl
  // singleChain - {asset} => asset = firstEl
  const asset = assetParam || (secondEl ?? firstEl);

  const { isLoading, assetConfig, assetData } = useAssetPageData(asset, chain);

  if (isLoading || !assetConfig || !assetData) return <Loader />;

  const { description, links, sentiment } = assetData;

  const { coinGeckoId, coinMarketCapId, auditHistory, recommendations, snapshotLink, proposalLink } = assetConfig;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {!hideHeader && <PageHeader proposedAssetData={assetData} />}
      <Links links={links} proposalLink={proposalLink} />
      {description && <Description description={description} />}
      <NotificationsBanners assetConfig={assetConfig} />
      <CustomSectionByClient proposedAssetData={assetData} assetConfig={assetConfig} />
      {!!recommendations?.length && <ParamRecommendationsTable recommendations={recommendations} />}
      <TokenOverview coinGeckoId={coinGeckoId} proposedAssetData={assetData} />
      <MarketsTable coinmarketcapId={coinMarketCapId} />
      {!!auditHistory?.length && <AuditHistory data={auditHistory} />}
      {sentiment && <SentimentAnalysis socialInfo={sentiment} />}
      {snapshotLink && <Voting snapshotLink={snapshotLink} />}
    </Box>
  );
};

export default AssetPage;
