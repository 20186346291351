import { Header } from "@frontend/types";
import { Box, ChaosTable, numberCell, percentCell, textCell, valueLabelsCell } from "@frontend/ui";
import { SimulationParameter } from "src/pages/ccar-lending-page/generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Parameter",
  },
  {
    renderType: "TEXT",
    text: "Current",
  },
  {
    renderType: "TEXT",
    text: "Recommended",
    icon: "chaos",
  },
  {
    renderType: "TEXT",
    text: "Change",
  },
];

type Props = {
  parameters: SimulationParameter[];
};

const ParamsRecommendationsTable = ({ parameters }: Props) => {
  const tableData = parameters.map((param) => {
    const { displayName, valueType, current, recommended } = param.result;
    const diff = !recommended ? 0 : recommended.value - current.value;

    const valueCell = valueType === "percent" ? percentCell : numberCell;

    return [
      textCell(displayName),
      valueCell(current.value),
      valueLabelsCell("RECOMMENDED", recommended?.value || current.value, valueType),
      valueCell(diff),
    ];
  });
  return (
    <Box>
      <ChaosTable headers={headers} data={tableData} title="Recommendations" isSettingsHidden isFilterHidden />
    </Box>
  );
};

export default ParamsRecommendationsTable;
