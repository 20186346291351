import { Box, Grid, Paper } from "@frontend/ui";
import { useOverviewData } from "./use-overview-data";
import {
  BridgeNetFlowByChainChart,
  BridgeNetFlowByTokenChart,
  OverviewCards,
  StablecoinAmountHistory,
  TvlByDappCategoryChart,
} from "./components";

const Overview = () => {
  const data = useOverviewData();
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <OverviewCards data={data} />
      <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <TvlByDappCategoryChart data={data} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <BridgeNetFlowByChainChart data={data} />
          </Grid>
          <Grid item xs={12} md={6}>
            <BridgeNetFlowByTokenChart data={data} />
          </Grid>
        </Grid>
        <StablecoinAmountHistory data={data} />
      </Paper>
    </Box>
  );
};

export default Overview;
