import { useRef, useState } from "react";
import { useAuth } from "@frontend/ui/auth";
import { Box, Button, CustomIcon, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Typography } from "@frontend/ui";
import { Avatar, CircularProgress, ClickAwayListener, Popper } from "@mui/material";
import { palette } from "@frontend/ui/theme/palette";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { Theme, useMediaQuery } from "@frontend/ui/theme";

export const UserInfoCard = () => {
  const auth = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const handleLogin = () => {
    void auth.authenticate();
  };

  if (!auth.isAuthenticated) {
    return (
      <Box>
        {isMobile ? (
          <IconButton color="secondary" onClick={handleLogin}>
            <CustomIcon icon="google" sx={{ svg: { width: 16, height: 16 } }} />{" "}
          </IconButton>
        ) : (
          <Button
            color="secondary"
            onClick={handleLogin}
            startIcon={
              auth.isRefreshing ? (
                <CircularProgress color="inherit" size="16px" />
              ) : (
                <CustomIcon icon="google" sx={{ svg: { width: 16, height: 16 } }} />
              )
            }
          >
            {auth.isRefreshing ? <ShimmerLoader sx={{ height: 24, width: 150 }} /> : "Login"}
          </Button>
        )}
      </Box>
    );
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = () => {
    void auth.logout();
    setOpen(false);
  };

  if (auth.isAuthenticated && auth.user) {
    return (
      <Box>
        <Button
          onClick={handleToggle}
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          sx={{
            padding: isMobile ? "4px" : "8px",
            maxWidth: "200px",
          }}
          color="secondary"
        >
          {auth.user?.picture && <Avatar src={auth.user.picture} sx={{ width: 32, height: 32 }} />}
          {!isMobile && (
            <Typography
              variant="body2"
              sx={{ ml: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {auth.user?.name ?? auth.user?.email}
            </Typography>
          )}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" disablePortal>
          <Paper sx={{ backgroundColor: palette.darkGrey.main, marginTop: "2px" }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" color="error" />
                  </ListItemIcon>
                  <Typography color="error">Logout</Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Box>
    );
  }

  return null;
};
