import { ChaosTable, currencyCell, currencyTrendCell, linkCell, numberCell, textCell } from "@frontend/ui";
import { getBlockDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import { Header, RenderData } from "@frontend/types";
import { useParams } from "react-router-dom";
import { PositionFragment, SortPositionsBy } from "../../../../../../generated";
import { dateCell, dateDiffCell, positionSideCell, positionSymbolCell } from "../../../../../positions/cells";
import { PositionsFilter } from "../../../../../positions/positions-filter";
import { usePositionsData } from "../../../../../positions/use-postions-data";
import { useClientConfig } from "../../../../../../clients-config";
import { RouteParams, RoutePath } from "../../../../../../../../config/routes";

const tableHeaders = (hasSharedCollateral: boolean, showLiquidationBlock?: boolean, open?: boolean): Header[] => [
  {
    renderType: "TEXT",
    text: "Opened",
    width: "20%",
    field: SortPositionsBy.TimeOpened,
  },
  {
    renderType: "TEXT",
    text: open ? "Duration" : "Settled",
    width: !open ? "20%" : undefined,
    nonSortable: open,
  },
  {
    renderType: "ICONS",
    text: "Market",
    field: SortPositionsBy.AssetSymbol,
  },
  {
    renderType: "TEXT",
    text: "Type",
    field: SortPositionsBy.Side,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "Open Price",
    nonSortable: true,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: open ? "Current Price" : "Close Price",
    field: SortPositionsBy.LastPriceUsd,
  },
  ...(open || !hasSharedCollateral
    ? ([
        {
          renderType: "CURRENCY",
          unit: "usd",
          text: hasSharedCollateral ? "Liquidation Price" : "Collateral",
          tooltip: hasSharedCollateral ? undefined : "Collateral at the time the position was opened",
          field: hasSharedCollateral ? SortPositionsBy.LiquidationPrice : SortPositionsBy.CollateralBalanceUsd,
        },
      ] as Header[])
    : []),
  {
    renderType: "TEXT",
    text: "Leverage",
    tooltip: open ? undefined : "Leverage at the time the position was opened",
    field: SortPositionsBy.Leverage,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "Size",
    tooltip: open ? undefined : "Size at the time before the position was closed",
    field: open ? SortPositionsBy.BalanceUsd : SortPositionsBy.OriginalBalanceUsd,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "PnL",
    field: SortPositionsBy.Pnl,
  },
  ...(showLiquidationBlock && !open
    ? [
        {
          renderType: "TEXT",
          text: "Liquidation Block",
          width: "15%",
          field: SortPositionsBy.LiquidationBlock,
        } as Header,
      ]
    : []),
];

const mapRow = (
  position: PositionFragment,
  hasSharedCollateral: boolean,
  chain: string,
  showLiquidationBlock?: boolean,
  open?: boolean,
): RenderData[] => [
  dateCell(position.timeOpened),
  open ? dateDiffCell(position.timeOpened, position.timeClosed) : dateCell(position.timeClosed!),
  positionSymbolCell(position.assetSymbol, position.assetName),
  positionSideCell(position.side),
  currencyCell(position.openPriceUsd),
  currencyCell(position.lastPriceUsd),
  ...(open || !hasSharedCollateral
    ? [
        hasSharedCollateral
          ? currencyCell(position.liquidationPrice)
          : currencyCell(position.balanceUsd / position.leverage),
      ]
    : []),
  numberCell(position.leverage),
  currencyCell(position.balanceUsd),
  currencyTrendCell(position.pnl),
  ...(showLiquidationBlock && !open
    ? [
        position.liquidationBlock
          ? linkCell(
              position.liquidationBlock.toString() || "",
              getBlockDetailsUrl(chain, position.liquidationBlock.toString()),
            )
          : textCell("-"),
      ]
    : []),
];

type PositionsTableProps = {
  title: string;
  account: string;
  subAccount?: number;
  chain: string;
  metadata?: string;
  hasSharedCollateral: boolean;
  open?: boolean;
  showLiquidationBlock?: boolean;
};

export const PositionsTable = ({
  account,
  subAccount,
  metadata,
  title,
  chain,
  open,
  hasSharedCollateral,
  showLiquidationBlock,
}: PositionsTableProps) => {
  const { clientName } = useParams();
  const { positionDetails } = useClientConfig();
  const { positions, loading, filters, onSearch, onFiltersChange, onPageChange, onSortChange } = usePositionsData({
    open,
    account,
    subAccount,
    sort: open ? SortPositionsBy.TimeOpened : SortPositionsBy.TimeClosed,
  });

  return (
    <ChaosTable
      title={title}
      headers={tableHeaders(hasSharedCollateral, showLiquidationBlock, open)}
      isLoading={loading}
      pageSize={10}
      data={positions.map((position) => mapRow(position, hasSharedCollateral, chain, showLiquidationBlock, open))}
      isFullHeight
      isFilterHidden
      showSearch
      serchbarPlaceholder="Search positions"
      customFilter={
        <PositionsFilter key={filters.length} showOpen={!!open} filters={filters} onFiltersChange={onFiltersChange} />
      }
      initialSortBy={open ? 0 : 1}
      isInitialSortDesc
      emptyState={{
        icon: "chaos",
        title: "No positions to show",
      }}
      rowHref={(rowId) => {
        const position = positions[rowId];

        if (positionDetails?.enabled) {
          return RoutePath.Risk.PositionDetails.replace(RouteParams.ClientName, clientName!).replace(
            RouteParams.PositionId,
            position.positionId,
          );
        }
        return "";
      }}
      rowHrefTarget="_blank"
      metadata={metadata}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      onSearch={onSearch}
    />
  );
};
