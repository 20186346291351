import { ValueOverTimeChart } from "@frontend/ui";
import { HistoryPoint, TimeSpan } from "../generated";

type Props = {
  title: string;
  label?: string;
  timeSpan: TimeSpan;
  loading: boolean;
  data?: HistoryPoint[];
  cryptoCurrency?: string;
  isPercent?: boolean;
};

export const HistoryChart = ({ title, label, timeSpan, loading, data, cryptoCurrency, isPercent }: Props) => (
  <ValueOverTimeChart
    title={title}
    series={[
      {
        label,
        data: (data || []).map((d) => [d.timestamp, d.value]),
      },
    ]}
    isLoading={loading}
    timeSpan={timeSpan}
    yAxisMin
    yAxisMax
    cryptoCurrency={cryptoCurrency}
    isPercent={isPercent}
    hideLegend
  />
);
