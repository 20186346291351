import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useAssetsConfigQuery } from "src/pages/asset-listing-portal/generated";
import { Chain } from "src/pages/ccar-lending-page/generated";
import { ListedAssetsContext } from "../context/listed-assets-context";
import { AssetsRecommendationsContext } from "../context/assets-recommendations-context";

export const useAssetsDropdownOptionsByTab = (chain: Chain) => {
  const location = useLocation();
  const { listedAssets, isLoading: isLoadingListedAssets } = useContext(ListedAssetsContext);
  const { assetsRecommendations, isLoading: isLoadingAssetsRecommendations } = useContext(AssetsRecommendationsContext);
  const isProposedTab = location.pathname.includes("proposed");
  const isRecommendationsTab = location.pathname.includes("recommendations");
  const { loading: isLoadingProposedAssets, data: proposedAssetsData } = useAssetsConfigQuery({
    variables: { chain: chain || null },
    skip: !isProposedTab,
  });

  const isLoading = isLoadingAssetsRecommendations || isLoadingListedAssets || isLoadingProposedAssets;

  const listedAssetsNames = listedAssets?.map((a) => a.asset);
  const recommendedAssetsNames = assetsRecommendations?.map((d) => d.asset);
  const proposedAssetNames = proposedAssetsData?.assetsConfig?.map((a) => a.assetSymbol);

  if (isProposedTab) {
    return { isLoading, assets: proposedAssetNames };
  }

  if (isRecommendationsTab) {
    return { isLoading, assets: recommendedAssetsNames };
  }

  return { isLoading, assets: listedAssetsNames };
};
