import dayjs from "dayjs";
import { EChartsOption } from "echarts";
import { palette } from "../../../theme/palette";
import { echartsTooltipFormatter } from "../../tooltipFormatter";
import { NumberChartProps } from "../../types";
import { formatEChartValue, formatXAxisDate } from "../../utils";

export const getValueOverTimeChart = ({
  series,
  isPercent,
  currency,
  yAxisMin,
  yAxisMax,
  zoomable,
  showLegend = true,
  connectNulls = false,
  yAxisLabel,
  timeSpan,
  hideTotal = true,
  useDefaultEchartsTimeFormat,
  dateFormatter,
  xAxisSplitNumber,
  tooltipHeaderFormatter,
  cryptoCurrency,
  hideLegend,
}: NumberChartProps): EChartsOption => {
  const allDates = series.flatMap((s) => s.data.flatMap((d) => d[0])).sort();
  const uniqueDates = Array.from(new Map(allDates.map((d) => [d, null])).keys());

  const allValues = series
    .flatMap((serie) =>
      uniqueDates.map((date) => {
        const matchingDate = serie.data.find((d) => d[0] === date);

        if (!matchingDate) {
          return Infinity;
        }

        return matchingDate[1];
      }),
    )
    .filter((v) => v !== Infinity);

  const min = Math.min(...allValues);
  const max = Math.max(...allValues);
  const range = max - min;
  const padding = range === 0 ? max * 0.2 : range * 0.2;

  const legend = showLegend ? series.map((serie) => serie.label || "") : [];

  return {
    tooltip: {
      trigger: "axis",
      formatter: echartsTooltipFormatter({
        headerFormatter: (value) => tooltipHeaderFormatter?.(value) || dayjs(value).format("MMM D, YYYY h:mm A"),
        valueFormatter: (v) =>
          formatEChartValue({ isPercent, currency, notation: "standard", cryptoCurrency })(Number(v)),
        currency,
        showTotal: !hideTotal,
      }),
      padding: 0,
      renderMode: "auto",
      verticalAlign: "middle",
    },
    legend: {
      data: hideLegend ? [] : legend,
      type: "scroll",
      padding: [0, 24, 0, 0],
      textStyle: {
        fontSize: 14,
      },
    },
    toolbox: {
      itemSize: 0,
      top: 0,
      feature: {
        brush: {
          show: false,
        },
        dataView: {
          show: false,
        },
        dataZoom: {
          yAxisIndex: "none",
        },
        magicType: {
          show: false,
        },
        restore: {},
        saveAsImage: {
          show: false,
        },
      },
    },
    ...(zoomable
      ? {
          dataZoom: {
            type: "inside",
            zoomLock: true,
          },
        }
      : {}),
    grid: {
      bottom: 5,
      top: legend.length && !hideLegend ? 50 : 20,
      left: yAxisLabel ? 50 : 2,
      right: 15,
    },
    xAxis: {
      boundaryGap: false,
      splitNumber: xAxisSplitNumber || 5,
      type: "time",
      axisLabel: {
        hideOverlap: true,
        fontSize: 12,
        formatter: useDefaultEchartsTimeFormat
          ? undefined
          : (v: string) => dateFormatter?.(Number(v)) || formatXAxisDate(Number(v), timeSpan),
      },
    },
    yAxis: {
      min: yAxisMin ? (min >= 0 ? Math.max(min - padding, 0) : min - padding) : undefined,
      max: yAxisMax ? max + padding : undefined,
      type: "value",
      name: yAxisLabel,
      nameLocation: "middle",
      position: "left",
      nameGap: 70,
      axisLabel: {
        fontSize: 12,
        formatter: (v: number) => {
          if (Math.abs(v) >= 1) return formatEChartValue({ isPercent, currency, cryptoCurrency })(Number(v));
          return formatEChartValue({ isPercent, currency, notation: "standard", cryptoCurrency })(Number(v));
        },
      },
    },
    series: series.map((serie) => {
      const color = series.length === 1 ? palette.blue.main : serie.color;

      const baseSerie = {
        name: serie.label,
        smooth: false,
        animation: false,
        symbol: "none",
        connectNulls,
        color,
        lineStyle: series.length <= 3 ? { width: 2 } : { width: 1 },
        data: uniqueDates.map((date) => {
          const matchingDate = serie.data.find((d) => d[0] === date);

          if (!matchingDate) {
            return [];
          }

          return [date, matchingDate[1]];
        }),
      };
      if (serie.type === "bar") {
        return {
          ...baseSerie,
          type: "bar",
          barWidth: "50%",
          barMaxWidth: 12,
          barGap: 1,
          ...(serie.stack && { stack: serie.stack }),
          itemStyle: serie.highlightTrends
            ? {
                color: (item) => {
                  if (Array.isArray(item.data)) {
                    return Number(item.data[1].valueOf()) < 0 ? palette.red.main : palette.green.main;
                  }

                  return palette.green.main;
                },
              }
            : undefined,
        };
      }

      return {
        ...baseSerie,
        type: "line",
      };
    }),
  };
};
