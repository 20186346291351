export const providerTypes = ["exchange", "oracle"] as const;

export type ProviderType = (typeof providerTypes)[number];

export type PriceFeedProvider = {
  id: string;
  name: string;
  type: ProviderType;
  display_name: string;
};

export type PriceFeed = {
  asset: string;
  price: number;
  providers: PriceFeedProvider[];
};

export type PriceFeedsResponse = {
  [asset: string]: Omit<PriceFeed, "asset">;
};

export type PriceFeedPrice = {
  id: string;
  origin: string;
  price: number;
  timestamp: number;
  type: ProviderType;
};

type LatencyErrorCode = "LOW_SAMPLING_BASELINE" | "LOW_SAMPLING_PROVIDER";

export type LatencyStatistics = {
  baseline_id: string;
  provider_id: string;
  asset: string;
  avg_latency_ms: number;
  baseline: string;
  lags_count: number;
  median_latency_ms: number;
  p75_latency_ms: number;
  p95_latency_ms: number;
  provider: string;
  type: ProviderType;
  error_code?: LatencyErrorCode;
};

export type LatencyStatisticsResponse = {
  data: LatencyStatistics[];
  error_code?: LatencyErrorCode;
};

export type DeviationStatistics = {
  baseline_id: string;
  provider_id: string;
  avg_abs_diff_percent: number;
  baseline: string;
  diffs_count: number;
  median_abs_diff_percent: number;
  p75_abs_diff_percent: number;
  p95_abs_diff_percent: number;
  provider: string;
  symbol: string;
  type: ProviderType;
};

export const aggregationTypes = ["avg", "max", "min"] as const;

export type AggregationType = (typeof aggregationTypes)[number];

export type Deviation = { [aggType in AggregationType]: number } & {
  id: string;
  type: ProviderType;
  provider: string;
  symbol: string;
  timestamp: number;
};

export type ProviderDeviationDistribution = {
  baseline_id: string;
  provider_id: string;
  baseline: string;
  buckets: {
    max_range: number | null;
    min_range: number;
    value: string;
  }[];
  provider: string;
  symbol: string;
  type: ProviderType;
};

export type ExchangeVolume = {
  id: string;
  origin: string;
  timestamp: number;
  volume_usd: number;
};

export type ExchangesVwap = {
  aggr_vwap: number;
  median_price: number;
  timestamp: number;
};

export type GrangerCausality = {
  baseline_id: string;
  provider_id: string;
  baseline: string;
  latency_ms: number;
  median_likelihood_ratio: number;
  median_p_value: number;
  provider: string;
  samples_count: number;
  type: ProviderType;
};
