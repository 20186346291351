import { Header, RenderData } from "@frontend/types";
import { ChaosTable, assetCell, diffCell, textCell, valueCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { RouteParams } from "src/config/route-params";
import { RoutePath } from "src/config/routes";
import { useMarketAssetsRecommendationsContext } from "../../context";

const AssetsRecommendationsTable = () => {
  const { clientName, marketId } = useParams();
  const {
    isLoading: isLoadingRecommendations,
    assetsRecommendations,
    isUnderReview,
  } = useMarketAssetsRecommendationsContext();

  const paramsKeysToDisplayName = (assetsRecommendations || []).reduce(
    (acc, asset) => {
      const { parameters } = asset;
      parameters.forEach(({ name, result }) => {
        const { displayName } = result;
        if (!acc[name]) {
          acc[name] = displayName;
        }
      });
      return acc;
    },
    {} as Record<string, string>,
  );

  const paramsDisplayNames = Object.values(paramsKeysToDisplayName);
  const paramsKeys = Object.keys(paramsKeysToDisplayName);

  const paramsHeaders: Header[] = paramsDisplayNames.map((displayName) => ({
    renderType: "TEXT",
    text: displayName,
  }));

  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Asset",
      width: "10%",
    },
    ...paramsHeaders,
  ];

  const tableData: RenderData[][] = (assetsRecommendations || []).map((rec) => {
    const { parameters } = rec;
    const paramsCells: RenderData[] = paramsKeys.map((key) => {
      const param = parameters.find((p) => p.name === key);
      const { current, recommended } = param?.result || {};
      if (!current) return textCell("", 0);
      if (!recommended || current.value === recommended.value) {
        return valueCell(current.value, param?.result?.valueType);
      }
      return diffCell(recommended.value, current.value, param?.result?.valueType);
    });
    return [assetCell(rec.assetName), ...paramsCells];
  });

  const handleOnClickRow = (rowIdx: number) => {
    const clickedAsset = assetsRecommendations?.[rowIdx];

    if (!clickedAsset) return "";

    return RoutePath.Risk.MarketAssetRecommendations(isUnderReview)
      .replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.MarketId, marketId!)
      .replace(RouteParams.Asset, clickedAsset.assetName);
  };

  return (
    <ChaosTable
      isLoading={isLoadingRecommendations}
      initialSortBy={0}
      isInitialSortDesc
      headers={headers}
      data={tableData}
      title="Recommendations"
      rowHref={handleOnClickRow}
      showRowChevron
      emptyState={{
        title: "No Recommendations to Show",
      }}
      isFullHeight
    />
  );
};

export default AssetsRecommendationsTable;
