import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import Chains from "../chains";
import { RiskTabsLayout } from "../../../components/risk-tabs-layout";
import {
  replaceHomePath,
  renderChainPageLayout,
  renderAssetPageLayout,
  DefaultChainPageTabRedirect,
  renderEModePageLayout,
} from "./utils";
import { ChainOverview, ListedAssets, ProposedAssets, EModeList } from "../tabs";
import { ProposedAsset, EModeDetails } from "../pages";
import { AssetsRecommendationsProvider, ListedAssetsProvider } from "../context";

const ChainsRoutes = () => {
  const { clientName } = useParams();
  const { isMultichain, chains } = useClientConfig();
  return (
    <AssetsRecommendationsProvider>
      <ListedAssetsProvider>
        <Routes>
          <Route
            index
            element={
              isMultichain ? (
                <RiskTabsLayout>
                  <Chains />
                </RiskTabsLayout>
              ) : (
                <Navigate
                  to={RoutePath.Risk.ChainDetailsListedAssets.replace(RouteParams.ClientName, clientName!).replace(
                    RouteParams.Chain,
                    chains[0],
                  )}
                  replace
                />
              )
            }
          />
          <Route path={replaceHomePath(RoutePath.Risk.ChainDetails)} element={<DefaultChainPageTabRedirect />} />
          <Route
            path={replaceHomePath(RoutePath.Risk.ChainDetailsOverview)}
            element={renderChainPageLayout(<ChainOverview />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.ChainDetailsListedAssets)}
            element={renderChainPageLayout(<ListedAssets />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.ChainDetailsProposedAssets)}
            element={renderChainPageLayout(<ProposedAssets />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.EModeDetails)}
            element={renderEModePageLayout(<EModeDetails />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.ChainDetailsEMode)}
            element={renderChainPageLayout(<EModeList />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.ChainAssetProposed)}
            element={renderAssetPageLayout(<ProposedAsset />)}
          />
        </Routes>
      </ListedAssetsProvider>
    </AssetsRecommendationsProvider>
  );
};
export default ChainsRoutes;
