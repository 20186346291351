import { Box, CustomReactSelect, ECharts, OptionType, Paper, Typography, Link, Button, CustomIcon } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { palette } from "@frontend/ui/theme/palette";
import { useMemo, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useCurrentAndRecommendedExperimentsObserverData } from "src/pages/parameter-recommendations-page/asset-page/use-experiment-observers-data";
import { getObserversPairScatterChartOptions } from "src/pages/parameter-recommendations-page/asset-page/components/public-simulations-section/utils/get-observers-pair-scatter-chart-options";
import { RouteParams, RoutePath } from "src/config/routes";
import { useAssetRecommendations } from "../../../../use-asset-recommendations";

type Props = {
  simulationResultId: string;
  currentStateExperimentId: string;
  recommendedStateExperimentId: string;
  observerDropdownOptions: OptionType[];
  isUnderReview?: boolean;
};

const ObservationsPairScatterChart = ({
  simulationResultId,
  currentStateExperimentId,
  recommendedStateExperimentId,
  observerDropdownOptions,
}: Props) => {
  const { isUnderReview } = useAssetRecommendations();

  const { clientName, marketId, asset } = useParams();

  const [firstSelectedObserverOption, setFirstSelectedObserverOption] = useState(observerDropdownOptions[0]);
  const [secondSelectedObserverOption, setSecondSelectedObserverOption] = useState(observerDropdownOptions[1]);

  const { isLoading: isLoadingFirstSelectedObserver, data: firstSelectedObserverData } =
    useCurrentAndRecommendedExperimentsObserverData(
      firstSelectedObserverOption.value,
      simulationResultId,
      currentStateExperimentId,
      recommendedStateExperimentId,
    );

  const { isLoading: isLoadingSecondSelectedObserver, data: secondSelectedObserverData } =
    useCurrentAndRecommendedExperimentsObserverData(
      secondSelectedObserverOption.value,
      simulationResultId,
      currentStateExperimentId,
      recommendedStateExperimentId,
    );

  const isLoading = isLoadingFirstSelectedObserver || isLoadingSecondSelectedObserver;
  const subtitle = `Showing observed values of 
    ${firstSelectedObserverOption.label} as a function of 
    ${secondSelectedObserverOption.label}, 
    each point on the chart is a single blockchain simulation.`;

  const maxYAxisValue = useMemo(() => {
    const yObserverCurrentValues = firstSelectedObserverData?.currentObserverData?.observerValues?.values || [];
    const yObserverRecommendedValues = firstSelectedObserverData?.recommendedObserverData?.observerValues?.values || [];
    return [...yObserverCurrentValues, ...yObserverRecommendedValues].reduce((max, n) => (n > max ? n : max), 0);
  }, [firstSelectedObserverData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography fontSize={24} fontWeight={700}>
            Metric Correlation
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <CustomReactSelect
            placeholder="Observer (y)"
            value={firstSelectedObserverOption}
            options={observerDropdownOptions.filter((option) => option.value !== secondSelectedObserverOption.value)}
            onChange={(selected) => {
              if (!selected) return;
              setFirstSelectedObserverOption(selected);
            }}
          />
          <CustomReactSelect
            placeholder="Observer (x)"
            value={secondSelectedObserverOption}
            options={observerDropdownOptions.filter((option) => option.value !== firstSelectedObserverOption.value)}
            onChange={(selected) => {
              if (!selected) return;
              setSecondSelectedObserverOption(selected);
            }}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2}>
        {["current", "recommended"].map((type, idx) => {
          const isCurrentValues = idx === 0;
          const badgeColor = isCurrentValues ? palette.orange.opacity50 : palette.aqua.opacity50;
          const isCurrent = type === "current";
          const dataKey = isCurrent ? "currentObserverData" : "recommendedObserverData";

          return (
            <Box display="flex" flexDirection="column" gap={3} width="100%">
              <Paper
                variant="widget"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  flex: 1,
                }}
                key={type}
              >
                <Box>
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="h3">{`${firstSelectedObserverOption.label} x ${secondSelectedObserverOption.label}`}</Typography>
                    <Box px={1} bgcolor={badgeColor} borderRadius={1}>
                      <Typography variant="body1">{isCurrentValues ? "Current" : "Recommended"}</Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1">{subtitle}</Typography>
                </Box>
                <Box display="flex">
                  <Box height={380} width={0} flex={1}>
                    {isLoading && <ShimmerLoader />}
                    {!isLoading && firstSelectedObserverData && secondSelectedObserverData && (
                      <ECharts
                        option={getObserversPairScatterChartOptions(
                          firstSelectedObserverData[dataKey],
                          secondSelectedObserverData[dataKey],
                          isCurrent ? palette.orange.main : palette.aqua.main,
                          maxYAxisValue,
                        )}
                        zoomable
                      />
                    )}
                  </Box>
                </Box>
              </Paper>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{ bgcolor: badgeColor, ":hover": { bgcolor: badgeColor, opacity: 0.9 } }}
                >
                  <Link
                    component={RouterLink}
                    to={RoutePath.Risk[isCurrent ? "ExperimentResultsCurrent" : "ExperimentResultsRecommended"](
                      isUnderReview,
                    )
                      .replace(RouteParams.ClientName, clientName!)
                      .replace(RouteParams.MarketId, marketId!)
                      .replace(RouteParams.Asset, asset!)}
                    underline="none"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    color="inherit"
                  >
                    <Typography>{`View ${isCurrentValues ? "Current" : "Recommended"} Results`}</Typography>
                    <CustomIcon icon="external-link" />
                  </Link>
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ObservationsPairScatterChart;
