import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { RoutePath } from "../../../config/routes";
import { RouteParams } from "../../../config/route-params";
import { CommunityDashboardLayout } from "../../../components/layouts";

type Props = {
  children: ReactNode;
};

const StakingTabsLayout = ({ children }: Props) => {
  const { clientName } = useParams();
  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);
  return (
    <CommunityDashboardLayout
      showClientToolsDropdown={false}
      tabs={[
        {
          label: "Overview",
          path: replaceParams(RoutePath.Risk.Overview),
        },
        {
          label: "Withdrawals",
          path: replaceParams(RoutePath.Risk.Withdrawals),
        },
      ]}
    >
      {children}
    </CommunityDashboardLayout>
  );
};

export const renderTabsLayout = (children: ReactNode) => <StakingTabsLayout>{children}</StakingTabsLayout>;
