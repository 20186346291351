import { useCallback, useEffect, useState } from "react";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { usePublicSimulationsData } from "src/pages/parameter-recommendations-page/hooks/use-public-simulations-data";
import { RepetitionResult } from "src/pages/parameter-recommendations-page/public-simulations-types";
import { ParameterModelResult } from "src/pages/ccar-lending-page/generated";
import { ExperimentType } from "../types";
import { useAssetRecommendations } from "../../use-asset-recommendations";

type Props = {
  paramModelResult: ParameterModelResult;
  experimentType: ExperimentType;
};

const PAGE_SIZE = 10;

const RepetitionsTable = ({ paramModelResult, experimentType }: Props) => {
  const { isUnderReview } = useAssetRecommendations();

  const [query, setQuery] = useState({
    simulationId: paramModelResult.modelRunId,
    experimentId: paramModelResult[experimentType]!.id,
  });

  const { isLoading: isLoadingRepetitions, response: repetitionsResponse } = usePublicSimulationsData({
    endpoint: "experiment/repetitions",
    query,
  });
  const [repetitionsData, setRepetitionsData] = useState<RepetitionResult[] | undefined>();

  const [lastBatchResult, setLastBatchResult] = useState<string | undefined>();

  useEffect(() => {
    if (repetitionsResponse) {
      setRepetitionsData((prevData) => [...(prevData || []), ...(repetitionsResponse.permutations || [])]);
      setLastBatchResult(repetitionsResponse.lastBatchResult);
    }
  }, [repetitionsResponse]);

  const { clientName, marketId, asset } = useParams();

  const tableHeaders: Header[] = [
    {
      renderType: "TEXT",
      text: "Simulation #",
      nonSortable: true,
    },
    ...(repetitionsData?.[0]?.observations || [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(
        ({ name }) =>
          ({
            renderType: "TEXT",
            text: name,
            nonSortable: true,
          }) as Header,
      ),
  ];

  const tableData: RenderData[][] = (repetitionsData || []).map(({ observations }, index) => {
    const rowData: RenderData[] = [
      {
        renderType: "CHIP",
        text: (index + 1).toString(),
      },
      ...observations
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ value, valueType, currencyCode }) => {
          const currency = valueType === "currency" ? currencyCode || "USD" : undefined;
          return {
            renderType: "TEXT",
            text: formatAmount(value, { notation: "compact", maximumFractionDigits: 2, currency }),
            tooltipText: formatAmount(value, { notation: "standard", currency }),
            value,
          } as unknown as RenderData;
        }),
    ];
    return rowData;
  });

  const handleOnClickRow = (rowId: number) => {
    const repetitionId = repetitionsData?.[rowId].id;

    if (!clientName || !repetitionId) return "";

    const simulationResultsPathKey =
      experimentType === "current" ? "SimulationResultsCurrent" : "SimulationResultsRecommended";

    const simulationResultsPageRoute = RoutePath.Risk[simulationResultsPathKey](isUnderReview)
      .replace(RouteParams.ClientName, clientName)
      .replace(RouteParams.MarketId, marketId || "")
      .replace(RouteParams.Asset, asset || "")
      .replace(RouteParams.SimulationResultId, repetitionId);

    return simulationResultsPageRoute;
  };

  const onPageChange = useCallback(
    (pageNumber: number) => {
      if (PAGE_SIZE * pageNumber === repetitionsData?.length && !isLoadingRepetitions) {
        setQuery((prevQuery) => ({ ...prevQuery, lastBatchResult }));
      }
    },
    [lastBatchResult, repetitionsData?.length, isLoadingRepetitions],
  );

  return (
    <ChaosTable
      title="Simulations"
      description="Results of blockchain simulations with different market conditions"
      headers={tableHeaders}
      rowHref={handleOnClickRow}
      onPageChange={onPageChange}
      isLoading={isLoadingRepetitions}
      data={tableData}
      isFullHeight
      pageSize={PAGE_SIZE}
      isSettingsHidden
      isFilterHidden
      showRowChevron
    />
  );
};

export default RepetitionsTable;
