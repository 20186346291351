import { FC, useMemo } from "react";
import { Header } from "@frontend/types";
import { Box } from "../box";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";
import { IconButton } from "../icon-button";
import { CustomIcon } from "../custom-icon";
import type { Filter } from "./table-filter";
import { formatDate } from "../utils/formatters";

interface TableAppliedFilterProps {
  filters: Record<number, Filter>;
  headers: Header[];
  removeFilter: (index: number) => void;
}

const rangeFilterValue = (value: string, header: Header) => `${header.prefix || ""}${value}${header.suffix || ""}`;

const formatNumber = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: value >= 10 ? 2 : 4,
  }).format(value);

const getFilterValue = (filter: Filter, header: Header) => {
  switch (filter.type) {
    case "options":
      return filter.options
        .filter(({ isHidden }) => !isHidden)
        .map(({ name }) => name)
        .join(", ");
    case "range":
      return `${rangeFilterValue(
        filter.isDate ? formatDate(new Date(filter.range[0])) : formatNumber(filter.range[0]),
        header,
      )}-${rangeFilterValue(
        filter.isDate ? formatDate(new Date(filter.range[1])) : formatNumber(filter.range[1]),
        header,
      )}`;
    case "text":
      return filter.value;
    default:
      return undefined;
  }
};

export const TableAppliedFilters: FC<TableAppliedFilterProps> = ({ filters, headers, removeFilter }) => {
  const filterValues = useMemo(
    () =>
      Object.entries(filters)
        .filter(([, filter]) => filter.isApplied)
        .map(([index, filter]) => ({
          index,
          header: headers[Number(index)].text,
          value: getFilterValue(filter, headers[Number(index)]),
        })),
    [filters, headers],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        mr: filterValues.length ? 2 : 0,
      }}
    >
      {filterValues.map(({ index, header, value }) => (
        <Box
          key={index}
          display="flex"
          borderRadius={4}
          px={1.5}
          bgcolor="rgba(255, 255, 255, 0.1)"
          alignItems="center"
          whiteSpace="nowrap"
          data-testid={`applied-filter-${index}`}
        >
          <Box mr={1}>
            <Typography variant="body2">{header}</Typography>
          </Box>
          {value ? (
            <Tooltip title={value}>
              <Box
                bgcolor="#22252C"
                borderRadius={4}
                px={1}
                mr={1}
                height={24}
                minWidth={24}
                display="flex"
                alignItems="center"
                justifyContent="center"
                maxWidth="250px"
              >
                <Typography variant="body2" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {value}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <Typography variant="body2">-</Typography>
          )}
          <IconButton sx={{ svg: { width: 8, height: 8 }, p: 0 }} onClick={() => removeFilter(Number(index))}>
            <CustomIcon icon="close" />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};
