import dayjs from "dayjs";
import { Paper, ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { ChainHistoryValue } from "../../generated";
import { useXAxisSplit, xAxisDateFormatter, XTimeSpan } from "../../chart-utils";

type Props = {
  data?: ChainHistoryValue[];
  isLoading: boolean;
  title: string;
  description?: string;
  isCurrency: boolean;
  yAxisLabel: string;
  suffix?: JSX.Element;
  useDefaultEchartsTimeFormat?: boolean;
  timeSpan: XTimeSpan;
  isBarChart?: boolean;
  showMonthTooltip?: boolean;
};

const ChainsHistoryChart = ({
  data,
  isLoading,
  title,
  description,
  isCurrency,
  yAxisLabel,
  suffix,
  timeSpan,
  isBarChart,
  showMonthTooltip,
}: Props) => {
  const xAxisSplit = useXAxisSplit();

  const chartData = (data || []).reduce((acc: Record<string, NumberChartSeries>, d: ChainHistoryValue) => {
    const prevSeries = acc[d.chain];
    const prevData = prevSeries?.data || [];
    const newData = [...prevData, [d.timestamp, d.value]] as [number, number][];
    const newSeries: NumberChartSeries = {
      label: d.chain,
      data: newData,
      type: isBarChart ? "bar" : "line",
    };
    return { ...acc, [d.chain]: newSeries };
  }, {});

  return (
    <Paper variant="widget">
      <ValueOverTimeChart
        title={title}
        description={description}
        series={Object.values(chartData)}
        isLoading={isLoading}
        currency={isCurrency ? "USD" : ""}
        xAxisLabel="Date"
        yAxisLabel={yAxisLabel}
        zoomable
        suffix={suffix}
        dateFormatter={(timestamp: number) => xAxisDateFormatter(timestamp, timeSpan)}
        tooltipHeaderFormatter={(timestamp: number | string) =>
          showMonthTooltip ? dayjs(Number(timestamp)).format("MMMM") : undefined
        }
        xAxisSplitNumber={xAxisSplit}
      />
    </Paper>
  );
};

export default ChainsHistoryChart;
