import { Header, RenderData } from "@frontend/types";
import { Box, ChaosTable, EmptyStateProps } from "@frontend/ui";
import { Alert, AlertFilters } from "@frontend/ui/alert-filters";
import { formatDateAndTime } from "@frontend/ui/utils/formatters";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { RouteParams } from "src/config/route-params";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import {
  useRiskAlertsQuery,
  useMarketAssetsBreakdownQuery,
  AlertSeverity,
  Chain,
  AlertsQuery,
  SortOrder,
} from "../../generated";
import { useClientConfig } from "../../clients-config";
import { NotificationChannelLinks, TitleCell } from "./components";
import { alertSeverityLabels, alertSeverityStatus, alertTypeLabels } from "./constants";
import { AlertDrawer } from "./components/alert-drawer";
import { getChainDisplayName } from "./utils";

export enum AlertType {
  BadDebt = "BAD_DEBT",
  Borrow = "BORROW",
  BorrowCaps = "BORROW_CAPS",
  ConfigChange = "CONFIG_CHANGE",
  DebtCeiling = "DEBT_CEILING",
  FundingRate = "FUNDING_RATE",
  HealthCheck = "HEALTH_CHECK",
  Leverage = "LEVERAGE",
  Liquidations = "LIQUIDATIONS",
  Liquidity = "LIQUIDITY",
  PositionSize = "POSITION_SIZE",
  PriceChange = "PRICE_CHANGE",
  StablePeg = "STABLE_PEG",
  Supply = "SUPPLY",
  SupplyCaps = "SUPPLY_CAPS",
  Utilizations = "UTILIZATIONS",
  ValueAtRisk = "VALUE_AT_RISK",
  Volume = "VOLUME",
  Whale = "WHALE",
  CirculatingSupply = "CIRCULATING_SUPPLY",
  TotalValueLocked = "TVL",
  ProfitLoss = "PROFIT_LOSS",
}

const alertsHeaders: Header[] = [
  {
    renderType: "TEXT",
    text: "Risk Type",
    width: "150px",
  },
  {
    renderType: "TEXT",
    text: "Severity",
    width: "90px",
  },
  {
    renderType: "TEXT",
    text: "Name",
  },
  {
    renderType: "TEXT",
    text: "Asset",
    width: "90px",
  },
  {
    renderType: "TEXT",
    text: "Chain",
  },
  {
    renderType: "TEXT",
    text: "Date",
  },
];

const mapAlerts = (alert: Alert): RenderData[] => {
  const formattedCreatedDate = `${formatDateAndTime(dayjs.unix(alert.createdDate).toDate())}`;
  const severityStatus = alertSeverityStatus[alert.severity as AlertSeverity];
  const severityLabel = alertSeverityLabels[alert.severity as AlertSeverity];

  return [
    {
      renderType: "TEXT",
      text: alert.type ? alertTypeLabels[alert.type] : "",
    },
    {
      text: severityLabel,
      renderType: "LABELS",
      status: severityStatus,
    },
    {
      renderType: "CUSTOM",
      text: alert.title,
      component: <TitleCell title={alert.title} isOpen={alert.open} />,
    },
    {
      renderType: "TEXT",
      cryptoIcon: alert.assets ? wrappedSymbolToIconSymbol(alert.assets[0]) : "",
      text: alert.assets ? alert.assets[0] : "",
    },
    {
      renderType: "TEXT",
      text: alert.chain ? getChainDisplayName(alert.chain as Chain) : "",
      cryptoIcon: alert.chain?.toLowerCase(),
    },
    {
      renderType: "TEXT",
      text: formattedCreatedDate,
      value: alert.createdDate,
    },
  ];
};

function mapDedupeOptions<T>(items: (T | null)[] = []) {
  return items.reduce((curr, next) => {
    if (next && !curr?.includes(next)) {
      curr.push(next);
    }
    return curr;
  }, [] as T[]);
}

type AlertsTableProps = {
  data: Alert[];
  loading: boolean;
  emptyState: EmptyStateProps;
};

const AlertsTable = ({ data, loading, emptyState }: AlertsTableProps) => {
  const config = useClientConfig();

  return (
    <ChaosTable
      pageSize={10}
      isFullHeight
      isLoading={loading}
      isSettingsHidden
      title="Alerts"
      headers={alertsHeaders}
      data={data.map(mapAlerts)}
      resetPagination={data.map((d) => d.id).join("-")}
      headerSuffixComponent={
        config.alerts?.notificationsChannels &&
        config.alerts.notificationsChannels.length && (
          <NotificationChannelLinks notificationsChannels={config.alerts.notificationsChannels} />
        )
      }
      isFilterHidden
      emptyState={emptyState}
      rowHref={(rowId) => {
        const row = data[rowId];
        if (!row) {
          return "";
        }
        const { id } = row;
        if (!id) {
          return "";
        }
        return `${String(id)}`;
      }}
    />
  );
};

const AlertsTab = () => {
  const [query] = useState<AlertsQuery>({
    limit: 100,
    filter: null,
    order: SortOrder.Descending,
    skip: 0,
    sort: null,
  });
  const { data, loading } = useRiskAlertsQuery({ variables: { query } });
  const { data: marketAssets } = useMarketAssetsBreakdownQuery();
  const chains = marketAssets?.assetsBreakdown.map((a) => a.chain);
  const assets = marketAssets?.assetsBreakdown.map((a) => a.asset);
  const alertAssets = mapDedupeOptions(assets).sort((a, b) => (a > b ? 1 : -1));
  const alertChains = mapDedupeOptions(chains).sort((a, b) => (a > b ? 1 : -1));
  const alertTypes = Object.keys(AlertType) as (keyof typeof AlertType)[];
  const [tableData, setTableData] = useState<Alert[]>([]);
  const emptyState = {
    icon: "warning",
    title: "No Alerts Found",
  };

  const applyFilters = useCallback((alerts: Alert[]) => {
    setTableData(alerts);
  }, []);

  useEffect(() => {
    if (data?.riskAlerts) {
      applyFilters(data.riskAlerts as Alert[]);
    }
  }, [data?.riskAlerts, applyFilters]);

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={[2, 3]}>
      <AlertFilters
        onChange={applyFilters}
        assets={alertAssets}
        chains={alertChains}
        types={alertTypes.map((t) => AlertType[t])}
        alertSeverities={[alertSeverityLabels.HIGH, alertSeverityLabels.INFO]}
        alertTypeLabels={(s) => alertTypeLabels[s as AlertType]}
        alerts={(data?.riskAlerts as Alert[]) || []}
      />
      <AlertsTable data={tableData} loading={loading} emptyState={emptyState} />
      <Routes>
        <Route path={`/${RouteParams.Alert}`} element={<AlertDrawer />} />
      </Routes>
    </Box>
  );
};

export default AlertsTab;
