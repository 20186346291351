import { Grid, Paper } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { useState } from "react";
import { useEcosystemPerformanceData } from "./use-ecosystem-performance-data";
import ChainsHistoryChart from "../components/chains-history-chart";
import { TimeSpanPicker } from "../../../ccar-lending-page/components/time-span-picker";
import { daysAgoByTimeSpan, XTimeSpan } from "../../chart-utils";

const EcosystemPerformance = () => {
  const [timeSpan, setTimeSpan] = useState<XTimeSpan>(TimeSpan.Year);
  const data = useEcosystemPerformanceData(timeSpan ? daysAgoByTimeSpan[timeSpan] : null);

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", gap: 3 }} variant="card">
      <Grid item xs={12} display="flex" justifyContent="end">
        <TimeSpanPicker<XTimeSpan>
          timeSpanOptions={[TimeSpan.Month, TimeSpan.Year]}
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
        />
      </Grid>
      <ChainsHistoryChart
        title="Blockchain TVL Comparison Over Time"
        yAxisLabel="Total Value Locked in USD"
        data={data.chainsTvlHistory}
        isLoading={data.isLoadingChainsTvlHistory}
        isCurrency
        timeSpan={timeSpan}
      />
      <ChainsHistoryChart
        title="Number of Blockchain Users Over Time"
        yAxisLabel="Number of Users"
        data={data.chainsNumberOfUsersHistory}
        isLoading={data.isLoadingChainsNumberOfUsersHistory}
        isCurrency={false}
        timeSpan={timeSpan}
      />
      <ChainsHistoryChart
        title="DEX Trading Volumes Across Blockchains Over Time"
        yAxisLabel="DEX Volume in USD"
        data={data.chainsDexTradingVolumeHistory}
        isLoading={data.isLoadingChainsDexTradingVolumeHistory}
        isCurrency
        timeSpan={timeSpan}
      />
    </Paper>
  );
};

export default EcosystemPerformance;
