import { Box, OptionType, Typography, HistogramChart } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { ObserverData } from "src/pages/parameter-recommendations-page/asset-page/use-experiment-observers-data";

type Props = {
  isLoading: boolean;
  selectedObserverOption?: OptionType;
  selectedObserverData?: ObserverData;
  isZerosFiltered?: boolean;
};

const MetricDistribution = ({ isLoading, selectedObserverData, selectedObserverOption, isZerosFiltered }: Props) => (
  <Box display="flex" flexDirection="column" gap={3} height="100%">
    <Box>
      {selectedObserverOption && <Typography variant="h3">{selectedObserverOption.label}</Typography>}
      {!isLoading && selectedObserverData?.description && (
        <Typography variant="body1">{selectedObserverData.description}</Typography>
      )}
    </Box>
    <Box flex={1}>
      {isLoading && <ShimmerLoader />}
      {!isLoading && selectedObserverData && (
        <HistogramChart
          emptyState={!selectedObserverData.observerValues.histogramDistributionData.length}
          name={selectedObserverData.name}
          bins={selectedObserverData.observerValues.histogramDistributionData}
          totalSamples={selectedObserverData.observerValues.numberOfRepetitions}
          xAxisOptions={{
            isCurrency: selectedObserverData.valueType === "currency",
            currencyCode: selectedObserverData.currencyCode,
          }}
          isZerosFiltered={isZerosFiltered}
          yAxisOptions={{
            label: "Number of Simulations",
          }}
        />
      )}
    </Box>
  </Box>
);
export default MetricDistribution;
