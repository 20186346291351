import { FC, memo, useMemo } from "react";
import { ScriptResource, ObserverGroupMetadata } from "@frontend/types";
import { Grid } from "@frontend/ui";
import { ObservationResult } from "src/pages/parameter-recommendations-page/public-simulations-types";
import { ObserverWidget, ObserverWidgetProps, GroupChildObserver } from "./observer-widget";

export interface ObserversViewProps {
  data: ObservationResult[];
  observers?: Partial<ScriptResource>[];
  observerGroupsMetadata?: ObserverGroupMetadata[];
  startBlock?: number;
  iterationsRange: [number, number];
}

export const ObserversView: FC<ObserversViewProps> = memo(
  ({ data, observers, observerGroupsMetadata, startBlock, iterationsRange }) => {
    const observersWidgetsData: ObserverWidgetProps[] = useMemo(
      () =>
        data.map(({ id, name, result }) => {
          const observerConfig = observers?.find((observer) => observer.id === id || observer.refId === id);

          return {
            id,
            instanceId: observerConfig?.instanceId,
            title: name,
            description: observerConfig?.description,
            observation: result,
            observerConfig,
            displayOrder: observerConfig?.displayOrder,
            startBlock,
            iterationsRange,
          };
        }),
      [data, observers, startBlock, iterationsRange],
    );

    const observersGroups: ObserverWidgetProps[] = useMemo(
      () =>
        (observerGroupsMetadata || []).reduce((acc: ObserverWidgetProps[], group: ObserverGroupMetadata) => {
          const observersInGroup = observersWidgetsData.filter(
            ({ id, instanceId }) =>
              (id && group?.observerIds?.includes(id)) ||
              (instanceId && group?.observerInstanceIds?.includes(instanceId)),
          );

          if (observersInGroup.length <= 1) return acc;

          const groupChildObservers: GroupChildObserver[] = observersInGroup.reduce(
            (observersObjs: GroupChildObserver[], { id, instanceId, title }: ObserverWidgetProps) => {
              if (!id || !title) return observersObjs;
              const newChildObserver: GroupChildObserver = {
                id,
                title,
                secondaryAxis: !!(
                  group.secondaryAxisObserverIds?.includes(id) ||
                  (instanceId && group.secondaryAxisInstanceIds?.includes(instanceId))
                ),
              };
              return [...observersObjs, newChildObserver];
            },
            [],
          );

          const groupProps: ObserverWidgetProps = {
            title: group.groupName,
            observation: observersInGroup.map((obs) => obs.observation as number[]),
            groupChildObservers,
            observerConfig: {
              dataType: group.dataType,
              chartType: group.chartType,
              valueType: group.valueType,
              valueLabel: group.valueLabel,
              currencyCode: group.currencyCode,
              isVisible: group.isVisible,
            },
            startBlock,
            iterationsRange,
            legends: group.legends,
            displayOrder: group.displayOrder,
          };

          return [...acc, groupProps];
        }, [] as ObserverWidgetProps[]),
      [observerGroupsMetadata, observersWidgetsData, startBlock, iterationsRange],
    );

    const observersIdsInGroups = useMemo(
      () =>
        (observerGroupsMetadata || [])
          .map(({ observerIds, observerInstanceIds }) => [...(observerIds || []), ...(observerInstanceIds || [])])
          .flat(),
      [observerGroupsMetadata],
    );

    const filteredObserversWidgetsData = useMemo(
      () =>
        observersWidgetsData.filter(
          ({ id, instanceId }) =>
            !((id && observersIdsInGroups.includes(id)) || (instanceId && observersIdsInGroups.includes(instanceId))),
        ),
      [observersWidgetsData, observersIdsInGroups],
    );

    const renderData = useMemo(
      () =>
        observersGroups
          .concat(
            filteredObserversWidgetsData.sort((obsA, obsB) => {
              if (obsA.observerConfig?.isMetric) return -1;
              return (obsA.displayOrder || 0) - (obsB.displayOrder || 0);
            }),
          )
          .filter(({ observerConfig }) => observerConfig?.isVisible !== false),
      [filteredObserversWidgetsData, observersGroups],
    );

    return (
      <Grid container spacing={2} flex={1}>
        {renderData.map((props: ObserverWidgetProps) => {
          const isGroup = typeof props?.observation[0] === "object";
          return (
            <Grid item key={props.id || props.title} sm={12} md={isGroup || renderData.length === 1 ? 12 : 6}>
              <ObserverWidget {...props} />
            </Grid>
          );
        })}
      </Grid>
    );
  },
);
