import dayjs from "dayjs";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { ChainCategoryHistoryValue } from "../../generated";
import { useXAxisSplit, xAxisDateFormatter, XTimeSpan } from "../../chart-utils";

type Props = {
  data?: ChainCategoryHistoryValue[];
  isLoading: boolean;
  title: string;
  yAxisLabel: string;
  isCurrency?: boolean;
  timeSpan: XTimeSpan;
  showMonthTooltip?: boolean;
  formatSeriesLabel?: (label: string) => string;
};

const ChainCategoryHistoryChart = ({
  data,
  isLoading,
  title,
  yAxisLabel,
  isCurrency,
  timeSpan,
  showMonthTooltip,
  formatSeriesLabel,
}: Props) => {
  const xAxisSplit = useXAxisSplit();

  const chartData = (data || []).reduce((acc: Record<string, NumberChartSeries>, d: ChainCategoryHistoryValue) => {
    const { categories } = d;

    categories.forEach((c) => {
      const prevSeries = acc[c.category];
      const prevData = prevSeries?.data || [];
      const newData = [...prevData, [d.timestamp, c.value]] as [number, number][];
      const newSeries: NumberChartSeries = {
        label: formatSeriesLabel?.(c.category) || c.category,
        data: newData,
        type: "bar",
      };
      acc[c.category] = newSeries;
    });

    return acc;
  }, {});
  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        chartHeight={325}
        title={title}
        series={Object.values(chartData)}
        yAxisLabel={yAxisLabel}
        currency={isCurrency ? "USD" : ""}
        isLoading={isLoading}
        dateFormatter={(timestamp: number) => xAxisDateFormatter(timestamp, timeSpan)}
        tooltipHeaderFormatter={(timestamp: number | string) =>
          showMonthTooltip ? dayjs(Number(timestamp)).format("MMMM") : undefined
        }
        xAxisSplitNumber={xAxisSplit}
      />
    </Paper>
  );
};

export default ChainCategoryHistoryChart;
