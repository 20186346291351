import { useMemo } from "react";
import { AssetConfig, ProposedAssetData, useAssetConfigQuery, useProposedAssetDataQuery } from "../../generated";

type ReturnType = {
  isLoading: boolean;
  assetConfig?: AssetConfig;
  assetData?: ProposedAssetData;
};

export const useAssetPageData = (assetSymbol: string, chain?: string): ReturnType => {
  const queryVariables = useMemo(
    () => ({
      query: {
        assetSymbol,
        chain: chain || null,
      },
    }),
    [assetSymbol, chain],
  );

  const { loading: isLoadingAssetConfig, data: assetConfigData } = useAssetConfigQuery({
    variables: queryVariables,
  });
  const { loading: isLoadingProposedAsset, data: proposedAssetData } = useProposedAssetDataQuery({
    variables: queryVariables,
  });

  return {
    isLoading: isLoadingAssetConfig || isLoadingProposedAsset,
    assetConfig: assetConfigData?.assetConfig || undefined,
    assetData: proposedAssetData?.proposedAssetData || undefined,
  };
};
