import { Filter, makePickerFilter, makeRangeFilter } from "@frontend/ui";
import { PositionSide, SortPositionsBy, Range } from "../../generated";

type PositionFilterProps = {
  assets: { name: string; token: string; value: string }[];
};

export const getPositionsFilters = ({ assets }: PositionFilterProps): Filter[] =>
  [
    makePickerFilter({
      title: "Asset",
      fieldName: SortPositionsBy.AssetSymbol,
      options: assets.map((asset) => ({
        name: asset.name,
        cryptoIcon: asset.token.toLowerCase(),
        value: asset.value,
      })),
    }),
    makePickerFilter({
      title: "Type",
      fieldName: SortPositionsBy.Side,
      options: [
        {
          name: "Long",
          customIcon: "line-up",
          value: PositionSide.Long,
        },
        {
          name: "Short",
          customIcon: "line-down",
          value: PositionSide.Short,
        },
      ],
    }),
    makeRangeFilter({
      title: "Size",
      fieldName: SortPositionsBy.BalanceUsd,
      range: [0, 50_000_000],
      min: 0,
      max: 50_000_000,
    }),
    makeRangeFilter({
      title: "Leverage",
      fieldName: SortPositionsBy.Leverage,
      range: [0, 200],
      min: 0,
      max: 200,
    }),
    makeRangeFilter({
      title: "PnL",
      fieldName: SortPositionsBy.Pnl,
      range: [-20_000_000, 20_000_000],
      min: -20_000_000,
      max: 20_000_000,
    }),
  ].map((filter, i) => ({ ...filter, fieldIndex: i }));

export const getOptionsFilterValue = (filters: Filter[], key: SortPositionsBy) => {
  const filter = filters.find((f) => f.fieldName === key);

  if (filter) {
    const values: string[] = [];
    if (filter.type === "options") {
      filter.options.forEach((option) => {
        if (!option.isHidden && option.value) {
          values.push(option.value);
        }
      });
    }
    return values;
  }

  return null;
};

export const getSideFilterValue = (filters: Filter[]) => {
  const value = getOptionsFilterValue(filters, SortPositionsBy.Side);
  return value?.length === 1 ? (value[0] as PositionSide) : null;
};

export const getRangeFilterValue = (filters: Filter[], key: SortPositionsBy) => {
  const filter = filters.find((f) => f.fieldName === key);

  if (filter) {
    const range: Range = { start: null, end: null };
    if (filter.type === "range") {
      const [start, end] = filter.range;
      range.start = start;
      range.end = end;
    }
    return range;
  }

  return null;
};
