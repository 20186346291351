import { Box, Paper, ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { useFeedData } from "../../use-price-feed-details";
import { Endpoints } from "../../../../api";
import {
  PriceFeedTimeSpan,
  priceFeedTimeSpanOptions,
  useProvidersSelectContext,
} from "../../../../context/providers-select-context";
import { TimeSpanPicker } from "../../../../../ccar-lending-page/components/time-span-picker";

const VolumesHistoryChart = () => {
  const { providerNameById } = useProvidersSelectContext();
  const { response: exchangesVolumes, isLoading, timeSpan, setTimeSpan } = useFeedData(Endpoints.PriceFeedVolumes);

  const series = (exchangesVolumes ? [...exchangesVolumes] : [])
    .sort((a, b) => (a.origin < b.origin ? 1 : -1))
    .reduce<Record<string, NumberChartSeries>>((acc, point) => {
      const currentSeriesData = acc[point.origin]?.data || [];
      const newSeries: NumberChartSeries = {
        id: point.origin,
        label: providerNameById?.[point.id] || capitalizeFirstLetter(point.origin),
        data: [...currentSeriesData, [point.timestamp * 1000, point.volume_usd]] as [number, number][],
        type: "line",
      };
      return { ...acc, [point.origin]: newSeries };
    }, {});

  return (
    <Paper variant="card">
      <Paper variant="widget" sx={{ flex: 1, display: "flex" }}>
        <Box flex={1} width={0}>
          <ValueOverTimeChart
            key={`volumes-chart-${Object.keys(series).join("-")}`}
            title="Trading Volume by Exchange"
            description="Trading volumes in USD across various exchanges"
            series={Object.values(series)}
            currency="USD"
            isLoading={isLoading}
            yAxisMax
            yAxisMin
            zoomable
            timeSpan={timeSpan}
            suffix={
              <TimeSpanPicker<PriceFeedTimeSpan>
                selectedTimeSpan={timeSpan}
                onSelectTimeSpan={setTimeSpan}
                timeSpanOptions={priceFeedTimeSpanOptions}
                hideLabel
              />
            }
          />
        </Box>
      </Paper>
    </Paper>
  );
};

export default VolumesHistoryChart;
