import { useState } from "react";
import { ModalWrapper } from "@frontend/ui/modals";
import { Box, Button, CustomIcon, List, ListItem, Typography } from "@frontend/ui";
import LatencyTable from "./latency-table";
import { Endpoints } from "../../../../api";
import { useFeedData } from "../../use-price-feed-details";
import { TimeSpanPicker } from "../../../../../ccar-lending-page/components/time-span-picker";
import { PriceFeedTimeSpan, priceFeedTimeSpanOptions } from "../../../../context/providers-select-context";

const LatencyAnalysisSection = () => {
  const [learMoreOpen, setLearnMoreOpen] = useState(false);
  const { response, isLoading, timeSpan, setTimeSpan } = useFeedData(Endpoints.PriceFeedLatencyStatistics);

  return (
    <>
      <Box mt={3} display="flex" justifyContent="space-between">
        <Box>
          <Box display="flex" gap={1} alignItems="center">
            <CustomIcon icon="latency" />
            <Typography variant="h3">Provider Latency Analysis</Typography>
          </Box>
          <Box>
            <Box display="flex" gap={1}>
              <Typography variant="h6">
                Evaluation of provider response times against benchmark oracle{" "}
                <Button
                  onClick={() => setLearnMoreOpen(true)}
                  variant="text"
                  size="small"
                  disableRipple
                  disableFocusRipple
                  sx={{
                    display: "inline-block",
                    height: "24px",
                    p: 0,
                    verticalAlign: "baseline",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                >
                  <Typography variant="h5" color="blue.main">
                    Learn More
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </Box>
        </Box>
        <TimeSpanPicker<PriceFeedTimeSpan>
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
          timeSpanOptions={priceFeedTimeSpanOptions}
          hideLabel
        />
      </Box>
      <LatencyTable latencyStatisticsResponse={response} isLoading={isLoading} />
      <ModalWrapper
        open={learMoreOpen}
        onClose={() => setLearnMoreOpen(false)}
        title="Understanding Provider Latency Analysis"
        maxWidth={1000}
      >
        <Typography variant="h6">
          We use cross-correlation over a rolling one-hour window to estimate the temporal shift between two providers.
          Given a reliable signal, the highest correlation peak points to the most probable shift. The process involves:
        </Typography>
        <List sx={{ listStyle: "decimal", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="h6">
              <Typography variant="h5" sx={{ display: "inline-block" }}>
                Rolling Window:
              </Typography>{" "}
              The data is segmented into overlapping intervals of one hour each.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="h6">
              <Typography variant="h5" sx={{ display: "inline-block" }}>
                Cross-Correlation Analysis:
              </Typography>{" "}
              Within each one-hour window, we perform a cross-correlation analysis between the time series from the two
              providers.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="h6">
              <Typography variant="h5" sx={{ display: "inline-block" }}>
                Determining Temporal Shift:
              </Typography>{" "}
              We compute the cross-correlation for various time shifts (lags) for each window and identify the lag where
              the cross-correlation peaks. This peak indicates the most probable temporal displacement between the two
              series in that interval.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="h6">
              <Typography variant="h5" sx={{ display: "inline-block" }}>
                Lag Estimation:
              </Typography>{" "}
              After analyzing the peak lags across all windows, we assess their distribution to determine the estimated
              temporal shift between the data series.
            </Typography>
          </ListItem>
        </List>
      </ModalWrapper>
    </>
  );
};

export default LatencyAnalysisSection;
