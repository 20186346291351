import { camelCase, startCase } from "lodash";
import { UserEngagementData } from "../use-user-engagement-data";
import ChainCategoryHistoryChart from "../../components/chain-category-history-chart";
import { TimeSpan } from "../../../generated";

type Props = {
  data: UserEngagementData;
};
const ActiveUsersByProtocol = ({ data }: Props) => (
  <ChainCategoryHistoryChart
    data={data?.chainActiveUsersByCategory}
    title="Monthly Active Users by Protocol Category"
    yAxisLabel="Number of Users"
    isLoading={data.isLoadingChainActiveUsersByCategory}
    timeSpan={TimeSpan.Year}
    formatSeriesLabel={(label: string) => startCase(camelCase(label))}
    showMonthTooltip
  />
);

export default ActiveUsersByProtocol;
