export type ProtocolGroupsOptions = {
  label: string;
  value: string;
  icon?: string;
  featureFlag?: string;
}[];

export type ProtocolGroupType = "lending" | "perp" | "chain-analytics" | "leverage" | "staking";

export type ProtocolGroupMetadata = {
  label: string;
  subLabel?: string;
  icon: string;
  type: ProtocolGroupType;
  category?: ProtocolGroupType;
  options?: ProtocolGroupsOptions;
};

type ProtocolGroups = Record<string, ProtocolGroupMetadata>;

export const groups: ProtocolGroups = {
  aave: {
    label: "Aave",
    icon: "aave",
    type: "lending",
    options: [
      { label: "V2 Mainnet", value: "aave-v2" },
      { label: "V3 Mainnet", value: "aave" },
    ],
  },
  benqi: {
    label: "Benqi",
    icon: "benqi",
    type: "lending",
  },
  gmx: {
    label: "GMX",
    icon: "gmx",
    type: "perp",
    options: [
      {
        label: "V1 Arbitrum",
        icon: "arbitrum",
        value: "gmx-arbitrum",
      },
      {
        label: "V1 Avalanche",
        icon: "avalanche",
        value: "gmx-avalanche",
      },
      {
        label: "V2 Arbitrum",
        icon: "arbitrum",
        value: "gmx-v2-arbitrum",
        featureFlag: "gmx_v2",
      },
      {
        label: "V2 Avalanche",
        icon: "avalanche",
        value: "gmx-v2-avalanche",
        featureFlag: "gmx_v2",
      },
    ],
  },
  "dydx-v4": {
    label: "dYdX",
    subLabel: "Chain",
    icon: "dydx",
    type: "perp",
  },
  "ostium-testnet": {
    label: "Ostium Testnet",
    icon: "ostium",
    type: "perp",
  },
  ostium: {
    label: "Ostium",
    icon: "ostium",
    type: "perp",
  },
  bluefin: {
    label: "Bluefin",
    icon: "bluefin",
    type: "perp",
  },
  radiant: {
    label: "Radiant",
    icon: "radiant",
    type: "lending",
  },
  venus: {
    label: "Venus",
    icon: "venus",
    type: "lending",
  },
  tapioca: {
    label: "Tapioca",
    icon: "tapioca",
    type: "lending",
  },
  "crv-usd": {
    label: "crvUSD",
    icon: "crvusd",
    type: "lending",
  },
  seamless: {
    label: "Seamless",
    icon: "seamless",
    type: "lending",
  },
  zerolend: {
    label: "ZeroLend",
    icon: "zerolend",
    type: "lending",
  },
  nftperp: {
    label: "NftPerp",
    icon: "nftperp",
    type: "perp",
  },
  jupiter: {
    label: "Jupiter",
    icon: "Jupiter",
    type: "perp",
  },
  synfutures: {
    label: "SynFutures",
    icon: "synfutures",
    type: "perp",
  },
  aptos: {
    label: "Aptos",
    icon: "aptos",
    type: "chain-analytics",
  },
  gearbox: {
    label: "Gearbox",
    icon: "gearbox",
    type: "leverage",
    category: "lending",
  },
  etherfi: {
    label: "Etherfi",
    icon: "etherfi",
    type: "staking",
  },
};
