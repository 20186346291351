import { BlockchainSimulation, SimulationPermutationInfo } from "@frontend/types";
import { Paper, Grid, Typography } from "@frontend/ui";
import { InfoItem } from "src/components/info-item";

type Props = {
  simulation: BlockchainSimulation;
  permutationInfo?: SimulationPermutationInfo;
};

const GeneralInfoCard = ({ simulation }: Props) => (
  <Paper
    variant="card"
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
    }}
  >
    <Typography variant="h2">General</Typography>
    <Grid container spacing={3}>
      {simulation.description && (
        <Grid item xs={12}>
          <InfoItem title="Description" value={simulation.description} />
        </Grid>
      )}
    </Grid>
  </Paper>
);

export default GeneralInfoCard;
