import {
  AssetConfig,
  useAssetsConfigQuery,
  useProposedAssetsDataQuery,
} from "src/pages/asset-listing-portal/generated";

type ProposedAssetsTableData = AssetConfig & {
  marketCap?: number;
  volume?: number;
};

type ReturnType = {
  isLoading: boolean;
  data: ProposedAssetsTableData[];
};

export const useProposedAssetsData = (chain?: string): ReturnType => {
  const { loading: isLoadingAssetsConfig, data: assetsConfigData } = useAssetsConfigQuery({
    variables: { chain: chain || null },
  });
  const { loading: isLoadingProposedAssetsData, data: proposedAssetsData } = useProposedAssetsDataQuery({
    variables: { chain: chain || null },
  });

  const data: ProposedAssetsTableData[] = (assetsConfigData?.assetsConfig || []).reduce(
    (tableData: ProposedAssetsTableData[], config: AssetConfig) => {
      const proposedAssetData = proposedAssetsData?.proposedAssetsData.find(
        (proposedAsset) => proposedAsset.assetSymbol === config.assetSymbol && proposedAsset.chain === config.chain,
      );

      if (!proposedAssetData) return tableData;

      const newDatum: ProposedAssetsTableData = {
        ...config,
        marketCap: proposedAssetData.marketCapUsd || undefined,
        volume: proposedAssetData.totalVolumeUsd || undefined,
      };

      return [...tableData, newDatum];
    },
    [],
  );

  return {
    isLoading: isLoadingAssetsConfig || isLoadingProposedAssetsData,
    data,
  };
};
