import { Box } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, addressCell, currencyCell, currencyTrendCell, numberCell } from "@frontend/ui/chaos-table";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { SortPositionsBy } from "../../generated";
import { useClientConfig } from "../../clients-config";
import { dateDiffCell, positionSideCell, positionSymbolCell } from "./cells";
import { usePositionsData } from "./use-postions-data";
import { PositionsFilter } from "./positions-filter";

export const Positions = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const {
    positions: { showClosedPositions, showPositionKeyColumn },
    positionDetails,
  } = useClientConfig();
  const pageSize = 10;
  const [showOpenPositions, setShowOpenPositions] = useState(true);
  const { positions, loading, filters, onFiltersChange, onPageChange, onSortChange, onSearch } = usePositionsData({
    open: showOpenPositions,
  });
  const tableHeaders: Header[] = [
    ...(showPositionKeyColumn
      ? ([
          {
            renderType: "TEXT",
            text: "Position Key",
            nonSortable: true,
            width: "15%",
          },
        ] as Header[])
      : []),
    {
      renderType: "TEXT",
      text: "Account",
      field: SortPositionsBy.Account,
      width: "15%",
    },
    {
      renderType: "ICONS",
      text: "Market",
      field: SortPositionsBy.AssetSymbol,
    },
    {
      renderType: "TEXT",
      text: "Type",
      nonSortable: true,
    },
    {
      renderType: "TEXT",
      text: "Size",
      field: showOpenPositions ? SortPositionsBy.BalanceUsd : SortPositionsBy.OriginalBalanceUsd,
    },
    {
      renderType: "TEXT",
      text: "Leverage",
      field: SortPositionsBy.Leverage,
    },
    {
      renderType: "TEXT",
      text: "Duration",
      nonSortable: true,
    },
    {
      renderType: "TEXT",
      text: showOpenPositions ? "Unrealized PnL" : "Realized PnL",
      field: SortPositionsBy.Pnl,
      width: "13%",
    },
    {
      renderType: "TEXT",
      text: "Open Price",
      nonSortable: true,
    },
    {
      renderType: "TEXT",
      text: !showOpenPositions ? "Close Price" : "Current Price",
      field: SortPositionsBy.LastPriceUsd,
    },
  ];
  const positionsRows: RenderData[][] = useMemo(
    () =>
      positions.map((position) => [
        ...(showPositionKeyColumn ? [addressCell(position.positionId)] : []),
        addressCell(position.account, undefined, true),
        positionSymbolCell(position.assetSymbol, position.assetName),
        positionSideCell(position.side),
        currencyCell(position.balanceUsd),
        numberCell(position.leverage),
        dateDiffCell(position.timeOpened, position.timeClosed),
        currencyTrendCell(position.pnl),
        currencyCell(position.openPriceUsd),
        currencyCell(position.lastPriceUsd),
      ]),
    [positions, showPositionKeyColumn],
  );

  const onShowOpenChange = (nextOpen: boolean) => {
    setShowOpenPositions(nextOpen);
  };

  return (
    <Box>
      <ChaosTable
        title="Positions"
        headers={tableHeaders}
        isLoading={loading}
        data={positionsRows}
        pageSize={pageSize}
        isFullHeight
        showSearch
        serchbarPlaceholder="Search positions"
        isFilterHidden
        customFilter={
          <PositionsFilter
            key={filters.length}
            showOpen={showOpenPositions}
            filters={filters}
            onShowOpenChange={showClosedPositions ? onShowOpenChange : undefined}
            onFiltersChange={onFiltersChange}
          />
        }
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        onSearch={onSearch}
        resetPagination={`${JSON.stringify(filters)}-${String(showOpenPositions)}`}
        rowHref={(rowId) => {
          const position = positions[rowId];

          if (!position) return "";

          if (positionDetails?.enabled) {
            return RoutePath.Risk.PositionDetails.replace(RouteParams.ClientName, clientName!).replace(
              RouteParams.PositionId,
              position.positionId,
            );
          }

          const { account, subAccount } = position;

          if (subAccount !== undefined && subAccount !== null) {
            return RoutePath.Risk.SubAccountPositions.replace(RouteParams.ClientName, clientName!)
              .replace(RouteParams.Address, account)
              .replace(RouteParams.SubAccount, subAccount.toString());
          }

          return RoutePath.Risk.AccountPositions.replace(RouteParams.ClientName, clientName!).replace(
            RouteParams.Address,
            account,
          );
        }}
        emptyState={{
          icon: "chaos",
          title: "No positions found",
        }}
        showRowChevron
        initialSortBy={3}
        isInitialSortDesc
      />
    </Box>
  );
};
