import { useEffect, useMemo, useState } from "react";
import { TitleTabs, TabProps, SubNavTabs } from "@frontend/ui";
import { useLocation, useNavigate } from "react-router-dom";

export const pathToTab = (pathname: string): string =>
  (pathname.split("/").pop() || "").replace(/-/g, " ").toLowerCase();

const tabToPath = (tab: string): string => tab.replace(/ /g, "-").toLowerCase();

export type NavigationTabProps = TabProps & {
  path?: string;
  isActive?: (pathname: string) => boolean;
};

type Props = {
  tabs: NavigationTabProps[];
  variant?: "title" | "sub-nav";
};

const TabsNavigation = ({ tabs, variant = "title" }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const activeTabIdx = useMemo(
    () =>
      tabs?.findIndex((tab) => {
        if (tab.isActive) return tab.isActive(pathname);

        if (tab.path) {
          return pathname.includes(tab.path);
        }
        return pathname.includes(tab.label.replace(" ", "-").toLowerCase());
      }) || 0,
    [pathname, tabs],
  );

  const [tabIdx, setTabIdx] = useState(activeTabIdx);

  useEffect(() => setTabIdx(activeTabIdx), [activeTabIdx]);

  const handleTabChange = (_event: React.SyntheticEvent, newTabIdx: number) => {
    if (!tabs) return;

    const tab = tabs[newTabIdx];

    navigate(tab.path || pathname.replace(/[^/]*$/, tabToPath(tab.label)));
  };

  switch (variant) {
    case "sub-nav":
      return <SubNavTabs tabs={tabs} value={tabIdx} onChange={handleTabChange} />;
    default:
      return <TitleTabs tabs={tabs} value={tabIdx} onChange={handleTabChange} />;
  }
};

export default TabsNavigation;
