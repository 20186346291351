import { NotificationBanner } from "@frontend/ui";
import { AssetConfig } from "../../generated";

type Props = {
  assetConfig: AssetConfig;
};

const NotificationsBanners = ({ assetConfig }: Props) => {
  const { isCollateralDisabled, isBorrowDisabled, isIsolationMode } = assetConfig;

  return (
    <>
      {isCollateralDisabled && (
        <NotificationBanner
          bgcolor="orange.opacity50"
          message="This asset is proposed to be launched with collateral disabled"
        />
      )}
      {isBorrowDisabled && (
        <NotificationBanner
          bgcolor="orange.opacity50"
          message="This asset is proposed to be launched with borrow disabled"
        />
      )}
      {isIsolationMode && (
        <NotificationBanner
          bgcolor="orange.opacity50"
          message="This asset is proposed to be launched in isolation mode"
        />
      )}
    </>
  );
};

export default NotificationsBanners;
