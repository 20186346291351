import { Box, CustomIcon, Grid, Paper, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { Trend } from "@frontend/ui/trend";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams } from "react-router-dom";
import { RoutePath } from "src/config/routes";
import { PriceFeed } from "src/pages/oracle-portal/types";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { useFeedData } from "../use-price-feed-details";
import { Endpoints } from "../../../api";
import { TimeSpanPicker } from "../../../../ccar-lending-page/components/time-span-picker";
import { PriceFeedTimeSpan, priceFeedTimeSpanOptions } from "../../../context/providers-select-context";

type Props = {
  feed?: PriceFeed;
};

const timeSpanToDisplayName: Record<PriceFeedTimeSpan, string> = {
  day: "24 hours",
  week: "7 days",
  month: "30 days",
};

const Header = ({ feed }: Props) => {
  const { feedId } = useParams();
  const { response: historicalPrice, timeSpan, setTimeSpan } = useFeedData(Endpoints.FeedPrice);
  const breadcrumbsLinks = [
    {
      title: "Price Feeds",
      href: RoutePath.OraclePortal.Home,
    },
  ];

  const priceChangePercent = historicalPrice && feed ? (feed.price - historicalPrice.price) / feed.price : undefined;

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <PageHeader
          pageTitle={`${feedId!} / USD`}
          breadCrumbTitleIcon={feedId}
          breadcrumbsLinks={breadcrumbsLinks}
          containerStyle={{ mt: 0, mb: 2 }}
          icon={feedId}
        />
      </Grid>
      <Grid item>
        <Paper variant="card" sx={{ py: 1, px: 1.5 }}>
          <Box width="fit-content" ml="auto">
            <TimeSpanPicker<PriceFeedTimeSpan>
              selectedTimeSpan={timeSpan}
              onSelectTimeSpan={setTimeSpan}
              timeSpanOptions={priceFeedTimeSpanOptions}
              hideLabel
              size="small"
            />
          </Box>
          <Box p={1} pt={0} width={200} display="flex" flexDirection="column" gap={1}>
            <Box display="flex" gap={1}>
              <CustomIcon icon="coin-dollar" sx={{ "& path": { fill: "#9B9DA1" } }} />
              <Typography variant="caption" fontWeight={500}>
                Price
              </Typography>
            </Box>
            <Box display="flex" gap={1} alignItems="flex-end">
              {!feed || !priceChangePercent ? (
                <ShimmerLoader sx={{ height: 32 }} />
              ) : (
                <>
                  <Typography variant="h3">
                    {formatAmount(feed?.price || 0, { currency: "usd", notation: "standard" })}
                  </Typography>
                  <Box mb={0.5}>
                    {priceChangePercent && (
                      <Trend
                        delta={priceChangePercent}
                        deltaTrend={Math.sign(priceChangePercent)}
                        deltaColor={Math.sign(priceChangePercent)}
                        tooltip={`Change over the last ${timeSpanToDisplayName[timeSpan]}`}
                        isPercent
                      />
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Header;
