import { Box, ECharts, Paper, Typography } from "@frontend/ui";
import { CustomReactSelect, OptionType } from "@frontend/ui/custom-select";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { useEffect, useState } from "react";
import { getObserversPairScatterChartOptions } from "src/pages/parameter-recommendations-page/asset-page/components/public-simulations-section/utils/get-observers-pair-scatter-chart-options";
import { useExperimentObserverData } from "src/pages/parameter-recommendations-page/asset-page/use-experiment-observers-data";

type Props = {
  observersOptions?: OptionType[];
  simulationResultId: string;
  permutationId: string;
};

const MetricScatterPlot = ({ observersOptions, simulationResultId, permutationId }: Props) => {
  const [firstSelectedObserverOption, setFirstSelectedObserverOption] = useState<OptionType>();

  const [secondSelectedObserverOption, setSecondSelectedObserverOption] = useState<OptionType>();

  useEffect(() => {
    if (observersOptions) {
      setFirstSelectedObserverOption(observersOptions[0]);
      setSecondSelectedObserverOption(observersOptions[1]);
    }
  }, [observersOptions]);

  const { isLoading: isLoadingFirstObserver, observerValues: firstSelectedObserverValues } = useExperimentObserverData(
    firstSelectedObserverOption?.value,
    simulationResultId,
    permutationId,
  );

  const { isLoading: isLoadingSecondObserver, observerValues: secondSelectedObserverValues } =
    useExperimentObserverData(secondSelectedObserverOption?.value, simulationResultId, permutationId);

  const isLoading = isLoadingFirstObserver || isLoadingSecondObserver;
  const subtitle =
    firstSelectedObserverOption && secondSelectedObserverOption
      ? `Showing observed values of 
    ${firstSelectedObserverOption.label} as a function of 
    ${secondSelectedObserverOption.label}, 
    each point on the chart is a single blockchain simulation.`
      : "";

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <Box>
          <Typography variant="h3">Metric Correlation</Typography>
          <Typography>{subtitle}</Typography>
        </Box>
        {observersOptions && secondSelectedObserverOption && firstSelectedObserverOption && (
          <Box display="flex" gap={2}>
            <CustomReactSelect
              placeholder="Observer"
              value={firstSelectedObserverOption}
              options={observersOptions.filter((obs) => obs.value !== secondSelectedObserverOption.value)}
              onChange={(selected) => {
                if (!selected) return;
                setFirstSelectedObserverOption(selected);
              }}
            />
            <CustomReactSelect
              placeholder="Observer"
              value={secondSelectedObserverOption}
              options={observersOptions.filter((obs) => obs.value !== firstSelectedObserverOption.value)}
              onChange={(selected) => {
                if (!selected) return;
                setSecondSelectedObserverOption(selected);
              }}
            />
          </Box>
        )}
      </Box>
      <Paper variant="widget">
        {firstSelectedObserverOption && secondSelectedObserverOption && (
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h3">{`${firstSelectedObserverOption.label} x ${secondSelectedObserverOption.label}`}</Typography>
          </Box>
        )}
        <Box height={380} width="100%">
          {isLoading && <ShimmerLoader />}
          {!isLoading && firstSelectedObserverValues && secondSelectedObserverValues && (
            <ECharts
              option={getObserversPairScatterChartOptions(firstSelectedObserverValues, secondSelectedObserverValues)}
              zoomable
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default MetricScatterPlot;
