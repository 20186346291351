import { useParams } from "react-router-dom";
import ProposedAssetsTable from "src/pages/parameter-recommendations-page/components/proposed-assets-table/proposed-assets-table";
import { ChaosApolloProvider } from "src/utils/graphql";

const ProposedAssets = () => {
  const { marketId } = useParams();

  // Currently proposed assets are only supported for Chain markets (marketId = chain)
  return (
    <ChaosApolloProvider product="asset-listing">
      <ProposedAssetsTable showLoader chain={marketId} />
    </ChaosApolloProvider>
  );
};

export default ProposedAssets;
