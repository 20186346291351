import { Paper, Box, Typography, HistogramChart } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { useParams } from "react-router-dom";
import { useRiskExplorerAssetWalletsHealthHistogramQuery } from "src/pages/ccar-lending-page/generated";

type Props = {
  liquidationThreshold: number;
};

const HealthDistribution = ({ liquidationThreshold }: Props) => {
  const { marketId, asset } = useParams();
  const { loading, data } = useRiskExplorerAssetWalletsHealthHistogramQuery({
    variables: {
      query: [
        {
          priceChangePercent: 0,
          chains: null,
          marketsIds: [marketId!],
          tokenSymbol: asset!,
          liquidationThreshold: liquidationThreshold * 100 || null,
          isRawSymbol: true,
        },
      ],
    },
  });

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="h3">Health Distribution</Typography>
      <Paper variant="widget">
        <Box height={380}>
          {loading ? (
            <ShimmerLoader />
          ) : (
            data && (
              <HistogramChart
                name="Health Score"
                bins={data.riskExplorerAssetWalletsHealthHistogram.map((bin) => ({
                  start: bin.bucket.start,
                  end: bin.bucket.end,
                  value: bin.numberOfWallets,
                }))}
                yAxisOptions={{
                  label: "Number of Wallets",
                }}
              />
            )
          )}
        </Box>
      </Paper>
    </Paper>
  );
};

export default HealthDistribution;
