import { Box } from "@frontend/ui";
import { WithdrawalCards, EventsTable } from "./components";

const Overview = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <WithdrawalCards />
    <EventsTable />
  </Box>
);

export default Overview;
