import { Grid } from "@frontend/ui";
import { formatAmount, formatPercent } from "@frontend/ui/utils/formatters";
import { useMemo } from "react";
import { palette } from "@frontend/ui/theme/palette";
import { RecommendationCard, RecommendationCardProps } from "src/pages/parameter-recommendations-page/components";
import { MetricResult, ParameterModelResult } from "src/pages/ccar-lending-page/generated";

type Props = {
  paramModelResult: ParameterModelResult;
};

const SimulationResultsCards = ({ paramModelResult }: Props) => {
  const recommendationsCards: RecommendationCardProps[] = useMemo(() => {
    const { metrics } = paramModelResult;
    const metricResultByName = metrics.reduce(
      (acc: Record<string, MetricResult>, metric) => ({
        ...acc,
        [metric.name]: metric.result,
      }),
      {},
    );

    const { aaveUtility, debtAgainstAsset, borrowUsage, valueAtRisk, extremeValueAtRisk } = metricResultByName;

    const utilityRatio = aaveUtility?.recommended
      ? aaveUtility.recommended.value / aaveUtility.current.value - 1
      : undefined;

    const currentDebtAgainstAsset = debtAgainstAsset?.current.value;
    const optimalDebtAgainstAsset = debtAgainstAsset?.recommended?.value;

    const debtAgainstAssetDiffUsd =
      currentDebtAgainstAsset !== undefined && optimalDebtAgainstAsset !== undefined
        ? optimalDebtAgainstAsset - currentDebtAgainstAsset
        : undefined;

    return [
      {
        title: "Borrow Usage",
        icon: "waterfall-up",
        currentValue: formatPercent(borrowUsage?.current?.value || 0),
        valueWithRecommendation: borrowUsage?.recommended ? formatPercent(borrowUsage.recommended.value) : undefined,
        tooltip: "The percent of the supplied collateral that is currently being borrowed against.",
        recommendedTrend: debtAgainstAssetDiffUsd && {
          delta: debtAgainstAssetDiffUsd,
          deltaTrend: debtAgainstAssetDiffUsd > 0 ? 1 : -1,
          deltaColor: debtAgainstAssetDiffUsd > 0 ? 1 : -1,
          currency: "USD",
          tooltip: "",
        },
      },
      {
        title: "VaR",
        icon: "waterfall-down",
        currentValue: formatAmount(valueAtRisk?.current?.value || 0, { currency: "USD" }),
        valueWithRecommendation: valueAtRisk?.recommended
          ? formatAmount(valueAtRisk.recommended.value, { currency: "USD" })
          : undefined,
        tooltip:
          "Value at Risk - 95th percentile of bad debt observed through simulations of extreme market conditions.",
      },
      {
        title: "Extreme VaR",
        icon: "waterfall-down",
        currentValue: formatAmount(extremeValueAtRisk?.current?.value || 0, { currency: "USD" }),
        valueWithRecommendation: extremeValueAtRisk?.recommended
          ? formatAmount(extremeValueAtRisk.recommended.value, { currency: "USD" })
          : undefined,
        tooltip:
          "Extreme Value at Risk - 99th percentile of bad debt observed through simulations of extreme market conditions.",
      },
      {
        title: "Utility Ratio",
        titleVariant: "h5",
        icon: "chaos",
        valueWithRecommendation:
          utilityRatio !== undefined ? `${utilityRatio >= 0 ? "+" : ""}${formatPercent(utilityRatio)}` : "+0%",
        bgColor: palette.blue.opacity15,
        tooltip: `Potential improvement in asset Risk / Return ratio compared to the current state,
        calculated as the change in borrows divided by the change in bad debt.`,
        isIconOriginalColor: true,
      },
    ] as RecommendationCardProps[];
  }, [paramModelResult]);

  return (
    <Grid container spacing={3}>
      {recommendationsCards.map((cardProps) => (
        <Grid key={cardProps.title} item xs={12} sm={6} lg={3}>
          <RecommendationCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SimulationResultsCards;
