import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { dataFetchersURL } from "src/utils/data-fetchers-url";
import { AssetInfo } from "../../types";

const getDydxAssetInfo = async (asset: string) => {
  const res = await axios.get<AssetInfo>(`${dataFetchersURL()}data/page/asset_info?token=${asset}&client=dydx`);
  return res.data;
};

type ReturnType = {
  assetInfo?: AssetInfo;
  isLoading: boolean;
};

export const useDydxAssetInfo = (asset?: string): ReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assetInfo, setAssetInfo] = useState<AssetInfo>();

  useEffect(() => {
    void (async () => {
      if (!asset) return;
      setIsLoading(true);
      const fetchedAssetInfo = await getDydxAssetInfo(asset);
      setAssetInfo(fetchedAssetInfo);
      setIsLoading(false);
    })();
  }, [asset]);

  const returnObj: ReturnType = useMemo(() => ({ assetInfo, isLoading }), [assetInfo, isLoading]);

  return returnObj;
};
