import { AssetRecommendation } from "src/pages/asset-listing-portal/generated";
import { RevenueEstimation as RevenueEstimationCard } from "src/pages/asset-page/revenue-estimation/revenue-estimation";

type Props = {
  assetRecommendations: AssetRecommendation[];
  assetPrice: number;
};

const RevenuePotential = ({ assetRecommendations, assetPrice }: Props) => {
  const findRecommendedValue = (paramName: string) =>
    assetRecommendations.find((r) => r.paramName.toLowerCase() === paramName.toLowerCase())?.recommendedValue;

  const supplyCap = findRecommendedValue("Supply Cap");
  const uOptimal = findRecommendedValue("Uoptimal");
  const reserveFactor = findRecommendedValue("Reserve Factor");
  const variableBase = findRecommendedValue("Variable Base");
  const variableSlope1 = findRecommendedValue("Variable Slope1");

  const showRevenuePotential =
    supplyCap !== undefined &&
    uOptimal !== undefined &&
    reserveFactor !== undefined &&
    variableBase !== undefined &&
    variableSlope1 !== undefined;

  if (!showRevenuePotential) return null;

  const interestRateAtUoptimal = variableBase + variableSlope1;

  const revenuePotential = supplyCap * uOptimal * interestRateAtUoptimal * reserveFactor * assetPrice;

  return (
    <RevenueEstimationCard
      title="Revenue Potential"
      subtitle="Yearly revenue from this asset in USD if listed, in case the maximal amount is supplied, the optimal amount (UOptimal) is borrowed, and the current asset price."
      value={revenuePotential}
    />
  );
};

export default RevenuePotential;
