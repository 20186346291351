import { Chain } from "../../generated";
import { ChainAnalyticsClientConfig } from "../types";

export const aptosConfig: ChainAnalyticsClientConfig = {
  chain: Chain.Aptos,
  role: "aptos-dashboard-viewer",
  token: "apt",
  ecosystemPerformance: {
    chainsToCompare: [Chain.Avalanche, Chain.Bnb, Chain.Solana],
  },
};
