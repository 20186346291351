import { useParams } from "react-router-dom";
import { AssetPage } from "src/pages/asset-listing-portal/asset-page";
import { ChaosApolloProvider } from "src/utils/graphql";

const ProposedAsset = () => {
  const { marketId } = useParams();

  // Currently proposed assets are supported only for Chain markets (marketId = chain)
  return (
    <ChaosApolloProvider product="asset-listing">
      <AssetPage hideHeader chain={marketId} />
    </ChaosApolloProvider>
  );
};

export default ProposedAsset;
