import { Box } from "@frontend/ui";
import { ParameterModelResult } from "src/pages/ccar-lending-page/generated";
import {
  SimulationResultsSectionHeader,
  SimulationResultsCards,
  HealthDistribution,
  WalletsAtRiskTable,
  ExperimentObservationsCharts,
} from "./components";

type Props = {
  paramModelResult: ParameterModelResult;
  isUnderReview: boolean;
};

const SimulationResultsSection = ({ paramModelResult }: Props) => {
  const { current, recommended } = paramModelResult;

  const valueDiff = recommended ? recommended.value - current.value : undefined;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <SimulationResultsSectionHeader />
      <SimulationResultsCards paramModelResult={paramModelResult} />
      {recommended && (
        <>
          <HealthDistribution currentLt={current.value} recommendedLt={recommended.value} />
          {!!valueDiff && valueDiff < 0 && <WalletsAtRiskTable newLT={recommended.value} />}
          <ExperimentObservationsCharts paramModelResult={paramModelResult} />
        </>
      )}
    </Box>
  );
};

export default SimulationResultsSection;
