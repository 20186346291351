import { getDeltaFields } from "@frontend/ui/trend";
import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { OverviewData } from "../use-overview-data";

type Props = {
  data: OverviewData;
};

const OverviewCards = ({ data }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "TVL",
      value: data.tvl?.value,
      icon: "pie-slice",
      currency: "USD",
      deltaTooltip: "Change from the beginning of day",
      ...getDeltaFields(data.tvl?.value, data.tvl?.prevValue || undefined, true, true),
    },
    {
      title: "Number of Active Users (30D)",
      value: data.numberOfActiveUsers?.value,
      ...getDeltaFields(data.numberOfActiveUsers?.value, data.numberOfActiveUsers?.prevValue || undefined, true, true),
      icon: "users",
    },
    {
      title: "Total Transaction Count",
      value: data.totalTransactionCount?.value,
      icon: "clock",
      ...getDeltaFields(
        data.totalTransactionCount?.value,
        data.totalTransactionCount?.prevValue || undefined,
        true,
        true,
      ),
    },
    {
      title: "Coin Price",
      value: data.tokenPrice?.value,
      ...getDeltaFields(data.tokenPrice?.value, data.tokenPrice?.prevValue || undefined, true, true),
      icon: "coin-dollar",
      currency: "USD",
      valueMaximumFractionDigits: 2,
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OverviewCards;
