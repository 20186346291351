import { Paper, CompositionOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { LendingEventType, TimeSpan, useAggEventsQuery } from "../../generated";

type Props = {
  timeSpan: TimeSpan;
  marketId: string;
};
const AggSupplyBorrowEventsChart = ({ timeSpan, marketId }: Props) => {
  const { loading, data } = useAggEventsQuery({
    variables: {
      query: {
        timeSpan,
        marketId,
        eventsTypes: [LendingEventType.Borrow, LendingEventType.Supply],
      },
    },
  });

  const { series, netInflow } = (data?.aggEvents ?? []).reduce(
    (acc, aggEvent) => {
      const { series: accSeries, netInflow: accNetInflow } = acc;
      const { data: assetsData, timestamp, type } = aggEvent;

      assetsData.forEach((assetData) => {
        const { asset, amountInUsd } = assetData;
        const key = `${asset}-${type}`;
        const currentAssetSeries = accSeries[key];
        const multiplier = type === LendingEventType.Borrow ? -1 : 1;
        const amount = amountInUsd * multiplier;
        const dataEntry: [number, number] = [timestamp, amount];
        if (currentAssetSeries) {
          const { data: seriesData } = currentAssetSeries;
          const newData = [...seriesData, dataEntry];
          currentAssetSeries.data = newData;
        } else {
          const newSeries: NumberChartSeries = {
            label: asset,
            type: "bar",
            data: [dataEntry],
            stack: "bar",
          };
          accSeries[key] = newSeries;
        }

        accNetInflow[timestamp] = (accNetInflow[timestamp] || 0) + amount;
      });

      return acc;
    },
    { series: {} as Record<string, NumberChartSeries>, netInflow: {} as Record<number, number> },
  );

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        title="Inflow and Outflow"
        description="Showing per asset sum of supply and withdraw over time"
        isLoading={loading}
        series={[
          ...Object.values(series),
          {
            label: "Net Inflow",
            type: "line",
            data: Object.entries(netInflow).map(([k, v]) => [Number(k), Number(v)]),
          },
        ]}
      />
    </Paper>
  );
};

export default AggSupplyBorrowEventsChart;
