import { useClientConfig } from "../../clients-config";
import {
  ChainHistoryValue,
  TimeSpan,
  useChainsDexTradingVolumeHistoryQuery,
  useChainsNumberOfUsersHistoryQuery,
  useChainsTvlHistoryQuery,
} from "../../generated";

export type EcosystemPerformanceData = {
  chainsTvlHistory?: ChainHistoryValue[];
  chainsNumberOfUsersHistory?: ChainHistoryValue[];
  chainsDexTradingVolumeHistory?: ChainHistoryValue[];
  isLoadingChainsTvlHistory: boolean;
  isLoadingChainsNumberOfUsersHistory: boolean;
  isLoadingChainsDexTradingVolumeHistory: boolean;
};

export const useEcosystemPerformanceData = (daysAgo: number | null): EcosystemPerformanceData => {
  const {
    chain,
    ecosystemPerformance: { chainsToCompare },
  } = useClientConfig();

  const chains = [chain, ...chainsToCompare];
  const { data: chainsTvlHistoryData, loading: isLoadingChainsTvlHistory } = useChainsTvlHistoryQuery({
    variables: {
      chains,
      daysAgo,
    },
  });

  const { data: chainsNumberOfUsersHistoryData, loading: isLoadingChainsNumberOfUsersHistory } =
    useChainsNumberOfUsersHistoryQuery({
      variables: {
        chains,
        daysAgo,
        timeSpan: TimeSpan.Day,
      },
    });

  const { data: chainsDexTradingVolumeHistoryData, loading: isLoadingChainsDexTradingVolumeHistory } =
    useChainsDexTradingVolumeHistoryQuery({
      variables: {
        chains,
        daysAgo,
      },
    });

  return {
    chainsTvlHistory: chainsTvlHistoryData?.chainsTvlHistory,
    chainsNumberOfUsersHistory: chainsNumberOfUsersHistoryData?.chainsNumberOfUsersHistory,
    chainsDexTradingVolumeHistory: chainsDexTradingVolumeHistoryData?.chainsDexTradingVolumeHistory,
    isLoadingChainsTvlHistory,
    isLoadingChainsNumberOfUsersHistory,
    isLoadingChainsDexTradingVolumeHistory,
  };
};
