import {
  Box,
  Button,
  CryptoIcon,
  CustomIcon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Theme,
  useMediaQuery,
} from "@frontend/ui";
import { clientViewProps } from "src/pages/community-analytics-page/client-view-props";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RouteParams, RoutePath } from "src/config/routes";

import { ListSubheader, Typography } from "@mui/material";
import { clientsViewPropsMapping } from "src/pages/community-analytics-page/clients-view-props-mapping";
import { RiskMonitoringRoutes } from "src/config/risk-monitoring-routes";
import { useFeatureFlag, useFeatureFlags } from "src/utils/feature-flags";
import { groups as protocols, ProtocolGroupMetadata, ProtocolGroupType } from "./protocol-groups";

const useProtocolGroups = () => {
  const dydxEnabled = useFeatureFlag("dydx-v4");
  const ostiumTestnetEnabled = useFeatureFlag("ostium-testnet");
  const ostiumEnabled = useFeatureFlag("ostium");
  const seamlessEnabled = useFeatureFlag("seamless");
  const zerolendEnabled = useFeatureFlag("zerolend");
  const bluefinEnabled = useFeatureFlag("bluefin");
  const nftperpEnabled = useFeatureFlag("nftperp");
  const jupiterEnabled = useFeatureFlag("jupiter");
  const synFuturesEnabled = useFeatureFlag("synfutures");
  const aptosEnabled = useFeatureFlag("aptos");
  const etherfiEnabled = useFeatureFlag("etherfi");

  const cloned = { ...protocols };

  if (!dydxEnabled) {
    delete cloned["dydx-v4"];
  }

  if (!ostiumTestnetEnabled) {
    delete cloned["ostium-testnet"];
  }

  if (!ostiumEnabled) {
    delete cloned.ostium;
  }

  if (!seamlessEnabled) {
    delete cloned.seamless;
  }

  if (!zerolendEnabled) {
    delete cloned.zerolend;
  }

  if (!bluefinEnabled) {
    delete cloned.bluefin;
  }

  if (!nftperpEnabled) {
    delete cloned.nftperp;
  }

  if (!jupiterEnabled) {
    delete cloned.jupiter;
  }

  if (!synFuturesEnabled) {
    delete cloned.synfutures;
  }

  if (!aptosEnabled) {
    delete cloned.aptos;
  }

  if (!etherfiEnabled) {
    delete cloned.etherfi;
  }

  return cloned;
};

const ListHeader = ({ title }: { title: string }) => (
  <ListSubheader
    sx={{
      background: "transparent",
      fontSize: "12px",
      fontWeight: 400,
      textAlign: "center",
      color: "almostWhite.main",
      lineHeight: "16px",
      marginTop: "16px",
      marginBottom: "4px",
    }}
  >
    {title}
  </ListSubheader>
);

export const useProtocolSwitcherContext = () => {
  const groups = useProtocolGroups();
  const { clientName } = useParams<{ clientName: string }>();
  const isInRiskMonitoring = window.location.pathname.includes("/risk");
  const groupsIncludeClient = Object.keys(groups).some((key) => clientName?.includes(key));

  return {
    shouldShowProtocolSwitcher: groupsIncludeClient && isInRiskMonitoring,
  };
};

export const ProtocolSwitcher = () => {
  const groups = useProtocolGroups();
  const { clientName } = useParams<"clientName">();
  const featureFlags = useFeatureFlags();
  const navigate = useNavigate();
  const clientsProps = clientViewProps();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const currentGroupKey = Object.keys(groups).find((k) => clientName?.includes(k)) || "";
  const currentGroup = groups[currentGroupKey];
  const [groupKey, setGroupKey] = useState(currentGroupKey);
  const selectedGroup = groups[groupKey];

  /** Preload logo images to avoid flashes while switching clients */
  useEffect(() => {
    const allLogos = Object.values(clientsViewPropsMapping).map((m) => m.headerProps.clientLogo);
    allLogos.forEach((logo) => {
      const img = new Image();
      img.src = logo;
    });
  }, []);

  const getSubLabel = () => {
    let subLabel = "";
    Object.keys(groups).forEach((k) => {
      const group = groups[k];
      const groupOptions = group.options;
      const groupLabel = groupOptions?.find((o) => o.value === clientName)?.label;
      if (groupLabel) {
        subLabel = groupLabel;
      } else if (k === clientName) {
        subLabel = group.subLabel ?? "";
      }
    });

    return subLabel;
  };

  const navigateToProtocol = (value: string, type: ProtocolGroupType) => {
    let path = "";
    if (currentGroup.type === type) {
      path = window.location.pathname.replace(clientName!, value);
    } else if (type === "lending" || type === "chain-analytics" || type === "leverage") {
      path = RoutePath.Risk.Home.replace(RouteParams.ClientName, value);
    } else if (type === "perp") {
      path = RoutePath.CCARPerpetuals.Home.replace(RouteParams.ClientName, value);
    }

    // Ignore any route params other than the client name
    // If present, navigate to the parent route.
    const riskMonitoringHome = RiskMonitoringRoutes.Home.replace(RouteParams.ClientName, clientName!);
    const match = window.location.pathname.match(`${riskMonitoringHome}/(.[a-z]+)`); // https://regex101.com/r/jxFlfz/1

    if (match) {
      path = match[0].replace(clientName!, value);
    }

    if (path.length) {
      navigate(path);
    }
  };

  const handleProtocolClick = (protocolKey: string) => {
    const group = groups[protocolKey];
    setGroupKey(protocolKey);

    if (!group.options) {
      navigateToProtocol(protocolKey, group.type);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const createGroupListItem = (key: string, groupMetadata: ProtocolGroupMetadata) => (
    <ListItemButton
      key={key}
      onClick={() => handleProtocolClick(key)}
      sx={{ ":active, &:focus": { bgcolor: "button.hover" } }}
    >
      <ListItemIcon sx={{ minWidth: 32 }}>
        <CryptoIcon icon={groupMetadata.icon} />
      </ListItemIcon>
      <ListItemText>{groupMetadata.label}</ListItemText>
      {clientName?.includes(key) && (
        <svg
          style={{ minWidth: 24 }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="4" fill="#0366C1" />
        </svg>
      )}
      <ListItemIcon sx={{ minWidth: 0 }}>
        <CustomIcon
          icon="chevron-right"
          sx={{
            visibility: groupMetadata.options ? "visible" : "hidden",
          }}
        />
      </ListItemIcon>
    </ListItemButton>
  );

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={<CustomIcon icon="chevron-down-small" />}
        sx={{
          bgcolor: "button.main",
          px: [1, 2],
          py: [1, 1.5],
          fontWeight: "normal",
          display: "flex",
          justifyContent: "space-between",
          ".MuiButton-endIcon": {
            ml: 0,
          },
        }}
      >
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img
            style={{
              display: "block",
              maxHeight: isMobile ? 16 : 24,
              height: "auto",
              maxWidth: isMobile ? 80 : 100,
              width: "auto",
            }}
            src={clientsProps?.headerProps.clientLogo}
            alt="header"
          />
          <Typography noWrap={isMobile} sx={{ maxWidth: isMobile ? 80 : "none", fontSize: isMobile ? "0.8em" : "1em" }}>
            {getSubLabel()}
          </Typography>
        </Box>
      </Button>
      <Menu
        MenuListProps={{ sx: { p: 0, boxShadow: "0 0 15px 20px" } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionProps={{ timeout: 0 }}
      >
        <Box
          sx={{
            display: "flex",
            bgcolor: "black.main",
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <List
            sx={{
              p: 0,
              flexGrow: 1,
              borderRight: "1px solid",
              borderColor: "button.main",
            }}
          >
            <ListHeader title="Borrow & Lend" />
            {Object.entries(groups)
              .filter(([, v]) => v.type === "lending" || v.category === "lending")
              .map(([k, v]) => createGroupListItem(k, v))}
            <ListHeader title="Perpetual" />
            {Object.entries(groups)
              .filter(([, v]) => v.type === "perp")
              .map(([k, v]) => createGroupListItem(k, v))}
          </List>
          {!!selectedGroup?.options?.length && (
            <List sx={{ p: 0, flexGrow: 1, minWidth: 190 }}>
              {selectedGroup.options
                .filter((o) => !o.featureFlag || featureFlags.find((f) => f.key === o.featureFlag && f.enabled))
                .map((item) => (
                  <ListItemButton key={item.value} onClick={() => navigateToProtocol(item.value, selectedGroup.type)}>
                    <ListItemText sx={{ textWrap: isMobile ? "wrap" : "nowrap" }}>{item.label}</ListItemText>
                    {item.value === clientName && (
                      <svg
                        style={{ minWidth: 24 }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="12" cy="12" r="4" fill="#0366C1" />
                      </svg>
                    )}
                  </ListItemButton>
                ))}
            </List>
          )}
        </Box>
      </Menu>
    </>
  );
};
