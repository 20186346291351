import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "../clients-config";
import { MethodologyPage } from "../../methodology-page";

export const ParameterRecommendationsRoutes = () => {
  const { clientName } = useParams();
  const { methodology } = useClientConfig();

  return (
    <Routes>
      <Route
        path={RoutePath.ParameterRecommendations.Methodology.replace(RoutePath.ParameterRecommendations.Home, "")}
        element={<MethodologyPage methodology={methodology} />}
      />
      <Route
        path="*"
        element={<Navigate to={methodology.redirectBack.replace(RouteParams.ClientName, clientName!)} replace />}
      />
    </Routes>
  );
};
