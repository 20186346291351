import { useClientConfig } from "../../clients-config";
import {
  AggregationPeriod,
  ChainCategoryHistoryValue,
  ChainHistoryValue,
  TokenHistoryValue,
  useBridgeNetFlowsByChainQuery,
  useBridgeNetFlowsByTokenQuery,
  useNumberOfActiveUsersQuery,
  useStablecoinAmountHistoryQuery,
  useTokenPriceQuery,
  useTotalTransactionCountQuery,
  useTvlByDappCategoryQuery,
  useTvlQuery,
  ValueByCategory,
  ValuePoint,
} from "../../generated";

export type OverviewData = {
  tvl?: ValuePoint;
  numberOfActiveUsers?: ValuePoint;
  totalTransactionCount?: ValuePoint;
  tvlByDappCategory?: ValueByCategory[];
  tokenPrice?: ValuePoint;
  bridgeNetFlowsByChain?: ChainHistoryValue[];
  bridgeNetFlowsByToken?: TokenHistoryValue[];
  stablecoinAmountHistory?: ChainCategoryHistoryValue[];
  isLoadingTvl: boolean;
  isLoadingNumberOfActiveUsers: boolean;
  isLoadingTotalTransactionCount: boolean;
  isLoadingTvlByDappCategory: boolean;
  isCoinPriceLoading: boolean;
  isLoadingBridgeNetFlowsByChain: boolean;
  isLoadingBridgeNetFlowsByToken: boolean;
  isLoadingStablecoinAmountHistory: boolean;
};

export const useOverviewData = (): OverviewData => {
  const { chain, token } = useClientConfig();
  const { data: tvlData, loading: isLoadingTvl } = useTvlQuery({
    variables: {
      chain,
    },
  });

  const { data: numberOfActiveUsersData, loading: isLoadingNumberOfActiveUsers } = useNumberOfActiveUsersQuery({
    variables: {
      chain,
      period: AggregationPeriod.Monthly,
    },
  });

  const { data: totalTransactionCountData, loading: isLoadingTotalTransactionCount } = useTotalTransactionCountQuery({
    variables: {
      chain,
    },
  });

  const { data: tvlByDappCategoryData, loading: isLoadingTvlByDappCategory } = useTvlByDappCategoryQuery({
    variables: {
      chain,
    },
  });

  const { data: priceData, loading: isCoinPriceLoading } = useTokenPriceQuery({
    variables: {
      token,
    },
  });

  const { data: bridgeNetFlowsByChainData, loading: isLoadingBridgeNetFlowsByChain } = useBridgeNetFlowsByChainQuery({
    variables: {
      chain,
    },
  });

  const { data: bridgeNetFlowsByTokenData, loading: isLoadingBridgeNetFlowsByToken } = useBridgeNetFlowsByTokenQuery({
    variables: {
      chain,
    },
  });

  const { data: stablecoinAmountHistoryData, loading: isLoadingStablecoinAmountHistory } =
    useStablecoinAmountHistoryQuery({
      variables: {
        chain,
      },
    });

  return {
    tvl: tvlData?.tvl,
    numberOfActiveUsers: numberOfActiveUsersData?.numberOfActiveUsers,
    totalTransactionCount: totalTransactionCountData?.totalTransactionCount,
    tvlByDappCategory: tvlByDappCategoryData?.tvlByDappCategory,
    bridgeNetFlowsByChain: bridgeNetFlowsByChainData?.bridgeNetFlowsByChain,
    bridgeNetFlowsByToken: bridgeNetFlowsByTokenData?.bridgeNetFlowsByToken,
    stablecoinAmountHistory: stablecoinAmountHistoryData?.stablecoinAmountHistory,
    isLoadingTvl,
    isLoadingNumberOfActiveUsers,
    isLoadingTotalTransactionCount,
    isLoadingTvlByDappCategory,
    isCoinPriceLoading,
    isLoadingBridgeNetFlowsByChain,
    isLoadingBridgeNetFlowsByToken,
    isLoadingStablecoinAmountHistory,
    tokenPrice: priceData?.tokenPrice,
  };
};
