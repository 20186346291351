import { TimeSpan } from "@frontend/ui/echarts/types";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from "react";
import { PriceFeedProvider } from "../types";

export const priceFeedTimeSpanOptions = [TimeSpan.Day, TimeSpan.Week, TimeSpan.Month] as const;
export type PriceFeedTimeSpan = (typeof priceFeedTimeSpanOptions)[number];

export type ProvidersSelectContextType = {
  baselineProviderId?: string;
  selectedBaselineProvider?: PriceFeedProvider;
  providersIds?: string[];
  selectedProviders?: PriceFeedProvider[];
  oracleProviders?: PriceFeedProvider[];
  exchangeProviders?: PriceFeedProvider[];
  providers?: PriceFeedProvider[];
  providerNameById?: Record<string, string>;
  setBaselineProviderId: Dispatch<SetStateAction<string | undefined>>;
  setProvidersIds: Dispatch<SetStateAction<string[] | undefined>>;
};

const initialState: ProvidersSelectContextType = {
  setBaselineProviderId: () => {},
  setProvidersIds: () => {},
};

export const ProvidersSelectContext = createContext<ProvidersSelectContextType>(initialState);

export const useProvidersSelectContext = () => {
  const context = useContext(ProvidersSelectContext);
  return context;
};

type Props = {
  providers?: PriceFeedProvider[];
  children: ReactNode;
};

const ProvidersSelectProvider = ({ children, providers }: Props) => {
  const [baselineProviderId, setBaselineProviderId] = useState<string>();
  const [providersIds, setProvidersIds] = useState<string[]>();

  const contextState: ProvidersSelectContextType = useMemo(() => {
    const oracleProviders = providers
      ?.filter((p) => p.type === "oracle")
      ?.sort((a) => (a.name === "chainlink" ? -1 : 0));
    const exchangeProviders = providers?.filter((p) => p.type === "exchange");

    const sortedProviders = [...(providers || [])].sort((a, b) => b.type.localeCompare(a.type));
    const baselineId = baselineProviderId || oracleProviders?.[0]?.id;
    const selectedProvidersIds = sortedProviders ? providersIds || sortedProviders.map((p) => p.id) : undefined;

    const selectedBaselineProvider = oracleProviders?.find((p) => p.id === baselineId);
    const selectedProviders = sortedProviders?.filter(
      (p) => (selectedProvidersIds || []).includes(p.id) && p.id !== baselineId,
    );

    const providerNameById = (providers || []).reduce((acc, p) => ({ ...acc, [p.id]: p.display_name }), {});
    return {
      baselineProviderId: baselineId,
      selectedBaselineProvider,
      selectedProviders,
      oracleProviders,
      exchangeProviders,
      providers: sortedProviders,
      providerNameById,
      setBaselineProviderId,
      setProvidersIds,
    };
  }, [baselineProviderId, providers, providersIds]);

  return <ProvidersSelectContext.Provider value={contextState}>{children}</ProvidersSelectContext.Provider>;
};

export default ProvidersSelectProvider;
