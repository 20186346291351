import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, numberCell, textCell } from "@frontend/ui";
import { Metric } from "src/pages/ccar-lending-page/generated";
import { ExperimentType } from "../types";
import { confidenceIntervalToString } from "./utils";

const tableHeaders: Header[] = [
  {
    renderType: "TEXT",
    text: "Metric",
    width: "20%",
  },
  {
    renderType: "TEXT",
    text: "Mean w. Confidence Interval (95%)",
    tooltip: "Assuming Normal Distribution",
  },
  {
    renderType: "TEXT",
    text: "P50",
  },
  {
    renderType: "TEXT",
    text: "P95",
  },
  {
    renderType: "TEXT",
    text: "P99",
  },
];

type Props = {
  metrics: Metric[];
  experimentType: ExperimentType;
};

const MetricsTable = ({ metrics, experimentType }: Props) => {
  const data: RenderData[][] = metrics.reduce((acc: RenderData[][], { result: metric }) => {
    const { statistics } = metric[experimentType] || {};

    if (!statistics) return acc;

    const { displayName, valueType } = metric;
    const isCurrency = valueType === "currency";

    const valueCell = isCurrency ? currencyCell : numberCell;

    const newRow: RenderData[] = [
      textCell(displayName || ""),
      textCell(confidenceIntervalToString(statistics.confidenceInterval, isCurrency ? "USD" : undefined)),
      valueCell(statistics.p50),
      valueCell(statistics.p95),
      valueCell(statistics.p99),
    ];

    return [...acc, newRow];
  }, []);

  return <ChaosTable title="Experiment View" headers={tableHeaders} data={data} isFilterHidden />;
};

export default MetricsTable;
