import { FC } from "react";
import { Paper } from "@frontend/ui/paper";
import { Typography } from "@frontend/ui/typography";
import { Box, Tooltip } from "@frontend/ui";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { AssetGagueChart } from "../charts/asset-gague-chart";

export interface TickSizeProps {
  optimalTick?: number;
  currentTick?: number;
  unit?: string;
}

export const TickSize: FC<TickSizeProps> = ({ optimalTick, currentTick, unit }) => (
  <Paper
    variant="card"
    sx={{
      display: "flex",
      justifyContent: "space-between",
      gap: 5,
      height: "100%",
    }}
  >
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="h3" whiteSpace="nowrap">
        Tick Size Analysis
      </Typography>
      <Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h6" color="almostWhite.main" whiteSpace="nowrap">
            Recommended Tick Size
          </Typography>
          <Tooltip arrow title="Recommended tick size to maximize trading volume">
            <CustomIcon icon="info" sx={{ svg: { width: 20, height: 20 }, path: { fill: "#9B9DA1" } }} />
          </Tooltip>
        </Box>
        <Typography variant="h6">{optimalTick ? `${unit ?? ""}${optimalTick}` : "N/A"}</Typography>
        <Typography variant="h6" color="almostWhite.main">
          Current Tick Size
        </Typography>
        <Typography variant="h6">{currentTick ? `${unit ?? ""}${currentTick}` : "N/A"}</Typography>
      </Box>
    </Box>
    {optimalTick && currentTick && (
      <Paper
        variant="widget"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <AssetGagueChart optimalValue={optimalTick} currentValue={currentTick} unit={unit} />
      </Paper>
    )}
  </Paper>
);
