import { Box } from "../box";
import { CryptoIcon } from "../crypto-icon";
import { CustomIcon } from "../custom-icon";
import { Link } from "../link";
import { Typography } from "../typography";

type Props = {
  link: string;
  text: string;
  icon?: string;
  cryptoIcon?: string;
};

export const ExternalLinkButton = ({ link, text, icon, cryptoIcon }: Props) => (
  <Link
    href={link}
    target="_blank"
    underline="none"
    color="inherit"
    onClick={(e) => e.stopPropagation()}
    maxWidth="100%"
  >
    <Box component="span" bgcolor="almostWhite.opacity20" px={1} py={0.5} display="flex" gap={1} borderRadius={1}>
      {icon && <CustomIcon icon={icon} sx={{ svg: { width: 24, height: 24 } }} />}
      {cryptoIcon && <CryptoIcon icon={cryptoIcon} sx={{ svg: { width: 24, height: 24 } }} />}
      <Typography variant="h5">{text}</Typography>
      <CustomIcon icon="external-link" sx={{ svg: { width: 24, height: 24 } }} />
    </Box>
  </Link>
);
