import { FC } from "react";
import { Typography } from "@frontend/ui/typography";
import { Box } from "@frontend/ui/box";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { Tooltip } from "@frontend/ui";
import { DemandAssessment } from "../types";

interface DemandScoreProps {
  title: string;
  subtitleStatus?: string;
  tooltip: string;
  value?: number;
}

function StateFromScore(score: number): string {
  if (score > 90) {
    return "Excellent";
  }
  if (score > 80) {
    return "Good";
  }
  if (score > 70) {
    return "Average";
  }
  if (score > 50) {
    return "Below Average";
  }
  return "Bad";
}

function ColorFromScore(score: number): string {
  if (score > 90) {
    return "#2DB574";
  }
  if (score > 80) {
    return "#2DB574";
  }
  if (score > 70) {
    return "#e1aa4c";
  }
  if (score > 50) {
    return "#e75252";
  }
  return "#e75252";
}

const DemandScore: FC<DemandScoreProps> = ({ title, tooltip, subtitleStatus, value }) => (
  <Box
    sx={{
      px: 2,
      py: 1.5,
      borderRadius: 1.5,
      display: "flex",
      bgcolor: "black.main",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Box sx={{ mr: 1 }}>
      <Typography sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        {title}
        <Tooltip
          classes={{ tooltip: "dark", arrow: "dark" }}
          placement="bottom"
          arrow
          sx={{ fontSize: "16px" }}
          title={tooltip}
          enterTouchDelay={0}
          leaveTouchDelay={10000}
        >
          <Box sx={{ height: 20, ml: 1 }}>
            <CustomIcon icon="info" sx={{ svg: { width: 20, height: 20 } }} />
          </Box>
        </Tooltip>
      </Typography>
      <Box lineHeight="22px">
        {value !== undefined && (
          <Typography variant="body2" sx={{ color: ColorFromScore(value) }} display="inline">
            {subtitleStatus}
            &nbsp;
          </Typography>
        )}
      </Box>
    </Box>

    <Box
      sx={{
        fontSize: 24,
        py: 1,
        px: 1.5,
        borderRadius: 1,
        minWidth: "66px",
        textAlign: "center",
        ml: "auto",
      }}
    >
      {value}
    </Box>
  </Box>
);

interface DemandScoresProps {
  data?: DemandAssessment;
}

const emptyDataTooltip = "Not enough data to generate demand score for this asset.";
export const DemandScores: FC<DemandScoresProps> = ({ data }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 1,
      flex: 1,
      minWidth: "60%",
      maxWidth: "70%",
    }}
  >
    <DemandScore
      title="Market Activity"
      subtitleStatus={data && StateFromScore(data.demandVolumeScore)}
      tooltip={
        data
          ? "Based on metrics of spot and derivatives trading volume, with greater weight for derivatives"
          : emptyDataTooltip
      }
      value={data?.demandVolumeScore}
    />

    <DemandScore
      title="Price Volatility"
      subtitleStatus={data && StateFromScore(data.priceChangeScore)}
      tooltip={
        data ? "Based on price volatility compared to the average volatility of Bitcoin and Ethereum" : emptyDataTooltip
      }
      value={data?.priceChangeScore}
    />
  </Box>
);
