import { Paper, Box, Typography, HistogramChart } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { palette } from "@frontend/ui/theme/palette";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useRiskExplorerAssetWalletsHealthHistogramQuery } from "src/pages/ccar-lending-page/generated";

type Props = {
  currentLt: number;
  recommendedLt: number;
};

const HealthDistribution = ({ currentLt, recommendedLt }: Props) => {
  const { marketId, asset } = useParams();

  const getQueryOpts = useCallback(
    (lt: number) => ({
      variables: {
        query: [
          {
            priceChangePercent: 0,
            marketsIds: [marketId!],
            chains: null,
            tokenSymbol: asset!,
            isRawSymbol: true,
            liquidationThreshold: lt * 100,
          },
        ],
      },
    }),
    [asset, marketId],
  );

  const { loading: isLoadingCurrent, data: currentData } = useRiskExplorerAssetWalletsHealthHistogramQuery(
    getQueryOpts(currentLt),
  );
  const { loading: isLoadingRecommended, data: recommendedData } = useRiskExplorerAssetWalletsHealthHistogramQuery(
    getQueryOpts(recommendedLt),
  );

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="h3">Health Distribution</Typography>
      <Box display="flex" gap={2}>
        {["current", "recommended"].map((type) => {
          const isCurrent = type === "current";
          const loading = isCurrent ? isLoadingCurrent : isLoadingRecommended;
          const data = isCurrent ? currentData : recommendedData;
          const color = isCurrent ? palette.orange : palette.aqua;
          const barColor = color.main;
          const badgeColor = color.opacity50;

          return (
            <Paper
              key={type}
              variant="widget"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                flex: 1,
                width: 0,
              }}
            >
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="h3">Health Distribution</Typography>
                <Box px={1} bgcolor={badgeColor} borderRadius={1}>
                  <Typography variant="body1">{isCurrent ? "Current" : "Recommended"}</Typography>
                </Box>
              </Box>
              <Box height={380}>
                {loading ? (
                  <ShimmerLoader />
                ) : (
                  data && (
                    <HistogramChart
                      name="Health Score"
                      bins={data.riskExplorerAssetWalletsHealthHistogram.map((bin) => ({
                        start: bin.bucket.start,
                        end: bin.bucket.end,
                        value: bin.numberOfWallets,
                      }))}
                      yAxisOptions={{
                        label: "Number of Wallets",
                      }}
                      barColor={barColor}
                    />
                  )
                )}
              </Box>
            </Paper>
          );
        })}
      </Box>
    </Paper>
  );
};

export default HealthDistribution;
