import { useParams } from "react-router-dom";
import { Box } from "@frontend/ui";
import { usePriceFeedsContext } from "../../context/price-feeds-context";
import {
  Header,
  ProvidersSelect,
  PriceHistoryChart,
  LatencyAnalysisSection,
  ExchangeSection,
  DeviationSection,
  GrangerCausalitySection,
} from "./components";
import ProvidersSelectProvider from "../../context/providers-select-context";

const PriceFeedDetails = () => {
  const { feedId } = useParams();
  const { getFeedById } = usePriceFeedsContext();
  const feed = getFeedById?.(feedId!);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Header feed={feed} />
      <ProvidersSelectProvider providers={feed?.providers}>
        <ProvidersSelect />
        <PriceHistoryChart />
        <LatencyAnalysisSection />
        <ExchangeSection />
        <DeviationSection />
        <GrangerCausalitySection />
      </ProvidersSelectProvider>
    </Box>
  );
};

export default PriceFeedDetails;
