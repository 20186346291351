import { Box, CustomIcon, Typography } from "@frontend/ui";
import VolumesHistoryChart from "./volumes-history-chart";
import ExchangesVwapChart from "./exchanges-vwap-chart";
import { useProvidersSelectContext } from "../../../../context/providers-select-context";

const ExchangeSection = () => {
  const { selectedProviders } = useProvidersSelectContext();

  if (!selectedProviders?.some((p) => p.type === "exchange")) return null;

  return (
    <>
      <Box mt={3}>
        <Box display="flex" gap={1} alignItems="center">
          <CustomIcon icon="exchange" />
          <Typography variant="h3">Exchange Overview</Typography>
        </Box>
        <Typography>Cross-exchange comparison and consolidated metrics</Typography>
      </Box>
      <VolumesHistoryChart />
      <ExchangesVwapChart />
    </>
  );
};

export default ExchangeSection;
