import { CryptoIcon, Typography } from "@frontend/ui";
import { useProvidersSelectContext } from "../../../context/providers-select-context";

const BenchmarkLabel = ({ color }: { color?: string }) => {
  const { selectedBaselineProvider } = useProvidersSelectContext();
  const { name, display_name: displayName } = selectedBaselineProvider || {};

  return (
    <Typography variant="caption" color={color}>
      Benchmark Oracle:
      {name && <CryptoIcon size="small" icon={name} sx={{ pl: 1 }} />} {displayName}
    </Typography>
  );
};

export default BenchmarkLabel;
