import { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@frontend/ui";
import { SxProps } from "@mui/material";

type Props = {
  description: string;
};
const textCollapsedStyle: SxProps = {
  lineClamp: "3",
  display: "-webkit-box",
  WebkitLineClamp: "3",
  WebkitBoxOrient: "vertical",
};

const Description = ({ description }: Props) => {
  const textWrapperRef = useRef<HTMLInputElement>(null);
  const [showViewMore, setShowViewMore] = useState(false);
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(true);
  useEffect(() => {
    const { current: textWrapper } = textWrapperRef;
    if (textWrapper) {
      setShowViewMore(textWrapper.offsetHeight < textWrapper.scrollHeight);
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box ref={textWrapperRef} overflow="hidden">
        <Typography
          variant="h6"
          dangerouslySetInnerHTML={{ __html: description }}
          sx={{
            a: { color: "primary.main" },
            ...(descriptionCollapsed ? textCollapsedStyle : {}),
          }}
          textOverflow="ellipsis"
        />
      </Box>
      {showViewMore && (
        <Box>
          <Button color="secondary" onClick={() => setDescriptionCollapsed((prev) => !prev)}>
            View {descriptionCollapsed ? "More" : "Less"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Description;
