import { Box, CustomIcon, Paper, Typography } from "@frontend/ui";
import DeviationChart from "./deviation-from-price-index-chart";
import DeviationDistributionChart from "./deviation-distribution-chart";
import DeviationTable from "./deviation-table";

const DeviationSection = () => (
  <>
    <Box mt={3}>
      <Box display="flex" gap={1} alignItems="center">
        <CustomIcon icon="deviation" />
        <Typography variant="h3">Deviation</Typography>
      </Box>
      <Typography>Comprehensive review of provider deviations from benchmark oracle</Typography>
    </Box>
    <Paper variant="card">
      <DeviationChart />
    </Paper>
    <DeviationDistributionChart />
    <DeviationTable />
  </>
);

export default DeviationSection;
