import { FC } from "react";
import { Slice, VictoryContainer, VictoryPie } from "victory";
import { colorScaleWarm } from "@frontend/ui/utils/color-scale";
import { palette } from "@frontend/ui/theme/palette";
import { Typography } from "@frontend/ui/typography";
import { Box } from "@frontend/ui/box";
import { ChartData } from "../types";

interface CustomSliceProps {
  datum?: {
    y: number;
    background: boolean;
  };
  sliceStartAngle?: number;
  sliceEndAngle?: number;
}
const valueToColor = (value: { y: number }) => {
  if (!value) return palette.darkGrey.main;

  if (value.y > 90) {
    return "#2DB574";
  }
  if (value.y > 80) {
    return "#2DB574";
  }
  if (value.y > 70) {
    return "#e1aa4c";
  }
  if (value.y > 50) {
    return "#e75252";
  }
  if (value.y > 0) {
    return "#e75252";
  }

  return palette.darkGrey.main;
};

export const CustomSlice: FC<CustomSliceProps> = ({ datum, sliceStartAngle, sliceEndAngle, ...props }) => (
  <Slice
    {...props}
    cornerRadius={6}
    sliceStartAngle={datum?.background ? 0 : sliceStartAngle}
    sliceEndAngle={datum?.background ? 360 : sliceEndAngle}
    // @ts-expect-error style prop actually exists
    style={{ fill: datum.background ? palette.black.main : valueToColor(datum) }}
  />
);

export interface AssetPieChartProps {
  data: ChartData[];
  colors?: string[];
  title: string;
  titleValue?: string | number;
  isLabelsHidden?: boolean;
  labelsPosition?: "side" | "bottom";
  chartHeight?: number;
  isCornersRounded?: boolean;
}

export const AssetPieChart: FC<AssetPieChartProps> = ({
  data,
  colors,
  title,
  titleValue,
  isLabelsHidden,
  chartHeight,
  labelsPosition = "side",
  isCornersRounded,
}) => {
  const colorScale = colors || colorScaleWarm;
  const isLabelsOnSide = labelsPosition === "side";
  const height = chartHeight || 248;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: isLabelsOnSide ? "row" : "column" },
        gap: 3,
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box width="100%">
          <VictoryPie
            data={data}
            width={height}
            height={height}
            padding={0}
            innerRadius={chartHeight ? chartHeight / 3 : 80}
            labelRadius={chartHeight ? chartHeight / 3 : 80}
            colorScale={colorScale}
            style={{ labels: { display: "none" } }}
            containerComponent={<VictoryContainer responsive />}
            dataComponent={isCornersRounded ? <CustomSlice /> : undefined}
          />
        </Box>
        <Box
          sx={{
            fontSize: {
              xs: 18,
              md: 20,
              lg: 24,
            },
            color: "#fff",
            fontFamily: "Archivo, sans-serif",
            position: "absolute",
            top: "50%",
            width: "100%",
            px: "30px",
            textAlign: "center",
            transform: "translateY(-50%)",
          }}
        >
          {`${titleValue || data[0].y}%`}
          <Box
            sx={{
              fontSize: {
                xs: 11,
                md: 14,
              },
              color: "#fff",
            }}
          >
            {title}
          </Box>
        </Box>
      </Box>

      {!isLabelsHidden && (
        <Box
          sx={{
            display: "flex",
            maxWidth: 280,
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          {data.map((item, i) => (
            <Box key={item.x} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  backgroundColor: colorScale[i % colorScale.length],
                }}
              />
              <Typography>{item.x}</Typography>
              <Typography>{item.y}%</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
