import { OptionDataValue } from "./types";
import { formatAxis, formatPercent } from "../../../utils/formatters";

export const valueFormatter = (isPercent?: boolean) => (value: OptionDataValue | OptionDataValue[]) => {
  if (typeof value !== "string" && typeof value !== "number") return value.toString();

  if (isPercent) {
    return formatPercent(Number(value), { maximumFractionDigits: 2, minimumFractionDigits: 0 });
  }

  return formatAxis(value);
};

export const getFractionDigitsByDiff = (firstNum: number, secondNum: number) => {
  const firstLength = firstNum.toString().length;
  const secondLength = secondNum.toString().length;
  const diff = Math.abs(secondNum - firstNum);
  const diffLength = diff.toString().length;

  if (firstLength !== secondLength && diff > 1) return 0;

  const fractionDigits = Math.abs(firstLength - diffLength);

  return Math.min(4, fractionDigits);
};
