import ECharts from "../../echarts";
import { getHistogramOptions } from "./get-histogram-chart-option";
import { HistogramChartProps } from "./types";

type Props = HistogramChartProps & {
  emptyState?: boolean;
};

const HistogramChart = ({ emptyState, ...restProps }: Props) => (
  <ECharts emptyState={emptyState} option={getHistogramOptions(restProps)} />
);

export default HistogramChart;
