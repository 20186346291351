import { PageHeader } from "@frontend/ui/page-header";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { ValueCardProps } from "@frontend/ui/value-card";
import { MultiValuesCard } from "src/components/multi-values-card";
import { AssetBreadcrumb } from "src/pages/parameter-recommendations-page/components/asset-breadcrumb";
import { useMemo } from "react";
import { useClientConfig } from "../../clients-config";
import { ProposedAssetData } from "../../generated";

type Props = {
  proposedAssetData: ProposedAssetData;
};

const AssetPageHeader = ({ proposedAssetData }: Props) => {
  const { mainPage } = useClientConfig();

  const { chain, name, assetSymbol, priceUsd, marketCapUsd, priceChangePercentage24h, marketCapChangePercentage24h } =
    proposedAssetData;

  const cardValues: ValueCardProps[] = [
    {
      title: "Token Price",
      value: priceUsd || undefined,
      delta: priceChangePercentage24h ? priceChangePercentage24h / 100 : undefined,
      deltaColor: priceChangePercentage24h || undefined,
      deltaTrend: Math.sign(priceChangePercentage24h || 0),
      currency: "USD",
      isDeltaPercent: true,
    },
    {
      title: "Market Cap",
      value: marketCapUsd || undefined,
      delta: marketCapChangePercentage24h ? marketCapChangePercentage24h / 100 : undefined,
      deltaColor: marketCapChangePercentage24h || undefined,
      deltaTrend: Math.sign(marketCapChangePercentage24h || 0),
      currency: "USD",
      isDeltaPercent: true,
    },
  ];

  const breadcrumbsLinks = mainPage
    ? [
        {
          title: mainPage.name,
          href: mainPage.path,
        },
      ]
    : undefined;

  const breadcrumbTitle = useMemo(
    () => <AssetBreadcrumb market={chain || undefined} asset={assetSymbol} isActive />,
    [chain, assetSymbol],
  );

  return (
    <PageHeader
      pageTitle={name || assetSymbol}
      breadcrumbsLinks={breadcrumbsLinks}
      breadcrumbTitle={breadcrumbTitle}
      icon={wrappedSymbolToIconSymbol(assetSymbol)}
      containerStyle={{ mt: 0, mb: 2 }}
      suffixComponent={<MultiValuesCard values={cardValues} />}
    />
  );
};

export default AssetPageHeader;
