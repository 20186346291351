import { AssetRecommendation } from "../../generated";
import {
  ParamRecommendationsTable as ParamRecTable,
  ParamRecommendationType,
} from "../../../../components/param-recommendations-table";

type Props = {
  recommendations: AssetRecommendation[];
};

const ParamRecommendationsTable = ({ recommendations }: Props) => {
  const data: ParamRecommendationType[] = recommendations.map(({ proposedValue, ...rest }) => ({
    currentValue: proposedValue,
    ...rest,
  }));

  return <ParamRecTable data={data} beforeChangeColTitle="Proposed" />;
};

export default ParamRecommendationsTable;
