import { Header, RenderData } from "@frontend/types";
import { ChaosTable } from "@frontend/ui/chaos-table";
import { Loader } from "@frontend/ui/loader";
import { extractNumberFromString } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useAlpAssetsTableData } from "src/hooks/use-alp-assets-table-data";
import { formatAssetRevenueEstimation } from "src/pages/asset-page/revenue-estimation/revenue-estimation";
import { ListingStatus } from "../../generated";

const HEADERS: Header[] = [
  {
    renderType: "TEXT",
    text: "Asset",
  },
  {
    renderType: "TEXT",
    text: "Status",
  },
  {
    renderType: "TEXT",
    text: "Volume 24h",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Market Cap",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Revenue Estimation",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Demand Score",
  },
  {
    renderType: "TEXT",
    text: "Risk Score",
  },
];

const AssetListingTable = () => {
  const { clientName } = useParams<"clientName">();
  const { assetsTableData, isLoading } = useAlpAssetsTableData();

  if (isLoading || !assetsTableData) return <Loader />;

  const handleRowClick = (rowId: number) => {
    const asset = assetsTableData[rowId];

    if (!asset) return "";

    const { chain, assetSymbol } = asset;

    const assetPathParam = chain ? `${chain}-${assetSymbol}` : assetSymbol;

    return RoutePath.AssetListing.Asset.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.MarketAsset,
      assetPathParam,
    );
  };

  const tableData: RenderData[][] = assetsTableData.map((asset) => {
    const showRevenueEstimation = asset.revenue && asset?.listingStatus !== ListingStatus.Listed;
    const revenueEstimationValue = showRevenueEstimation ? asset.revenue || 0 : 0;

    return [
      {
        renderType: "TEXT",
        text: asset.name,
        token1: asset.assetSymbol,
      },
      {
        renderType: "CHIP",
        text: asset.listingStatus,
      },
      {
        renderType: "TEXT",
        text: asset.volume,
        value: extractNumberFromString(asset.volume),
      },
      {
        renderType: "TEXT",
        text: asset.marketCap,
        value: extractNumberFromString(asset.marketCap),
      },
      {
        renderType: "TEXT",
        text: showRevenueEstimation ? formatAssetRevenueEstimation(asset.revenue) : "-",
        value: revenueEstimationValue,
      },
      {
        renderType: "PIE_CHART",
        text: "N/A",
        value: asset.demandScore || 0,
      },
      {
        renderType: "PIE_CHART",
        text: "N/A",
        value: asset.riskScore || 0,
      },
    ] as RenderData[];
  });

  return (
    <ChaosTable
      title="Assets"
      headers={HEADERS}
      data={tableData}
      isSettingsHidden
      rowHref={handleRowClick}
      initialSortBy={1}
      isInitialSortDesc
      pageSize={assetsTableData.length}
      showSearch
      isFullHeight
    />
  );
};

export default AssetListingTable;
