import { FC, useMemo } from "react";
import ReactSelect, { MultiValue } from "react-select";
import { useMediaQuery, Theme } from "../theme";
import { InputLabel } from "../input-label";
import { FormHelperText } from "../form-helper-text";
import { FormControl } from "../form-control";
import { customReactSelectComponents, customMultiReactSelectComponents } from "./config";
import { FormControlProps, CustomReactSelectProps, CustomMultiReactSelectProps, MultiOptionType } from "./types";
import { styles } from "./styles";
import { Tooltip } from "../tooltip";

const SelectFormControl: FC<FormControlProps> = ({
  tooltip,
  label,
  helperText,
  error,
  disabled,
  fullWidth,
  sx,
  children,
}) => (
  <FormControl
    {...{
      error,
      disabled,
      fullWidth,
      sx,
    }}
  >
    {label && (
      <Tooltip title={tooltip || ""} placement="right-end">
        <InputLabel sx={{ opacity: disabled ? 0.3 : 1, width: "fit-content" }}>{label}</InputLabel>
      </Tooltip>
    )}
    {children}
    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControl>
);

export const CustomReactSelect: FC<CustomReactSelectProps> = ({ error, variant, disabled, ...props }) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <SelectFormControl {...props} disabled={disabled} error={error}>
      <ReactSelect
        styles={styles({ error, variant, isMobile })}
        isDisabled={disabled}
        components={customReactSelectComponents}
        {...props}
      />
    </SelectFormControl>
  );
};

const SELECT_ALL_VALUE = "*";

export const CustomMultiReactSelect: FC<CustomMultiReactSelectProps> = ({
  controlShouldRenderValue,
  disabled,
  error,
  variant,
  btnProps,
  deleteItemBtnProps,
  allowSelectAll,
  onChange,
  options,
  value,
  selectAllLabel,
  ...props
}) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const values = value as MultiValue<MultiOptionType> | undefined;
  const selectedValues = (values || []).map((v) => v?.value);
  const selectAllOption = useMemo(
    () => ({ label: selectAllLabel || "Select All", value: SELECT_ALL_VALUE, bold: true, customIcon: "collab" }),
    [selectAllLabel],
  );

  const isSelectAllSelected =
    !!allowSelectAll && values?.length === options?.filter((opt) => !(opt as MultiOptionType).groupValues)?.length;
  const isOptionSelected = (option: MultiOptionType) => {
    const isOptionInValues = selectedValues.some((v) => v === option.value);
    const isGroupOptionSelected = option?.groupValues?.every((v) => selectedValues.includes(v));

    return isOptionInValues || isGroupOptionSelected || isSelectAllSelected;
  };

  const components = useMemo(
    () =>
      customMultiReactSelectComponents({
        btnProps,
        deleteItemBtnProps,
      }),
    [btnProps, deleteItemBtnProps],
  );

  return (
    <SelectFormControl {...props} disabled={disabled} error={error}>
      <ReactSelect
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={controlShouldRenderValue || false}
        isClearable={false}
        isDisabled={disabled}
        backspaceRemovesValue={false}
        tabSelectsValue={false}
        styles={styles({ error, variant, isMobile })}
        components={components}
        isOptionSelected={allowSelectAll ? isOptionSelected : undefined}
        options={allowSelectAll ? [selectAllOption, ...(options || [])] : options}
        value={values}
        onChange={(selected, actionMeta) => {
          const { action, option, removedValue } = actionMeta;
          const isSelectAllOption = option?.value === selectAllOption.value;
          const isGroupOption = !!option?.groupValues;

          if (action === "select-option" && isSelectAllOption) {
            return onChange?.((options as MultiOptionType[]) || [], actionMeta);
          }

          if (action === "select-option" && isGroupOption) {
            const groupOptions = (options as MultiOptionType[])?.filter(
              (opt) => option?.groupValues?.includes(opt.value) || selectedValues.includes(opt.value),
            );
            return onChange?.(groupOptions || [], actionMeta);
          }

          if (
            (action === "deselect-option" && isSelectAllOption) ||
            (action === "remove-value" && removedValue.value === selectAllOption.value)
          ) {
            return onChange?.([], actionMeta);
          }

          if (action === "deselect-option" && isGroupOption) {
            const selectedOptionsWithoutGroup = (options as MultiOptionType[])?.filter(
              (opt) => selectedValues.includes(opt.value) && !option?.groupValues?.includes(opt.value),
            );
            return onChange?.(selectedOptionsWithoutGroup, actionMeta);
          }

          return onChange?.(selected, actionMeta);
        }}
        {...props}
      />
    </SelectFormControl>
  );
};
