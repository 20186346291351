import { ObserverValueType } from "@frontend/types";
import { HistogramBinType } from "@frontend/ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useClientConfig } from "../clients-config";
import { GetPublicSimulationsData } from "../hooks/use-public-simulations-data";
import {
  GetExperimentRepetitionsObserverQuery,
  GetCurrentAndRecommendedExperimentObserverQuery,
  ExperimentRepetitionsObserverResponse,
} from "../public-simulations-types";
import { PublicSimulationsType } from "../types";

const getExperimentObserver = (
  publicSimulationsType: PublicSimulationsType,
  query: GetExperimentRepetitionsObserverQuery,
) => GetPublicSimulationsData("experiment/repetitions/observer", publicSimulationsType, query);

const getCurrentAndRecommendedExperimentsObserver = (
  publicSimulationsType: PublicSimulationsType,
  query: GetCurrentAndRecommendedExperimentObserverQuery,
) => GetPublicSimulationsData("simulation/experiments/observer", publicSimulationsType, query);

type ObserverValues = {
  values: number[];
  histogramDistributionData: HistogramBinType[];
  numberOfRepetitions: number;
};

export type ObserverData = {
  id: string;
  name: string;
  description?: string;
  valueType?: ObserverValueType;
  currencyCode?: string;
  observerValues: ObserverValues;
};

const parseResponseToObserverData = (
  observerId: string,
  response?: ExperimentRepetitionsObserverResponse,
): ObserverData => {
  const observerValues: ObserverValues = {
    values: response?.values || [],
    histogramDistributionData: response?.histogramDistributionData || [],
    numberOfRepetitions: response?.numberOfRepetitions || 0,
  };

  const observerData: ObserverData = {
    id: observerId,
    name: response?.name || "",
    description: response?.description,
    valueType: response?.valueType,
    currencyCode: response?.currencyCode,
    observerValues,
  };

  return observerData;
};

export const getExperimentObserverData = async (
  publicSimulationsType: PublicSimulationsType,
  observerId: string,
  simulationResultId: string,
  permutationId: string,
  histogramIncludeZeros?: boolean,
) => {
  const data = await getExperimentObserver(publicSimulationsType, {
    simulationId: simulationResultId,
    experimentId: permutationId,
    observerId,
    histogramIncludeZeros,
  });

  const observerData: ObserverData = parseResponseToObserverData(observerId, data);

  return observerData;
};

export const getCurrentAndRecommendedExperimentsObserverData = async (
  publicSimulationsType: PublicSimulationsType,
  observerId: string,
  simulationResultId: string,
  currentPermutationId: string,
  recommendedPermutationId: string,
  histogramIncludeZeros?: boolean,
) => {
  const data = await getCurrentAndRecommendedExperimentsObserver(publicSimulationsType, {
    simulationId: simulationResultId,
    currentExperimentId: currentPermutationId,
    recommendedExperimentId: recommendedPermutationId,
    observerId,
    histogramIncludeZeros,
  });

  const { currentObserver, recommendedObserver } = data || {};

  const currentObserverData: ObserverData = parseResponseToObserverData(observerId, currentObserver);
  const recommendedObserverData: ObserverData = parseResponseToObserverData(observerId, recommendedObserver);

  return { currentObserverData, recommendedObserverData };
};

export const useExperimentObserverData = (
  observerId: string | undefined,
  simulationResultId: string,
  permutationId: string,
  histogramIncludeZeros?: boolean,
): {
  isLoading: boolean;
  observerValues: ObserverData | undefined;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [observerValues, setObserverValues] = useState<ObserverData | undefined>(undefined);
  const { publicSimulationsType } = useClientConfig();

  useEffect(() => {
    let mounted = true;
    void (async () => {
      if (!observerId || !permutationId) return;
      setIsLoading(true);
      const experimentObserverValues = await getExperimentObserverData(
        publicSimulationsType,
        observerId,
        simulationResultId,
        permutationId,
        histogramIncludeZeros,
      );
      if (mounted) {
        setObserverValues(experimentObserverValues);
        setIsLoading(false);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [publicSimulationsType, observerId, simulationResultId, permutationId, histogramIncludeZeros]);

  return { isLoading, observerValues };
};

export const useCurrentAndRecommendedExperimentsObserverData = (
  observerId: string,
  simulationResultId: string,
  currentPermutationId: string,
  recommendedPermutationId: string,
  histogramIncludeZeros?: boolean,
): {
  isLoading: boolean;
  data:
    | {
        currentObserverData: ObserverData;
        recommendedObserverData: ObserverData;
      }
    | undefined;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<
    | {
        currentObserverData: ObserverData;
        recommendedObserverData: ObserverData;
      }
    | undefined
    | undefined
  >(undefined);
  const { pythonSimulationResultId } = useParams<{ pythonSimulationResultId: string }>();
  const { publicSimulationsType } = useClientConfig();

  useEffect(() => {
    let mounted = true;
    void (async () => {
      if (!observerId || !currentPermutationId || !recommendedPermutationId) return;
      setIsLoading(true);
      const observersData = await getCurrentAndRecommendedExperimentsObserverData(
        publicSimulationsType,
        observerId,
        pythonSimulationResultId || simulationResultId,
        currentPermutationId,
        recommendedPermutationId,
        histogramIncludeZeros,
      );
      if (mounted) {
        setData(observersData);
        setIsLoading(false);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [
    publicSimulationsType,
    observerId,
    simulationResultId,
    currentPermutationId,
    recommendedPermutationId,
    histogramIncludeZeros,
    pythonSimulationResultId,
  ]);

  return { isLoading, data };
};
