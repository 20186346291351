import { Header, RenderData } from "@frontend/types";
import { ChaosTable } from "@frontend/ui/chaos-table";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { parseMarketString } from "../../utils";

export type RewardTableRowData = {
  chain: string;
  market?: string;
  marketName?: string;
  tokenSymbol: string;
  amount: string;
  amountUsd: number;
  status: "claimed" | "unclaimed";
};

type Props = {
  data: RewardTableRowData[];
  isMultichain: boolean;
  showChain?: boolean;
  metadata?: string;
};

const ROW_HEIGHT = 72;

const WalletRewardsRowView = ({ data, isMultichain, metadata, showChain }: Props) => {
  const headers: Header[] = [
    ...(showChain
      ? [
          {
            renderType: "TEXT",
            text: "Chain",
          } as Header,
        ]
      : []),
    ...(isMultichain
      ? [
          {
            renderType: "TEXT",
            text: "Market",
          } as Header,
        ]
      : []),
    {
      renderType: "TEXT",
      text: "Asset",
    },
    {
      renderType: "TEXT",
      text: "Amount (Token)",
    },
    {
      renderType: "TEXT",
      text: "Amount (USD)",
      prefix: "$",
    },
    {
      renderType: "TEXT",
      text: "Status",
    },
  ];

  const tableData: RenderData[][] = data.map((reward: RewardTableRowData) => {
    const { chain } = reward;
    const marketAsset = reward.market ? parseMarketString(reward.market).asset : null;

    const marketComponenTooltipAndIcons = {
      ...(marketAsset
        ? {
            tooltipText: `${wrappedSymbolToIconSymbol(marketAsset).toLocaleUpperCase()} | ${chain}`,
            token1: marketAsset,
            token2: chain,
          }
        : {
            text: chain,
            token1: chain,
          }),
    };

    return [
      ...(showChain
        ? [
            {
              renderType: "TEXT",
              text: reward.chain ?? "",
              token1: reward.chain,
            },
          ]
        : []),
      ...(isMultichain
        ? [
            {
              renderType: "TEXT",
              text: reward.marketName ?? "",
              ...(reward.marketName ? {} : marketComponenTooltipAndIcons),
            },
          ]
        : []),
      {
        renderType: "TEXT",
        text: reward.tokenSymbol,
        icon: wrappedSymbolToIconSymbol(reward.tokenSymbol),
      },
      {
        renderType: "TEXT",
        text: formatAmount(parseFloat(reward.amount) || 0, { maximumFractionDigits: 2 }),
        tooltipText: formatAmount(parseFloat(reward.amount) || 0, { notation: "standard" }),
        value: parseFloat(reward.amount) || 0,
      },
      {
        renderType: "TEXT",
        text: formatAmount(reward.amountUsd || 0, { currency: "USD", maximumFractionDigits: 2 }),
        tooltipText: formatAmount(reward.amountUsd || 0, { currency: "USD", notation: "standard" }),
        value: Number(reward.amountUsd) || 0,
      },
      {
        renderType: "LABELS",
        text: reward.status === "claimed" ? "Claimed" : "Unclaimed",
        width: "103px",
      },
    ] as RenderData[];
  });

  return (
    <ChaosTable
      title="Rewards"
      metadata={metadata}
      headers={headers}
      data={tableData}
      rowHeight={ROW_HEIGHT}
      tableHeight={ROW_HEIGHT * tableData.length}
      pageSize={10}
      initialSortBy={0}
      isInitialSortDesc
      emptyState={{ icon: "info", title: "No rewards to display" }}
    />
  );
};

export default WalletRewardsRowView;
