import { AssetConfig, ProposedAssetData } from "src/pages/asset-listing-portal/generated";
import { OverallRisk } from "./overall-risk";
import RevenuePotential from "./revenue-potential";

type Props = {
  proposedAssetData: ProposedAssetData;
  assetConfig: AssetConfig;
};

const AaveSection = ({ proposedAssetData, assetConfig }: Props) => {
  const { priceUsd } = proposedAssetData;
  const { recommendations } = assetConfig;
  return (
    <>
      {recommendations && priceUsd && <RevenuePotential assetRecommendations={recommendations} assetPrice={priceUsd} />}
      <OverallRisk proposedAssetData={proposedAssetData} assetConfig={assetConfig} />
    </>
  );
};

export default AaveSection;
