import { EChartsOption, SeriesOption } from "echarts";
import { echartsTooltipFormatter } from "@frontend/ui";
import { ChartLegends, ScriptResource } from "@frontend/types";
import { formatAxis } from "@frontend/ui/utils/formatters";
import { palette } from "@frontend/ui/theme/palette";

export const getObserverChartOptions = (
  series: SeriesOption[],
  legends?: ChartLegends,
  startBlock?: number,
  observerConfig?: Partial<ScriptResource>,
): EChartsOption => {
  const formatValue = (value: number) => {
    const isCurrency = observerConfig?.valueType === "currency";
    const isPercent = observerConfig?.valueType === "percent";

    return formatAxis(value, 4, isCurrency ? observerConfig?.currencyCode || "USD" : undefined, isPercent, 0);
  };

  return {
    tooltip: {
      trigger: "axis",
      formatter: echartsTooltipFormatter({
        headerFormatter: (x: string | number) =>
          `Iteration ${x.toString()}${startBlock ? ` (Block #${startBlock + Number(x)})` : ""}`,
      }),
      padding: 0,
      renderMode: "auto",
      verticalAlign: "middle",
    },
    toolbox: {
      itemSize: 12,
      top: 0,
      feature: {
        brush: {
          show: false,
        },
        dataView: {
          show: false,
        },
        dataZoom: {
          yAxisIndex: "none",
        },
        magicType: {
          show: false,
        },
        restore: {},
        saveAsImage: {
          show: false,
        },
      },
    },
    legend: {
      type: "scroll",
      data: series.map((serie) => `${serie.name || ""}`),
    },
    xAxis: {
      type: "category",
      name: "Iteration #",
      nameLocation: "middle",
      nameGap: 45,
      nameTextStyle: {
        color: palette.white.main,
        fontSize: 14,
      },
      axisLabel: {
        interval: 499,
      },
    },
    grid: {
      top: 45,
      left: legends?.left ? 30 : 10,
      right: legends?.right ? 30 : 10,
      bottom: 30,
    },
    dataZoom: {
      type: "inside",
      zoomLock: true,
    },
    brush: {
      geoIndex: "all",
    },
    yAxis: [
      {
        type: "value",
        name: legends?.left,
        nameLocation: "middle",
        nameGap: 60,
        nameTextStyle: {
          color: palette.white.main,
        },
        axisLabel: {
          formatter: formatValue,
        },
      },
      {
        type: "value",
        name: legends?.right,
        nameLocation: "middle",
        nameGap: 60,
        nameTextStyle: {
          color: palette.white.main,
        },
        axisLabel: {
          formatter: formatValue,
        },
      },
    ],
    series,
  };
};
