import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, iconsCell, textCell } from "@frontend/ui";
import { usePriceFeedsContext } from "../../context/price-feeds-context";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Asset",
  },
  {
    renderType: "TEXT",
    text: "Price",
    tooltip: "The median USD market price of the asset aggregated from all our supported providers",
  },
  {
    renderType: "TEXT",
    text: "Providers",
    tooltip: "The data providers contributing to the asset's price information",
  },
];

const PriceFeeds = () => {
  const { priceFeeds, isLoading } = usePriceFeedsContext();

  const data: RenderData[][] = priceFeeds.map((feed) => {
    const sortedProvider = [...feed.providers].sort((a, b) => a.type.localeCompare(b.type));
    return [
      textCell(`${feed.asset} / USD`, undefined, feed.asset),
      currencyCell(feed.price, { maximumFractionDigits: feed.price < 1 ? 10 : undefined }),
      iconsCell(
        sortedProvider.map((p) => p.display_name),
        undefined,
        sortedProvider
          .map((p) => p.display_name)
          .slice(3)
          .join(", "),
        "more",
        sortedProvider.reduce(
          (acc, p) => ({ ...acc, [p.display_name]: p.name.includes("uniswap") ? "uniswap" : p.name }),
          {},
        ),
      ),
    ];
  });

  const onClickRow = (rowId: number) => {
    const feed = priceFeeds[rowId];

    return feed?.asset || "";
  };

  return (
    <ChaosTable
      title="Price Feeds"
      headers={headers}
      data={data}
      rowHref={onClickRow}
      isLoading={isLoading}
      isFullHeight
      initialSortBy={1}
      isInitialSortDesc
    />
  );
};

export default PriceFeeds;
