import { Box, CustomIcon, Typography } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { Link, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";

const SimulationResultsSectionHeader = () => {
  const { clientName } = useParams();

  return (
    <Box>
      <Typography variant="h2">LT/LB Simulation Results</Typography>
      <Typography>
        Chaos Labs runs thousands of agent-based blockchain simulations with different market conditions to discover the
        optimal set of parameters for minimizing the risk and maximizing protocol profit. We use the Chaos Utility Ratio
        to balance risk and profit in an optimal way.
        <Link
          target="_blank"
          to={RoutePath.ParameterRecommendations.Methodology.replace(RouteParams.ClientName, clientName!)}
          style={{ textDecoration: "none", display: "inline-block" }}
        >
          <Box display="inline-flex" gap={1} alignItems="center" sx={{ verticalAlign: "bottom" }}>
            <CustomIcon icon="external-link" sx={{ svg: { path: { fill: palette.turqouise.main } } }} />
            <Typography variant="h5" color="turqouise.main">
              Read more about our Methodology
            </Typography>
          </Box>
        </Link>
      </Typography>
    </Box>
  );
};

export default SimulationResultsSectionHeader;
