import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CustomReactSelect, Paper, Typography, ValueOverTimeChart } from "@frontend/ui";
import { TimeSpanPicker } from "src/pages/ccar-lending-page/components/time-span-picker";
import { Endpoints } from "src/pages/oracle-portal/api";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { AggregationType, Deviation, aggregationTypes } from "../../../../types";
import {
  PriceFeedTimeSpan,
  priceFeedTimeSpanOptions,
  useProvidersSelectContext,
} from "../../../../context/providers-select-context";
import { useFeedData } from "../../use-price-feed-details";
import BenchmarkLabel from "../benchmark-label";

const DeviationChart = () => {
  const { feedId } = useParams();
  const [selectedAggregation, setSelectedAggregation] = useState<AggregationType>("avg");
  const {
    response: deviationFromBaseline,
    isLoading: isLoadingDeviationFromBaseline,
    timeSpan: deviationFromBaselineTimeSpan,
    setTimeSpan: deviationFromBaselineSetTimeSpan,
  } = useFeedData(Endpoints.PriceFeedDeviationFromBaseline);
  const { providerNameById } = useProvidersSelectContext();

  const series = (deviationFromBaseline || [])
    .sort((a, b) => (a.type < b.type ? 1 : -1))
    .reduce((acc: Record<string, NumberChartSeries>, point: Deviation) => {
      const pointId = `${point.provider}-${point.type}`;
      const currentSeriesData = acc[pointId]?.data || [];
      const newSeries: NumberChartSeries = {
        id: pointId,
        label: providerNameById?.[point.id] || point.provider,
        data: [...currentSeriesData, [point.timestamp * 1000, point[selectedAggregation] / 100]] as [number, number][],
        type: "line",
      };
      return { ...acc, [pointId]: newSeries };
    }, {});

  const aggregationOptions = aggregationTypes.map((t) => ({
    value: t,
    label: capitalizeFirstLetter(t),
  }));

  return (
    <Paper variant="widget" sx={{ flex: 1, display: "flex", height: "100%" }}>
      <Box flex={1} width={0} height="100%">
        <ValueOverTimeChart
          key={`deviation-chart-${Object.keys(series).join("-")}`}
          title="Benchmark Price Deviation Comparison"
          description={
            <Box>
              <Typography>Price deviation of {feedId!} across providers relative to benchmark oracle</Typography>
              <BenchmarkLabel />
            </Box>
          }
          yAxisMax={false}
          series={Object.values(series)}
          currency=""
          isPercent
          zoomable
          timeSpan={deviationFromBaselineTimeSpan}
          yAxisLabel="Deviation(%)"
          isLoading={isLoadingDeviationFromBaseline}
          suffix={
            <Box display="flex" flexDirection="column" gap={1}>
              <TimeSpanPicker<PriceFeedTimeSpan>
                selectedTimeSpan={deviationFromBaselineTimeSpan}
                onSelectTimeSpan={deviationFromBaselineSetTimeSpan}
                timeSpanOptions={priceFeedTimeSpanOptions}
                hideLabel
              />
              <CustomReactSelect
                options={aggregationOptions}
                value={aggregationOptions.filter((opt) => opt.value === selectedAggregation)}
                onChange={(opt) => {
                  if (!opt) return;
                  setSelectedAggregation(opt.value as AggregationType);
                }}
                isSearchable={false}
              />
            </Box>
          }
        />
      </Box>
    </Paper>
  );
};

export default DeviationChart;
