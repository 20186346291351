import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useAuth } from "@frontend/ui/auth";
import { RouteParams, RoutePath } from "../../../config/routes";
import { replaceHomePath } from "./utils";
import { OverviewTab, EcosystemPerformanceTab, UserEngagementTab } from "../tabs";
import { renderTabsLayout } from "./layout";
import { ChaosApolloProvider } from "../../../utils/graphql";
import { FeatureFlagsProvider } from "../../../utils/feature-flags";
import { useClientConfig } from "../clients-config";
import { NotPermittedError } from "../../../components/not-permitted-error";

const ChainAnalyticsRoutes = () => {
  const { clientName } = useParams();
  const defaultRoute = RoutePath.Risk.Overview.replace(RouteParams.ClientName, clientName!);
  const { role } = useClientConfig();
  const auth = useAuth();

  if (!auth.hasRole(role)) {
    return <NotPermittedError />;
  }

  return (
    <ChaosApolloProvider product="chain-analytics">
      <FeatureFlagsProvider>
        <Routes>
          <Route index element={<Navigate to={defaultRoute} replace />} />
          <Route path={replaceHomePath(RoutePath.Risk.Overview)} element={renderTabsLayout(<OverviewTab />)} />
          <Route
            path={replaceHomePath(RoutePath.Risk.EcosystemPerformance)}
            element={renderTabsLayout(<EcosystemPerformanceTab />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.UserEngagement)}
            element={renderTabsLayout(<UserEngagementTab />)}
          />
          <Route path="*" element={<Navigate to={defaultRoute} replace />} />
        </Routes>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
};

export default ChainAnalyticsRoutes;
