import { Box, Paper, Typography, Link, OptionType, CustomReactSelect, CustomSwitch } from "@frontend/ui";
import { HistogramChart } from "@frontend/ui/echarts";
import { useEffect, useState } from "react";
import { palette } from "@frontend/ui/theme/palette";
import { Link as RouterLink, useParams } from "react-router-dom";
import { experimentTypes } from "src/pages/parameter-recommendations-page/public-simulations-types";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { useClientConfig } from "src/pages/parameter-recommendations-page/clients-config";
import { useCurrentAndRecommendedExperimentsObserverData } from "src/pages/parameter-recommendations-page/asset-page/use-experiment-observers-data";
import { RouteParams, RoutePath } from "src/config/routes";
import { useAssetRecommendations } from "../../../../use-asset-recommendations";

type Props = {
  simulationResultId: string;
  currentStateExperimentId: string;
  recommendedStateExperimentId: string;
  observerDropdownOptions: OptionType[];
};
const ObservationsDistributionHistograms = ({
  simulationResultId,
  currentStateExperimentId,
  recommendedStateExperimentId,
  observerDropdownOptions,
}: Props) => {
  const { isUnderReview } = useAssetRecommendations();
  const { distributionHistogramIncludeZerosDefault } = useClientConfig();
  const [includeZeros, setIncludeZeros] = useState(false);

  useEffect(() => {
    setIncludeZeros(!!distributionHistogramIncludeZerosDefault);
  }, [distributionHistogramIncludeZerosDefault]);

  const { clientName, marketId, asset } = useParams();

  const [selectedObserverOption, setSelectedObserverOption] = useState(observerDropdownOptions[0]);

  const { isLoading: isLoadingSelectedObserver, data: selectedObserverData } =
    useCurrentAndRecommendedExperimentsObserverData(
      selectedObserverOption.value,
      simulationResultId,
      currentStateExperimentId,
      recommendedStateExperimentId,
      includeZeros,
    );

  const { currentObserverData, recommendedObserverData } = selectedObserverData || {};
  const selectedObserverMaxDistributionValue: number = [
    ...(currentObserverData?.observerValues?.histogramDistributionData || []),
    ...(recommendedObserverData?.observerValues?.histogramDistributionData || []),
  ].reduce((max, bin) => Math.max(max, bin.value), 0);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h3">Observations Distribution</Typography>
        </Box>
        <Box display="flex" gap={3} alignItems="center">
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="h5">Show 0 Values</Typography>
            <CustomSwitch checked={includeZeros} onChange={(e) => setIncludeZeros(e.target.checked)} />
          </Box>
          <CustomReactSelect
            placeholder="Observer"
            value={selectedObserverOption}
            options={observerDropdownOptions.filter((option) => option.value !== selectedObserverOption.value)}
            onChange={(selected) => {
              if (!selected) return;
              setSelectedObserverOption(selected);
            }}
          />
        </Box>
      </Box>

      <Box display="flex" gap={2}>
        {experimentTypes.map((type) => {
          const isCurrent = type === "current";
          const observerData = selectedObserverData?.[isCurrent ? "currentObserverData" : "recommendedObserverData"];
          const observerValues = observerData?.observerValues;
          const color = isCurrent ? palette.orange : palette.aqua;
          const barColor = color.main;
          const badgeColor = color.opacity50;

          return (
            <Paper
              key={type}
              variant="widget"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                flex: 1,
                width: 0,
              }}
            >
              <Box>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography variant="h3">{selectedObserverOption.label}</Typography>
                  <Box px={1} bgcolor={badgeColor} borderRadius={1}>
                    <Typography variant="body1">{isCurrent ? "Current" : "Recommended"}</Typography>
                  </Box>
                  <Link
                    component={RouterLink}
                    to={RoutePath.Risk[isCurrent ? "ExperimentResultsCurrent" : "ExperimentResultsRecommended"](
                      isUnderReview,
                    )
                      .replace(RouteParams.ClientName, clientName!)
                      .replace(RouteParams.MarketId, marketId!)
                      .replace(RouteParams.Asset, asset!)}
                    underline="none"
                    sx={{ ml: "auto" }}
                  >
                    View Results
                  </Link>
                </Box>
                <Typography variant="body1">
                  Showing the distribution of values observed when running simulations with different market conditions
                </Typography>
              </Box>
              <Box height={380}>
                {isLoadingSelectedObserver && <ShimmerLoader />}
                {!isLoadingSelectedObserver && selectedObserverData && observerValues && (
                  <HistogramChart
                    emptyState={!observerValues.histogramDistributionData.length}
                    name={observerData.name}
                    bins={observerValues.histogramDistributionData}
                    totalSamples={observerValues.numberOfRepetitions}
                    barColor={barColor}
                    yAxisOptions={{
                      isPercent: true,
                      maxValue: selectedObserverMaxDistributionValue,
                      label: "% of Simulations",
                    }}
                    xAxisOptions={{
                      isCurrency: observerData.valueType === "currency",
                      currencyCode: observerData.currencyCode,
                    }}
                    isZerosFiltered={!includeZeros}
                  />
                )}
              </Box>
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};

export default ObservationsDistributionHistograms;
