import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useRiskExplorerLiquidatedWalletsQuery } from "../../../../../../../generated";
import { WalletsAtRiskTable as WalletsAtRiskTableComp } from "../../../../../../../../../components/wallets-at-risk-table";

type Props = {
  newLT: number;
};

const WalletsAtRiskTable = ({ newLT }: Props) => {
  const { marketId, asset } = useParams();

  const variables = useMemo(
    () => ({
      query: [
        {
          chains: null,
          marketsIds: [marketId!],
          priceChangePercent: 0,
          tokenSymbol: asset!,
          isRawSymbol: true,
          liquidationThreshold: newLT * 100,
        },
      ],
      becameEligibleOnly: true,
    }),
    [asset, marketId, newLT],
  );

  const { data, loading } = useRiskExplorerLiquidatedWalletsQuery({ variables });

  return (
    <WalletsAtRiskTableComp
      wallets={data?.riskExplorerLiquidatedWallets || []}
      description={`Wallets that will be eligible for liquidation after reducing Liquidation Threshold to ${
        newLT * 100
      }%`}
      isLoading={loading}
      emptyState={{
        icon: "check-circle",
        title: "No Wallets at Risk",
      }}
    />
  );
};

export default WalletsAtRiskTable;
