import {
  MetricStatistics,
  BlockchainSimulationResult,
  BlockchainSimulationRunResult,
  AggregationName,
  ObserverValueType,
} from "@frontend/types";
import { HistogramBinType } from "@frontend/ui/echarts";
import { SimulationParamType } from "./types";

export type PublicSimulationsEndpoint =
  | "simulation_result"
  | "experiment/aggregation"
  | "experiment/repetitions"
  | "experiment/confidence_interval"
  | "experiment/repetitions/observer"
  | "simulation/experiments/observer"
  | "simulation"
  | "";

export type ObservationResult = {
  id: string;
  name: string;
  result: number[];
};

export type GetSimulationResultQuery = {
  simulationResultId: string;
};

export type SimulationResultResponse = BlockchainSimulationResult & {
  result: BlockchainSimulationRunResult & { observations: ObservationResult[] };
};

export type ExperimentMetricStatistics = MetricStatistics & {
  name: string;
  description?: string;
  confidenceIntervalOverTime: string;
  repetitions: number;
  valueType?: ObserverValueType;
  currencyCode?: string;
};

export type GetExperimentAggregationQuery = {
  simulationId: string;
  experimentId: string;
};
export type ExperimentAggregationResponse = ExperimentMetricStatistics[];

export type RepetitionResult = {
  id: string;
  observations: {
    id: string;
    name: string;
    value: number;
    valueType?: ObserverValueType;
    currencyCode?: string;
  }[];
};

export type GetExperimentRepetitionsQuery = {
  simulationId: string;
  experimentId: string;
  lastBatchResult?: string;
};
export type ExperimentRepetitionsPageResponse = {
  permutations?: RepetitionResult[];
  lastBatchResult?: string;
};

export type GetExperimentRepetitionsObserverQuery = {
  simulationId: string;
  experimentId: string;
  observerId: string;
  histogramIncludeZeros?: boolean;
};

export type ExperimentRepetitionsObserverResponse = {
  values: number[];
  aggregationMethod: AggregationName;
  histogramDistributionData: HistogramBinType[];
  numberOfRepetitions: number;
  name?: string;
  description?: string;
  valueType?: ObserverValueType;
  currencyCode?: string;
};

export type GetCurrentAndRecommendedExperimentObserverQuery = {
  simulationId: string;
  currentExperimentId: string;
  recommendedExperimentId: string;
  observerId: string;
  histogramIncludeZeros?: boolean;
};

export type CurrentAndRecommendedExperimentObserverResponse = {
  currentObserver: ExperimentRepetitionsObserverResponse;
  recommendedObserver: ExperimentRepetitionsObserverResponse;
};

export type GetSimulationQuery = {
  market?: string;
  asset?: string;
  simulationId?: string;
};

export const aaveRecommendationOutcomeNames = [
  "aaveUtility",
  "borrowUsage",
  "valueAtRisk",
  "extremeValueAtRisk",
] as const;
export type RecommendationOutcomeNamesType = (typeof aaveRecommendationOutcomeNames)[number];

export type PermutationResult = {
  arguments: Record<SimulationParamType, number>;
  result: Record<RecommendationOutcomeNamesType, number> & {
    debtAgainstAsset?: number;
  };
};
export type AssetCap = {
  current: number;
  recommended?: number;
};

export type AssetCapsResult = {
  supply?: AssetCap;
  borrow: AssetCap;
};

export type VisibilityState = "Published" | "Pending" | "Outdated";
export type SimulationResponse = {
  asset: string;
  market?: string;
  id: string;
  currentStateExperimentId: string;
  recommendedStateExperimentId: string;
  currentState: PermutationResult;
  optimalPermutation?: PermutationResult;
  permutationsResults: PermutationResult[];
  visibilityState?: VisibilityState;
  caps?: AssetCapsResult;
};

export type PublicSimulationItem = Omit<SimulationResponse, "permutationsResults">;
export type GetPublicSimulationsQuery = {
  states?: VisibilityState[];
  market?: string;
};
export type GetPublicSimulationsResponse = PublicSimulationItem[];

export const experimentTypes = ["current", "recommended"] as const;
export type ExperimentType = (typeof experimentTypes)[number];

export type PublicSimulationsQuery<T extends PublicSimulationsEndpoint> = T extends "simulation_result"
  ? GetSimulationResultQuery
  : T extends "experiment/aggregation"
    ? GetExperimentAggregationQuery
    : T extends "experiment/repetitions"
      ? GetExperimentRepetitionsQuery
      : T extends "experiment/repetitions/observer"
        ? GetExperimentRepetitionsObserverQuery
        : T extends "simulation/experiments/observer"
          ? GetCurrentAndRecommendedExperimentObserverQuery
          : T extends "simulation"
            ? GetSimulationQuery
            : GetPublicSimulationsQuery;

export type PublicSimulationsResponse<T extends PublicSimulationsEndpoint> = T extends "simulation_result"
  ? SimulationResultResponse
  : T extends "experiment/aggregation"
    ? ExperimentAggregationResponse
    : T extends "experiment/repetitions"
      ? ExperimentRepetitionsPageResponse
      : T extends "experiment/repetitions/observer"
        ? ExperimentRepetitionsObserverResponse
        : T extends "simulation/experiments/observer"
          ? CurrentAndRecommendedExperimentObserverResponse
          : T extends "simulation"
            ? SimulationResponse
            : GetPublicSimulationsResponse;

export type PublicSimulationsDataOptions<T extends PublicSimulationsEndpoint = ""> = {
  endpoint: T;
  query: PublicSimulationsQuery<T>;
  sendRequest?: boolean;
};
