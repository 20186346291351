import { useParams } from "react-router-dom";
import { OverviewCard, Client, OverviewChart, MarketsCard, MarketsChart, NotificationsChannelConfig } from "./types";
import { Chain } from "./generated";

type CCARPerpetualsClientConfig = {
  clientName: string;
  isSynthetic?: boolean;
  role?: string;
  hourlyHistorySupport?: boolean;
  network: {
    name: Chain;
    id: string;
  };
  accounts?: {
    accountAnalysis?: boolean;
    analysisOperationsThreshold?: number;
  };
  overview?: {
    title: string;
    subtitle: string;
    cards: OverviewCard[];
    charts: OverviewChart[];
    useVolumeCardFromMarkets?: boolean;
    hourlySupport?: boolean;
  };
  markets?: {
    cards: MarketsCard[];
    hideDailyChange?: boolean;
    charts: MarketsChart[];
    hasGmPools?: boolean;
    borrowRateName?: string;
    hasDailyVolume?: boolean;
    hasNextFundingRate?: boolean;
    hasDailyTrades?: boolean;
    hasFeesDistribution?: boolean;
    priceCardFromCoingecko?: boolean;
    priceImpactDistribution?: boolean;
  };
  alerts?: {
    enabled: boolean;
    notificationsChannels?: NotificationsChannelConfig[];
  };
  liquidations?: {
    showBlockNumber?: boolean;
    hideAddress?: boolean;
    hideTxHash?: boolean;
    hideLeverage?: boolean;
    isLiquidationsInMarketToken?: boolean;
  };
  riskExplorer?: {
    enabled: boolean;
  };
  positions: {
    accountDetailsUrl?: string;
    showClosedPositions?: boolean;
    showPositionKeyColumn?: boolean;
    showPositionsAnalysis?: boolean;
    hasSubAccounts?: boolean;
  };
  positionDetails?: {
    enabled: boolean;
  };
  leaderboard?: {
    enabled: boolean;
  };
  jlpPool?: {
    enabled: boolean;
  };
  hasSharedCollateral: boolean;
  combineOpenInterest: boolean;
};

export const clientsConfig: Record<Client, CCARPerpetualsClientConfig> = {
  "gmx-avalanche": {
    clientName: "gmx-avalanche",
    network: { name: Chain.Avalanche, id: "avalanche" },
    overview: {
      title: "GMX Avalanche",
      subtitle: "Protocol health metrics",
      cards: ["glp-pool", "fees", "trading-volume", "open-interest"],
      charts: ["pool-value", "pool-composition", "daily-fees", "oi-imbalance"],
    },
    positions: {
      showClosedPositions: true,
    },
    markets: {
      cards: ["interestImbalance"],
      charts: ["assetPrice", "averageLeverage"],
    },
    alerts: {
      enabled: true,
      notificationsChannels: [
        {
          channel: "telegram",
          link: "https://t.me/+rP-FLqn0i9IzOTFh",
        },
      ],
    },
    hasSharedCollateral: false,
    combineOpenInterest: false,
  },
  "gmx-v2-avalanche": {
    clientName: "gmx-v2-avalanche",
    isSynthetic: true,
    hourlyHistorySupport: true,
    accounts: {
      accountAnalysis: true,
      analysisOperationsThreshold: 10,
    },
    network: { name: Chain.Avalanche, id: "avalanche" },
    markets: {
      cards: ["interestImbalance"],
      charts: ["assetPrice", "averageLeverage"],
      hasGmPools: true,
      hasFeesDistribution: true,
    },
    overview: {
      title: "GMX V2 Avalanche",
      subtitle: "Protocol health metrics",
      cards: ["tvl", "fees", "trading-volume", "open-interest"],
      charts: ["daily-fees", "tvl", "oi-imbalance"],
    },
    positions: {
      showClosedPositions: true,
      showPositionsAnalysis: true,
    },
    alerts: {
      enabled: true,
      notificationsChannels: [
        {
          channel: "telegram",
          link: "https://t.me/+rP-FLqn0i9IzOTFh",
        },
      ],
    },
    hasSharedCollateral: false,
    combineOpenInterest: false,
  },
  "gmx-arbitrum": {
    clientName: "gmx-arbitrum",
    network: { name: Chain.Arbitrum, id: "arbitrum" },
    overview: {
      title: "GMX Arbitrum",
      subtitle: "Protocol health metrics",
      cards: ["glp-pool", "fees", "trading-volume", "open-interest"],
      charts: ["pool-value", "pool-composition", "daily-fees", "oi-imbalance"],
    },
    positions: {
      showClosedPositions: true,
    },
    markets: {
      cards: ["interestImbalance"],
      charts: ["assetPrice", "averageLeverage"],
    },
    alerts: {
      enabled: true,
      notificationsChannels: [
        {
          channel: "telegram",
          link: "https://t.me/+rP-FLqn0i9IzOTFh",
        },
      ],
    },
    hasSharedCollateral: false,
    combineOpenInterest: false,
  },
  "gmx-v2-arbitrum": {
    clientName: "gmx-v2-arbitrum",
    isSynthetic: true,
    hourlyHistorySupport: true,
    accounts: {
      accountAnalysis: true,
      analysisOperationsThreshold: 10,
    },
    network: { name: Chain.Arbitrum, id: "arbitrum" },
    markets: {
      cards: ["interestImbalance"],
      charts: ["assetPrice", "averageLeverage"],
      hasGmPools: true,
      hasFeesDistribution: true,
    },
    positions: {
      showClosedPositions: true,
      showPositionsAnalysis: true,
    },
    overview: {
      title: "GMX V2 Arbitrum",
      subtitle: "Protocol health metrics",
      cards: ["tvl", "fees", "trading-volume", "open-interest"],
      charts: ["daily-fees", "tvl", "oi-imbalance"],
    },
    alerts: {
      enabled: true,
      notificationsChannels: [
        {
          channel: "telegram",
          link: "https://t.me/+rP-FLqn0i9IzOTFh",
        },
      ],
    },
    hasSharedCollateral: false,
    combineOpenInterest: false,
  },
  "dydx-v4": {
    clientName: "dydx-v4",
    network: { name: Chain.DYdXv4, id: "dYdXV4" },
    markets: {
      cards: ["dailyVolume", "fundingRate", "24HTrades"],
      charts: ["order-book", "assetPrice"],
      hasDailyTrades: true,
      hasDailyVolume: true,
      hasNextFundingRate: true,
    },
    overview: {
      title: "dYdX Chain",
      subtitle: "Protocol health metrics",
      cards: ["tvl", "trading-volume", "daily-trades", "open-interest"],
      charts: ["marketsOpenInterest", "fundingRate", "aggOrders"],
    },
    alerts: {
      enabled: true,
      notificationsChannels: [
        {
          channel: "telegram",
          link: "https://t.me/+XZDoGvIWHlpiZWJh",
        },
      ],
    },
    liquidations: {
      showBlockNumber: true,
    },
    positions: {
      accountDetailsUrl: "https://www.mintscan.io/dydx/address/",
      hasSubAccounts: true,
    },
    leaderboard: {
      enabled: true,
    },
    hasSharedCollateral: true,
    combineOpenInterest: true,
  },
  "ostium-testnet": {
    clientName: "ostium-testnet",
    network: { name: Chain.ArbitrumSepolia, id: "arbitrum-sepolia" },
    hourlyHistorySupport: true,
    markets: {
      cards: ["borrowRate", "sideFundingRate", "interestImbalance"],
      charts: ["assetPrice", "averageLeverage"],
      borrowRateName: "Rollover Fee 1H",
    },
    positions: {
      showClosedPositions: true,
    },
    accounts: {},
    overview: {
      title: "Ostium Testnet",
      subtitle: "Protocol health metrics",
      cards: ["trading-volume", "open-interest"],
      charts: ["tvl", "realizedPnL", "oi-imbalance"],
      hourlySupport: true,
    },
    alerts: {
      enabled: true,
      notificationsChannels: [
        {
          channel: "telegram",
          link: "https://t.me/+4KchZZkhMj00YzYx",
        },
      ],
    },
    hasSharedCollateral: false,
    combineOpenInterest: false,
  },
  ostium: {
    clientName: "ostium",
    network: { name: Chain.Arbitrum, id: "arbitrum" },
    hourlyHistorySupport: true,
    markets: {
      cards: ["borrowRate", "sideFundingRate", "interestImbalance"],
      charts: ["assetPrice", "averageLeverage"],
      borrowRateName: "Rollover Fee 1H",
    },
    positions: {
      showClosedPositions: true,
    },
    accounts: {},
    overview: {
      title: "Ostium",
      subtitle: "Protocol health metrics",
      cards: ["trading-volume", "open-interest"],
      charts: ["tvl", "realizedPnL", "oi-imbalance"],
      hourlySupport: true,
    },
    alerts: {
      enabled: true,
      notificationsChannels: [
        {
          channel: "telegram",
          link: "https://t.me/+4KchZZkhMj00YzYx",
        },
      ],
    },
    hasSharedCollateral: false,
    combineOpenInterest: false,
  },
  bluefin: {
    clientName: "bluefin",
    network: { name: Chain.Arbitrum, id: "arbitrum" },
    markets: {
      cards: ["dailyVolume", "fundingRate", "24HTrades"],
      charts: ["order-book", "assetPrice", "averageLeverage"],
      hasDailyVolume: true,
    },
    positions: {
      showClosedPositions: true,
    },
    liquidations: {
      hideAddress: true,
      hideTxHash: true,
      hideLeverage: true,
    },
    overview: {
      title: "Bluefin",
      subtitle: "Protocol health metrics",
      cards: ["tvl", "trading-volume", "open-interest"],
      charts: ["marketsOpenInterest", "tvl", "fundingRate"],
    },
    alerts: {
      enabled: true,
    },
    hasSharedCollateral: true,
    combineOpenInterest: true,
  },
  nftperp: {
    clientName: "nftperp",
    network: { name: Chain.Arbitrum, id: "nftperp" },
    markets: {
      cards: ["dailyVolume", "fundingRate"],
      charts: ["order-book"],
      hasDailyVolume: true,
    },
    positions: {
      showClosedPositions: true,
    },
    liquidations: {
      hideTxHash: true,
      hideLeverage: true,
    },
    overview: {
      title: "Nftperp",
      subtitle: "Protocol health metrics",
      cards: ["trading-volume", "open-interest"],
      charts: ["marketsOpenInterest", "fundingRate"],
    },
    alerts: {
      enabled: true,
    },
    hasSharedCollateral: true,
    combineOpenInterest: false,
  },
  jupiter: {
    clientName: "jupiter",
    network: { name: Chain.Solana, id: "jupiter" },
    accounts: {
      accountAnalysis: true,
      analysisOperationsThreshold: 10,
    },
    markets: {
      cards: ["dailyVolume", "interestImbalance"],
      hideDailyChange: true,
      charts: ["assetPrice"],
      hasDailyVolume: true,
      priceCardFromCoingecko: true,
      priceImpactDistribution: true,
    },
    positions: {
      showClosedPositions: true,
      accountDetailsUrl: "https://solscan.io/account/",
      showPositionKeyColumn: true,
    },
    positionDetails: {
      enabled: true,
    },
    liquidations: {
      isLiquidationsInMarketToken: true,
    },
    overview: {
      title: "Jupiter",
      subtitle: "Protocol health metrics",
      cards: ["trading-volume", "open-interest", "oi-imbalance", "open-positions"],
      charts: ["realizedPnL", "uniqueUsers"],
      useVolumeCardFromMarkets: true,
    },
    alerts: {
      enabled: true,
      notificationsChannels: [
        {
          channel: "telegram",
          link: "https://t.me/+E2i_UxCRvIFhZmVh",
        },
      ],
    },
    jlpPool: {
      enabled: true,
    },
    hasSharedCollateral: false,
    combineOpenInterest: false,
  },
  synfutures: {
    clientName: "synfutures",
    network: { name: Chain.Blast, id: "blast" },
    accounts: {
      accountAnalysis: true,
      analysisOperationsThreshold: 10,
    },
    markets: {
      cards: ["dailyVolume", "interestImbalance"],
      charts: ["assetPrice", "averageLeverage"],
      hasDailyVolume: true,
    },
    positions: {
      showClosedPositions: true,
    },
    liquidations: {},
    overview: {
      title: "SynFutures",
      subtitle: "Protocol health metrics",
      cards: ["trading-volume", "open-interest", "oi-imbalance", "open-positions", "oi-imbalance"],
      charts: [],
    },
    alerts: {
      enabled: false,
    },
    hasSharedCollateral: false,
    combineOpenInterest: false,
  },
};

export const useClientConfig = (): CCARPerpetualsClientConfig => {
  const { clientName } = useParams<{ clientName: Client }>();
  return clientsConfig[clientName!];
};
