import { echartsTooltipFormatter } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { formatAxis } from "@frontend/ui/utils/formatters";
import { EChartsOption } from "echarts";
import { ObserverData } from "../../../use-experiment-observers-data";

const formatAxisValue = (value: number, observerData: ObserverData) =>
  formatAxis(
    value,
    4,
    observerData.valueType === "currency" ? observerData?.currencyCode || "USD" : undefined,
    false,
    0,
  );

export const getObserversPairScatterChartOptions = (
  firstObserver: ObserverData,
  secondObserver: ObserverData,
  color?: string,
  maxYAxisValue?: number,
): EChartsOption => ({
  tooltip: {
    formatter: echartsTooltipFormatter(),
    padding: 0,
    renderMode: "auto",
    verticalAlign: "middle",
    trigger: "item",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    left: 50,
    right: 15,
    bottom: 40,
  },
  dataZoom: {
    type: "inside",
    zoomLock: true,
  },
  xAxis: {
    name: secondObserver.name,
    nameLocation: "middle",
    nameGap: 50,
    type: "value",
    nameTextStyle: {
      color: palette.white.main,
    },
    min: "dataMin",
    axisLabel: {
      formatter: (value: number) => formatAxisValue(value, secondObserver),
    },
  },
  yAxis: {
    name: firstObserver.name,
    nameLocation: "middle",
    nameGap: 60,
    type: "value",
    nameTextStyle: {
      color: palette.white.main,
    },
    min: 0,
    max: maxYAxisValue,
    axisLabel: {
      formatter: (value: number) => formatAxisValue(value, firstObserver),
    },
  },
  series: {
    symbolSize: 10,
    data: (secondObserver?.observerValues?.values || []).map((value, idx) => [
      value,
      (firstObserver?.observerValues?.values || [])[idx],
    ]),
    type: "scatter",
    color,
    dimensions: [secondObserver.name, firstObserver.name],
  },
});
