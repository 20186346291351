import { Paper, CompositionOverTimeChart, CustomSwitch } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { useState } from "react";
import { HistoryDataFragment } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  isLoading: boolean;
  realizedPnL?: HistoryDataFragment[];
};

const getChartProps = (
  realizedPnL: HistoryDataFragment[] = [],
  showAggregatedPnL = false,
): { series: NumberChartSeries[] } => ({
  series: showAggregatedPnL
    ? [
        {
          label: "Aggregated",
          type: "line",
          data: [...realizedPnL]
            .sort((a, b) => a.time - b.time)
            .reduce<[number, number][]>((acc, s, i) => [...acc, [s.time, (acc[i - 1]?.[1] || 0) + s.value]], []),
          highlightTrends: true,
        },
      ]
    : [
        {
          label: "Realized PnL",
          type: "bar",
          data: realizedPnL.map((s) => [s.time, s.value]),
          highlightTrends: true,
        },
      ],
});

export const RealizedPnLChart = ({ isLoading, realizedPnL = [] }: Props) => {
  const [showAggregatedPnL, setShowAggregatedPnL] = useState(true);
  const { series } = getChartProps(realizedPnL, showAggregatedPnL);

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        title="Realized PnL"
        description={`${showAggregatedPnL ? "Aggregated" : "Daily"} realized profits or losses by traders over time`}
        isLoading={isLoading}
        hideTotal
        series={series}
        showLegend={false}
        headerSuffix={
          <CustomSwitch
            onChange={() => setShowAggregatedPnL(!showAggregatedPnL)}
            checked={!showAggregatedPnL}
            checkedLabel="Daily"
            uncheckedLabel="Aggregated"
          />
        }
      />
    </Paper>
  );
};
