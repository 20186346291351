import { palette } from "@frontend/ui/theme/palette";
import { Box } from "@mui/material";

export enum Rating {
  "A+" = 0,
  "A" = 1,
  "A-" = 2,
  "B+" = 3,
  "B" = 4,
  "B-" = 5,
  "C+" = 6,
  "C" = 7,
  "C-" = 8,
  "D+" = 9,
  "D" = 10,
  "D-" = 11,
}

const getColorByRating = (rating: Rating) => {
  if (rating <= Rating["B-"]) {
    return palette.green.opacity50;
  }

  if (rating <= Rating["C-"]) {
    return palette.orange.opacity50;
  }

  return palette.red.opacity50;
};

type ScoreBadgeProps = {
  rating: Rating;
};

const ScoreBadge = ({ rating }: ScoreBadgeProps) => (
  <Box borderRadius={1} px={1} width="fit-content" bgcolor={getColorByRating(rating)}>
    {Rating[rating]}
  </Box>
);

export default ScoreBadge;
