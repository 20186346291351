import { useEffect, useMemo, useState } from "react";
import { Box, Typography, Paper, CustomSwitch } from "@frontend/ui";
import { CustomReactSelect, OptionType } from "@frontend/ui/custom-select";

import { useExperimentObserverData } from "src/pages/parameter-recommendations-page/asset-page/use-experiment-observers-data";
import { GetExperimentAggregationQuery } from "src/pages/parameter-recommendations-page/public-simulations-types";
import { useClientConfig } from "src/pages/parameter-recommendations-page/clients-config";
import { usePublicSimulationsData } from "src/pages/parameter-recommendations-page/hooks/use-public-simulations-data";

import MetricScatterPlot from "./metric-scatter-plot";
import MetricDistribution from "./metric-distribution";

type Props = {
  simulationResultId: string;
  experimentId: string;
};

const ObservationsDistribution = ({ simulationResultId, experimentId }: Props) => {
  const { distributionHistogramIncludeZerosDefault } = useClientConfig();
  const [histogramIncludeZeros, setHistogramIncludeZeros] = useState(false);
  useEffect(() => {
    setHistogramIncludeZeros(!!distributionHistogramIncludeZerosDefault);
  }, [distributionHistogramIncludeZerosDefault]);

  const experimentAggregationQuery: GetExperimentAggregationQuery = useMemo(
    () => ({
      simulationId: simulationResultId,
      experimentId,
    }),
    [simulationResultId, experimentId],
  );

  const { response: experimentAggregation } = usePublicSimulationsData({
    endpoint: "experiment/aggregation",
    query: experimentAggregationQuery,
  });

  const observersOptions = useMemo(
    () =>
      (experimentAggregation || []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [experimentAggregation],
  );

  const [selectedObserverOption, setSelectedObserverOption] = useState<OptionType>();

  useEffect(() => {
    setSelectedObserverOption(observersOptions[0]);
  }, [observersOptions]);

  const { isLoading: isLoadingSelectedObserver, observerValues: selectedObserverData } = useExperimentObserverData(
    selectedObserverOption?.value,
    simulationResultId,
    experimentId,
    histogramIncludeZeros,
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={3}>
          <Box>
            <Typography variant="h3">Observations Distribution</Typography>
            <Typography>
              Showing the distribution of values observed when running simulations with different market conditions
            </Typography>
          </Box>
          <Box display="flex" gap={3} alignItems="center">
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="h5">Show 0 Values</Typography>
              <CustomSwitch
                checked={histogramIncludeZeros}
                onChange={(e) => setHistogramIncludeZeros(e.target.checked)}
              />
            </Box>
            <CustomReactSelect
              placeholder="Observer"
              value={selectedObserverOption}
              options={observersOptions}
              onChange={(selected) => {
                if (!selected) return;
                setSelectedObserverOption(selected);
              }}
            />
          </Box>
        </Box>
        <Paper variant="widget" sx={{ height: 496 }}>
          <MetricDistribution
            isLoading={isLoadingSelectedObserver}
            selectedObserverData={selectedObserverData}
            selectedObserverOption={selectedObserverOption}
            isZerosFiltered={!histogramIncludeZeros}
          />
        </Paper>
      </Paper>
      <Paper variant="card">
        <MetricScatterPlot
          simulationResultId={simulationResultId}
          permutationId={experimentId}
          observersOptions={observersOptions}
        />
      </Paper>
    </Box>
  );
};

export default ObservationsDistribution;
