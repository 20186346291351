import { Box, ECharts, Paper, Typography } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { useMinBurnChartOption } from "./use-mint-burn-chart-options";
import { useStablecoinDataContext } from "../../provider";

type Props = {
  hasPaper?: boolean;
};

export const StablecoinMintBurnChart = ({ hasPaper = true }: Props) => {
  const option = useMinBurnChartOption();
  const { tokenSymbol } = useStablecoinDataContext();

  const content = (
    <Paper variant="widget" data-testid="change-per-day-chart">
      <Box mb={2}>
        <Typography variant="h4">{tokenSymbol} Change Per Day</Typography>
        <Typography>
          Showing the total amount of mint and redeem events each day. <br /> In the case of minting and redeeming a
          position within the same day, both are counted.
        </Typography>
      </Box>
      <Box height={325}>{option ? <ECharts option={option} /> : <ShimmerLoader />}</Box>
    </Paper>
  );

  if (hasPaper) {
    return <Paper variant="card">{content}</Paper>;
  }

  return content;
};
