import { Box, ExternalLinkButton } from "@frontend/ui";
import { AssetLinks } from "../../generated";

type Props = {
  links?: AssetLinks | null;
  proposalLink?: string | null;
};

const Links = ({ links, proposalLink }: Props) => {
  const { homepage, twitter, github } = links || {};
  return (
    <Box display="flex" gap={1}>
      {homepage && <ExternalLinkButton text="Website" link={homepage} icon="web" />}
      {twitter && <ExternalLinkButton text="Twitter" link={twitter} icon="twitter-filled" />}
      {github && <ExternalLinkButton text="Github" link={github} icon="github" />}
      {proposalLink && <ExternalLinkButton text="Proposal" link={proposalLink} cryptoIcon="aave" />}
    </Box>
  );
};

export default Links;
