export type Client =
  | "aave"
  | "aave-v2"
  | "benqi"
  | "radiant"
  | "tapioca"
  | "venus"
  | "crv-usd"
  | "seamless"
  | "zerolend";

export type OverviewCard =
  | "totalValueLockedUsd"
  | "numberOfWalletsAtRisk"
  | "totalValueAvailableForLiquidationUsd"
  | "totalBorrowUsd"
  | "totalSupplyUsd"
  | "totalCollateralUsd"
  | "protocolHealth"
  | "activeUsers"
  | "totalValueAtRiskUsd"
  | "numberOfWalletsAvailableForLiquidation"
  | "totalPotentialBadDebtUsd"
  | "dailyGrowth"
  | "stablecoinPrice"
  | "collateraDebtRatio";

export type OverviewChart =
  | "historyStatsTvl"
  | "chainHistoryStats"
  | "supplyAndBorrowDistribution"
  | "crossChainSupplyAndBorrowDistribution"
  | "collateralOverTime"
  | "mintHistogram"
  | "valueAtRisk"
  | "stablecoinCollateralComposition"
  | "stablecoinMintBurn"
  | "topHolders"
  | "poolsComposition"
  | "aggSupplyBorrowEvents";

export type ChainsTableColumn =
  | "chain"
  | "activeUsers"
  | "totalBorrowUsd"
  | "totalSupplyUsd"
  | "totalValueLockedUsd"
  | "totalValueAvailableForLiquidationUsd"
  | "totalValueAtRiskUsd"
  | "totalChainTvl"
  | "totalSupplyPercentChainTvl"
  | "protocolHealth";

export type WalletDetailsTable = "totalSupplyAndBorrow" | "rewards" | "recentEvents";

export type MarketDetailsTable = "wallets" | "assetEvents";

export type MarketDetailsChart =
  | "collateralAtRisk"
  | "borrowWalletsHealth"
  | "supplyWalletsHealth"
  | "marketsDistribution"
  | "marketsDistributionOverTime"
  | "marketHistory"
  | "walletsDistribution"
  | "historicalPrices"
  | "priceImpact"
  | "liquidationBands"
  | "topWallets";

export type AssetDetailsTable = "wallets" | "assetEvents";

export type AssetDetailsChart = "collateralAtRisk" | "walletsHealth" | "marketsDistribution" | "walletsDistribution";

export type StableCoinCard =
  | "price"
  | "totalMinted"
  | "totalBorrow"
  | "totalSupply"
  | "totalCollateral"
  | "collateraDebtRatio"
  | "marketCap"
  | "volume";

export type StableCoinChart =
  | "facilitators"
  | "collateralComposition"
  | "assetPriceLiquidation"
  | "mintBurn"
  | "marketCap"
  | "topTokenHolders";

export type StableCoinCollateralChart =
  | "assetBreakdown"
  | "assetClassBreakdown"
  | "aaveEMode"
  | "collateralRatio"
  | "marketBreakdown";

export type ReservesChart = "reserveDistribution" | "reserveHistory";

export enum RiskStatus {
  "BAD_DEBT" = -1,
  "ELIGIBLE_FOR_LIQUIDATION" = 0,
  "AT_RISK_FOR_LIQUIDATION" = 1,
  "NO_RISK_FOR_LIQUIDATION" = 2,
}

type NotificationsChannel = "telegram";

export type NotificationsChannelConfig = {
  channel: NotificationsChannel;
  link: string;
};

export type WalletDetailsChart = "wallet-history";
