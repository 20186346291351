import { DydxSection } from "./dydx";
import { AaveSection } from "./aave";
import { useAssetPageParams } from "../hooks/use-asset-page-params";
import { AssetConfig, ProposedAssetData } from "../../generated";

type Props = {
  proposedAssetData: ProposedAssetData;
  assetConfig: AssetConfig;
};

const CustomSectionByClient = ({ proposedAssetData, assetConfig }: Props) => {
  const { clientName } = useAssetPageParams();

  switch (clientName) {
    case "dydx":
      return <DydxSection />;
    case "aave":
      return <AaveSection proposedAssetData={proposedAssetData} assetConfig={assetConfig} />;
    default:
      return null;
  }
};

export default CustomSectionByClient;
