import { Header, RenderData } from "@frontend/types";
import {
  Box,
  ChaosTable,
  CustomSwitch,
  Typography,
  currencyCell,
  numberCell,
  progressCell,
  textCell,
  Link,
  CustomIcon,
} from "@frontend/ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ParameterModelResult, useAssetStatsQuery } from "src/pages/ccar-lending-page/generated";
import { useClientConfig } from "src/pages/parameter-recommendations-page/clients-config";
import { RouteParams, RoutePath } from "src/config/routes";
import { palette } from "@frontend/ui/theme/palette";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Type",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Current Usage",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Current Cap",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "% of Cap",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Recommended Cap",
    nonSortable: true,
  },
];

type Props = {
  borrowCap?: ParameterModelResult;
  supplyCap?: ParameterModelResult;
};

const capTableRowData = (
  price: number,
  isValuesInUSD: boolean,
  displayName: string,
  usage: number,
  currentCap: number,
  recommendedCap: number,
): RenderData[] => [
  textCell(displayName),
  isValuesInUSD ? currencyCell(usage * price) : numberCell(usage),
  isValuesInUSD ? currencyCell(currentCap * price) : numberCell(currentCap),
  progressCell(usage / currentCap),
  isValuesInUSD ? currencyCell(recommendedCap * price) : numberCell(recommendedCap),
];

const CapsDetails = ({ borrowCap, supplyCap }: Props) => {
  const { clientName, asset, marketId } = useParams();
  const [isValuesInUSD, setIsValuesInUSD] = useState(false);
  const { capsMethodologyUrl } = useClientConfig();
  const { data: assetData, loading: isLoadingAssetStats } = useAssetStatsQuery({
    variables: {
      marketId: marketId!,
      tokenSymbol: asset!,
      chain: null,
      market: null,
      marketType: null,
    },
  });

  const { price, totalBorrowToken, totalSupplyToken } = assetData?.assetStats.current || {};

  const assetPrice = price || 0;
  const currentBorrowCap = borrowCap?.current?.value || 0;
  const recommendedBorrowCap = borrowCap?.recommended?.value || 0;
  const currentSupplyCap = supplyCap?.current?.value || 0;
  const recommendedSupplyCap = supplyCap?.recommended?.value || 0;

  const data: RenderData[][] = [
    ...(borrowCap
      ? [
          capTableRowData(
            assetPrice,
            isValuesInUSD,
            borrowCap.displayName,
            totalBorrowToken || 0,
            currentBorrowCap,
            recommendedBorrowCap,
          ),
        ]
      : []),
    ...(supplyCap
      ? [
          capTableRowData(
            assetPrice,
            isValuesInUSD,
            supplyCap.displayName,
            totalSupplyToken || 0,
            currentSupplyCap,
            recommendedSupplyCap,
          ),
        ]
      : []),
  ];

  const title = supplyCap && borrowCap ? "Borrow and Supply Caps" : `${supplyCap ? "Supply" : "Borrow"} Cap`;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box>
        <Typography variant="h3">{title}</Typography>
        <Typography>
          Chaos Labs constantly monitors asset utilization as well insolvency risks to set optimal{" "}
          {supplyCap && borrowCap ? "values" : "value"} for {title}.
          <Link
            target="_blank"
            href={
              capsMethodologyUrl ||
              RoutePath.ParameterRecommendations.Methodology.replace(RouteParams.ClientName, clientName!)
            }
            sx={{ textDecoration: "none", display: "inline-block" }}
          >
            <Box display="inline-flex" gap={1} alignItems="center" sx={{ verticalAlign: "bottom" }}>
              <CustomIcon icon="external-link" sx={{ svg: { path: { fill: palette.turqouise.main } } }} />
              <Typography variant="h5" color="turqouise.main">
                Read more about our Methodology
              </Typography>
            </Box>
          </Link>
        </Typography>
      </Box>
      <ChaosTable
        isLoading={isLoadingAssetStats}
        title="Caps Details"
        description="Showing current and recommended asset caps values with their current usage information"
        headers={headers}
        data={isLoadingAssetStats ? [] : data}
        isSettingsHidden
        isFilterHidden
        headerSuffixComponent={
          <CustomSwitch
            checked={isValuesInUSD}
            onChange={(e) => setIsValuesInUSD(e.target.checked)}
            checkedIcon="dollar"
            uncheckedCryptoIcon={asset}
          />
        }
      />
    </Box>
  );
};

export default CapsDetails;
