import { useEffect, useState } from "react";
import axios from "axios";
import { dataFetchersURL } from "src/utils/data-fetchers-url";
import { useParams } from "react-router-dom";
import { useAssetsConfigQuery } from "../pages/asset-listing-portal/generated";
import { AssetTableRow, AssetTableRowData } from "../pages/asset-listing-portal/components/asset-listing-table/types";

type ReturnType = {
  isLoading: boolean;
  assetsTableData?: AssetTableRow[];
};

export const useAlpAssetsTableData = (chain?: string): ReturnType => {
  const { clientName } = useParams<"clientName">();
  const { data } = useAssetsConfigQuery({
    variables: { chain: chain || null },
  });
  const [assetsTableData, setAssetsTableData] = useState<AssetTableRow[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    void (async () => {
      setIsLoading(true);

      if (!data) return;

      const { assetsConfig } = data;

      const assetsDataUrl = `${dataFetchersURL()}data/page/asset_grid?client=${clientName!}`;
      const assetsDataResponse = await axios.get<AssetTableRowData[]>(assetsDataUrl);
      const tableData: AssetTableRow[] = assetsDataResponse.data.reduce(
        (rows: AssetTableRow[], datum: AssetTableRowData) => {
          const config = assetsConfig.find(({ coinGeckoId }) => coinGeckoId === datum.id);
          if (!config) return rows;

          const newRow: AssetTableRow = { ...datum, ...config };

          return [...rows, newRow];
        },
        [],
      );

      setAssetsTableData(tableData);
      setIsLoading(false);
    })();
  }, [data, clientName]);

  return {
    isLoading,
    assetsTableData,
  };
};
