import { Grid } from "@frontend/ui";
import { ChaosRiskScore } from "src/pages/asset-page/chaos-risk-score/chaos-risk-score";
import { ChaosDemandScore } from "src/pages/asset-page/demand-score/chaos-demand-score";
import { AssetInfo } from "../../types";

type Props = {
  assetInfo: AssetInfo;
};

const OverallRisk = ({ assetInfo }: Props) => (
  <Grid container columnSpacing={{ xs: 0, lg: 2 }} rowSpacing={{ xs: 2, lg: 0 }}>
    <Grid item xs={12} lg={6}>
      <ChaosRiskScore data={assetInfo.risk} />
    </Grid>
    <Grid item xs={12} lg={6}>
      <ChaosDemandScore data={assetInfo.demand} />
    </Grid>
  </Grid>
);

export default OverallRisk;
