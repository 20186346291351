import { Button, CryptoIcon, CustomIcon, Drawer, Grid, Typography } from "@frontend/ui";
import { formatDateAndTime } from "@frontend/ui/utils/formatters";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertFragment,
  AlertSeverity,
  AlertsQuery,
  SortOrder,
  useRiskAlertsQuery,
} from "src/pages/ccar-lending-page/generated";
import { TagChip } from "@frontend/ui/tag-chip";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { alertSeverityLabels } from "../constants";
import { getChainDisplayName } from "../utils";
import { AlertDrawerCharts } from "./alert-drawer-charts";

export const AlertDrawer = () => {
  const { alertId = "" } = useParams<"alertId">();
  const [query] = useState<AlertsQuery>({
    limit: 100,
    filter: null,
    order: SortOrder.Descending,
    skip: 0,
    sort: null,
  });
  const { data, loading } = useRiskAlertsQuery({ variables: { query } });
  const navigate = useNavigate();
  const location = useLocation();
  const alert: AlertFragment | undefined = data?.riskAlerts.find((a) => a.id === alertId);
  const [open, setOpen] = useState(false);
  const config = useClientConfig();

  let alertHref = alert?.link?.href || "";
  if (
    (!config.overview && alertHref.match("/risk/overview")) ||
    (!config.marketDetails && alertHref.match("/risk/markets")) ||
    (!config.assetDetails && alertHref.match("/risk/assets")) ||
    (!config.wallets && alertHref.match("/risk/wallets")) ||
    (!config.liquidations && alertHref.match("/risk/liquidations")) ||
    (!config.riskExplorer && alertHref.match("/risk/risk-explorer")) ||
    (!config.reserves && alertHref.match("/risk/reserves"))
  ) {
    alertHref = "";
  }

  useEffect(() => {
    if (loading) {
      return;
    }
    setOpen(true);
  }, [loading]);

  if (!alert) {
    return null;
  }

  let severityColor;
  const severityLabel = alertSeverityLabels[alert.severity];
  switch (alert.severity) {
    case AlertSeverity.High:
      severityColor = "error.opacity50";
      break;
    default:
      severityColor = "light.opacity50";
      break;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      SlideProps={{
        onExited() {
          navigate(location.pathname.replace(`/${alertId}`, ""));
        },
      }}
      onClose={() => {
        setOpen(false);
      }}
      title={alert.title}
    >
      <Grid container spacing={[2, 3]}>
        <Grid item xs={6} md={4}>
          <Typography color="almostWhite.main">Date</Typography>
          <Typography>{formatDateAndTime(dayjs.unix(alert.createdDate).toDate())}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography color="almostWhite.main">Severity</Typography>
          <Typography>
            <TagChip label={severityLabel} sx={{ bgcolor: severityColor }} />
          </Typography>
        </Grid>
        {alert.chain && (
          <Grid item xs={6} md={4}>
            <Typography color="almostWhite.main">Chains</Typography>
            <Typography>
              <CryptoIcon icon={alert.chain?.toLowerCase()} sx={{ pr: 0.5 }} />
              {getChainDisplayName(alert.chain)}
            </Typography>
          </Grid>
        )}
        {alert.assets && (
          <Grid item xs={6} md={4}>
            <Typography color="almostWhite.main">Asset</Typography>
            {alert.assets?.map((asset) => (
              <Typography key={asset}>
                <CryptoIcon icon={wrappedSymbolToIconSymbol(asset)} sx={{ pr: 0.5 }} />
                {asset}
              </Typography>
            ))}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography color="almostWhite.main">Description</Typography>
          <Typography>{alert.description}</Typography>
        </Grid>

        {!!alertHref?.length && (
          <Grid item xs={12}>
            <Button
              color="primary"
              href={alertHref}
              target="_blank"
              endIcon={<CustomIcon size="small" icon="external-link" />}
            >
              {alert?.link?.title}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <AlertDrawerCharts alert={alert} />
        </Grid>
      </Grid>
    </Drawer>
  );
};
