import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { OverviewData } from "../use-overview-data";
import { ChainHistoryValue } from "../../../generated";
import { useXAxisSplit } from "../../../chart-utils";

type Props = {
  data: OverviewData;
};

const BridgeNetFlowByChainChart = ({ data }: Props) => {
  const xAxisSplit = useXAxisSplit();

  const chartData = (data.bridgeNetFlowsByChain || []).reduce(
    (acc: Record<string, NumberChartSeries>, d: ChainHistoryValue) => {
      const isInbound = d.value > 0;
      const key = `${d.chain}-${isInbound ? "inbound" : "outbound"}`;

      const prevSeries = acc[key];
      const prevData = prevSeries?.data || [];
      const newData = [...prevData, [d.timestamp, d.value]] as [number, number][];

      const newSeries: NumberChartSeries = {
        label: d.chain,
        data: newData,
        type: "bar",
      };

      return { ...acc, [key]: newSeries };
    },
    {},
  );

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        chartHeight={325}
        title="Bridge Inflows & Outflows By Chain"
        series={Object.values(chartData)}
        currency="USD"
        isLoading={data.isLoadingBridgeNetFlowsByChain}
        sortTooltipValues
        xAxisSplitNumber={xAxisSplit}
      />
    </Paper>
  );
};

export default BridgeNetFlowByChainChart;
