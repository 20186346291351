import { daysAgoByTimeSpan } from "@frontend/ui/echarts/utils";
import { useClientConfig } from "../../clients-config";
import {
  AggregationPeriod,
  TimeSpan,
  useChainsNumberOfUsersHistoryQuery,
  ChainHistoryValue,
  useNumberOfActiveUsersQuery,
  useThirtyDaysUserTransactionCountQuery,
  ChainCategoryHistoryValue,
  useChainActiveUsersByCategoryQuery,
  useChainNewUsersHistoryQuery,
  ValuePoint,
  UsersCohort,
  useUsersMonthlyUsersRetentionRateQuery,
} from "../../generated";

export type UserEngagementData = {
  dailyActiveUsers?: ValuePoint;
  monthlyActiveUsers?: ValuePoint;
  thirtyDaysUserTransactionCount?: ValuePoint;
  chainsNumberOfUsersHistory?: ChainHistoryValue[];
  chainNewUsersHistory?: ChainHistoryValue[];
  chainActiveUsersByCategory?: ChainCategoryHistoryValue[];
  usersMonthlyRetentionRate?: UsersCohort[];
  isLoadingDailyActiveUsers: boolean;
  isLoadingMonthlyActiveUsers: boolean;
  isLoadingThirtyDaysUserTransactionCount: boolean;
  isLoadingChainsNumberOfUsersHistory: boolean;
  isLoadingChainNewUsersHistory: boolean;
  isLoadingChainActiveUsersByCategory: boolean;
  isLoadingUsersMonthlyRetentionRate: boolean;
};

export const useUserEngagementData = (isNumberOfUsersHistoryMonthly?: boolean): UserEngagementData => {
  const { chain } = useClientConfig();
  const { data: dailyActiveUsersData, loading: isLoadingDailyActiveUsers } = useNumberOfActiveUsersQuery({
    variables: {
      chain,
      period: AggregationPeriod.Daily,
    },
  });

  const { data: monthlyActiveUsersData, loading: isLoadingMonthlyActiveUsers } = useNumberOfActiveUsersQuery({
    variables: {
      chain,
      period: AggregationPeriod.Monthly,
    },
  });

  const { data: thirtyDaysUserTransactionCountData, loading: isLoadingThirtyDaysUserTransactionCount } =
    useThirtyDaysUserTransactionCountQuery({
      variables: {
        chain,
      },
    });

  const { data: chainsNumberOfUsersHistoryData, loading: isLoadingChainsNumberOfUsersHistory } =
    useChainsNumberOfUsersHistoryQuery({
      variables: {
        chains: [chain],
        timeSpan: isNumberOfUsersHistoryMonthly ? TimeSpan.Month : TimeSpan.Day,
        daysAgo: daysAgoByTimeSpan[TimeSpan.Year],
      },
    });
  const { data: chainNewUsersHistoryData, loading: isLoadingChainNewUsersHistory } = useChainNewUsersHistoryQuery({
    variables: {
      chain,
    },
  });

  const { data: chainActiveUsersByCategoryData, loading: isLoadingChainActiveUsersByCategory } =
    useChainActiveUsersByCategoryQuery({
      variables: {
        chain,
      },
    });

  const { data: usersMonthlyRetentionRateData, loading: isLoadingUsersMonthlyRetentionRate } =
    useUsersMonthlyUsersRetentionRateQuery({
      variables: {
        chain,
      },
    });

  return {
    dailyActiveUsers: dailyActiveUsersData?.numberOfActiveUsers,
    monthlyActiveUsers: monthlyActiveUsersData?.numberOfActiveUsers,
    thirtyDaysUserTransactionCount: thirtyDaysUserTransactionCountData?.thirtyDaysUserTransactionCount,
    chainsNumberOfUsersHistory: chainsNumberOfUsersHistoryData?.chainsNumberOfUsersHistory,
    chainNewUsersHistory: chainNewUsersHistoryData?.chainNewUsersHistory,
    chainActiveUsersByCategory: chainActiveUsersByCategoryData?.chainActiveUsersByCategory,
    usersMonthlyRetentionRate: usersMonthlyRetentionRateData?.usersMonthlyUsersRetentionRate,
    isLoadingDailyActiveUsers,
    isLoadingMonthlyActiveUsers,
    isLoadingThirtyDaysUserTransactionCount,
    isLoadingChainsNumberOfUsersHistory,
    isLoadingChainNewUsersHistory,
    isLoadingChainActiveUsersByCategory,
    isLoadingUsersMonthlyRetentionRate,
  };
};
