import { FC } from "react";
import { Box, Typography } from "@frontend/ui";

export type InfoItemProps = {
  title: string;
  value: string | number | JSX.Element;
};

const InfoItem: FC<InfoItemProps> = ({ title, value }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="caption" component="span" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {title}
    </Typography>
    <Typography fontSize={14} width="100%" overflow="hidden" textOverflow="ellipsis">
      {value}
    </Typography>
  </Box>
);

export default InfoItem;
