import dayjs from "dayjs";
import mean from "lodash/mean";
import { ValueOverTime } from "src/pages/asset-listing-portal/generated";
import { Rating } from "../../../components/score-badge";

export const getDaysSince = (timestamp: number) => dayjs().diff(dayjs(timestamp), "day");

export const getRatingsAverage = (ratings: Rating[]) => {
  const ratingsTotalValue = ratings.reduce((acc, rating) => acc + rating, 0);
  const ratingsAvgValue = Math.floor(ratingsTotalValue / ratings.length);

  const ratingKey = Object.keys(Rating)[Object.values(Rating).indexOf(ratingsAvgValue)] as keyof typeof Rating;

  return Rating[ratingKey];
};

export const getAvgVolume = (volumeUsdHistory: ValueOverTime[]) => {
  const values = volumeUsdHistory.map((v) => v.value);
  const volumeUsd30dValues = values.slice(-30);
  const volumeUsd90dValues = values.slice(-90);

  const volumeAverage30d = mean(volumeUsd30dValues);
  const volumeAverage90d = mean(volumeUsd90dValues);

  return {
    volumeAverage30d,
    volumeAverage90d,
  };
};
