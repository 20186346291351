import { Box, Paper, Typography } from "@frontend/ui";
import { ChartWidget } from "@frontend/ui/chart-widget";
import dayjs from "dayjs";
import { SentimentInfo } from "../../generated";
import { valueOverTimeToArr } from "./utils";

type Props = {
  socialInfo: SentimentInfo;
};

const SentimentAnalysis = ({ socialInfo }: Props) => {
  const showSentiment = socialInfo.twitterFollowers90d?.length || socialInfo.twitterMentions90d?.length;

  if (!showSentiment) return null;

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box display="flex" gap={2} alignItems="center">
        <Typography variant="h3">Sentiment Analysis</Typography>
        {socialInfo.sentimentPositivePercentage && (
          <Box
            bgcolor="green.opacity50"
            borderRadius={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            px={1}
          >
            <Typography variant="h6">Positive - {socialInfo.sentimentPositivePercentage}%</Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" gap={3}>
        <Box flex={1} width={0}>
          <ChartWidget
            type="line"
            series={{
              data: valueOverTimeToArr(socialInfo.twitterFollowers90d || []),
              color: "#23AED2",
              name: "Followers",
              showArea: true,
            }}
            tooltipHeaderFormatter={(value) => dayjs(value).format("DD/MM/YYYY")}
            title="Twitter Followers"
            emptyState={!socialInfo.twitterFollowers90d?.length}
            chartHeight={100}
            xAxisOptions={{
              axisLabel: {
                fontSize: 12,
                formatter: (v: string) => dayjs(Number(v)).format("DD/MM"),
              },
            }}
            yAxisOptions={{
              min: "dataMin",
              max: "dataMax",
            }}
          />
        </Box>
        <Box flex={1} width={0}>
          <ChartWidget
            series={{
              data: valueOverTimeToArr(socialInfo.twitterMentions90d || []),
              color: "#23AED2",
              name: "Mentions",
              showArea: true,
            }}
            tooltipHeaderFormatter={(value) => dayjs(value).format("DD/MM/YYYY")}
            type="line"
            title="Twitter Mentions"
            emptyState={!socialInfo.twitterMentions90d?.length}
            chartHeight={100}
            xAxisOptions={{
              axisLabel: {
                fontSize: 12,
                formatter: (v: string) => dayjs(Number(v)).format("DD/MM"),
              },
            }}
            yAxisOptions={{
              min: "dataMin",
              max: "dataMax",
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default SentimentAnalysis;
