import { Chain } from "../../generated";
import { CCARClientConfig } from "../types";

export const radiantConfig: CCARClientConfig = {
  endpointsPrefix: "radiant-v2",
  marketType: "GeneralMarket",
  chains: [Chain.Arbitrum, Chain.Bnb, Chain.Ethereum, Chain.Base],
  riskThreshold: 1.2,
  eligibleThreshold: 1,
  isMultichain: true,
  isMultiMarket: true,
  overview: {
    cards: ["totalSupplyUsd", "totalBorrowUsd", "totalValueLockedUsd", "totalValueAtRiskUsd"],
    charts: ["historyStatsTvl", "chainHistoryStats"],
    chainTableConfig: {
      columns: [
        "chain",
        "totalSupplyUsd",
        "totalBorrowUsd",
        "totalValueLockedUsd",
        "totalValueAtRiskUsd",
        "activeUsers",
      ],
    },
  },
  wallets: {
    hiddenRows: {
      assetSupply: true,
      assetBorrows: true,
      liquidity: true,
      badDebt: true,
    },
  },
  walletDetails: {
    tables: ["totalSupplyAndBorrow", "recentEvents"],
  },
  marketDetails: {
    tables: ["wallets", "assetEvents"],
    charts: ["collateralAtRisk", "supplyWalletsHealth", "borrowWalletsHealth", "marketsDistribution", "topWallets"],
    liquidationThresholdLabel: "Liquidation Threshold",
  },
  liquidations: {
    showLiquidationPie: false,
    showLiquidationEvents: true,
  },
  riskExplorer: {
    liquidationThresholdLabel: "Liquidation Threshold",
    showAdvancedSettings: true,
  },
  markets: {
    overview: {
      showEventsTable: true,
    },
  },
  alerts: {
    enabled: true,
    notificationsChannels: [
      {
        channel: "telegram",
        link: "https://t.me/+s4ZW45faxoxhNWQx",
      },
    ],
  },
};
