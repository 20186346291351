import { Box, Typography } from "@frontend/ui";
import { Link, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";

const ExperimentSectionHeader = () => {
  const { clientName } = useParams();

  const methodologyPath = RoutePath.ParameterRecommendations.Methodology.replace(RouteParams.ClientName, clientName!);

  return (
    <Box>
      <Typography variant="h2">Experiment</Typography>
      <Typography>
        Chaos Labs runs thousands of agent-based blockchain simulations with different market conditions to discover the
        optimal set of parameters for minimizing the risk and maximizing protocol profit. We use the Chaos Utility Ratio
        to balance risk and profit in an optimal way.{" "}
        <Link to={methodologyPath} style={{ textDecoration: "none", display: "inline-block" }}>
          <Typography color="turqouise.main">Discover our methodology</Typography>
        </Link>
      </Typography>
    </Box>
  );
};

export default ExperimentSectionHeader;
