import { formatAmount, getFractionDigits } from "@frontend/ui/utils/formatters";

export type ConfidenceInterval = {
  min: number;
  mean: number;
  max: number;
  interval: number;
};

export const parseIntervalString = (interval: string): ConfidenceInterval => {
  const splitted = interval.split("±").filter((el) => !!el);

  if (splitted.length !== 2) {
    return {
      min: 0,
      mean: 0,
      max: 0,
      interval: 0,
    };
  }

  const [mean, intervalDiff] = splitted;

  return {
    min: Number(mean) - Number(intervalDiff),
    mean: Number(mean),
    max: Number(mean) + Number(intervalDiff),
    interval: Number(intervalDiff),
  };
};

export const confidenceIntervalToString = (confidenceInterval: string, currency?: string): string => {
  const { mean: confidenceIntervalMean, interval } = parseIntervalString(confidenceInterval);

  const fractionDigits = Math.max(2, getFractionDigits(confidenceIntervalMean));

  const intervalStr = interval
    ? ` ± ${formatAmount(interval, { notation: "compact", maximumFractionDigits: fractionDigits })}`
    : "";

  return `${formatAmount(confidenceIntervalMean, {
    notation: "compact",
    maximumFractionDigits: fractionDigits,
    currency,
  })}${intervalStr}`;
};
