import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { usePriceFeedsContext } from "../../context/price-feeds-context";
import { PriceFeedTimeSpan, useProvidersSelectContext } from "../../context/providers-select-context";
import { Endpoints, useApiRequest } from "../../api";
import { Interpolation, Params } from "../../api/types";

const timeSpanToHours: Record<PriceFeedTimeSpan, number> = {
  [TimeSpan.Day]: 24,
  [TimeSpan.Week]: 24 * 7,
  [TimeSpan.Month]: 24 * 30,
};

type ParamsByEndpoint = {
  [T in Endpoints]: { params: Params<T>; interpolations: Interpolation<T>; skip?: boolean };
};

const useApiParamsByEndpoint = (timeSpan: PriceFeedTimeSpan): ParamsByEndpoint => {
  const { feedId } = useParams();
  const { selectedProviders, selectedBaselineProvider } = useProvidersSelectContext();

  const timeSpanParams = useMemo(
    () => ({
      start_time: dayjs().subtract(timeSpanToHours[timeSpan], "hours").toISOString(),
      end_time: dayjs().toISOString(),
    }),
    [timeSpan],
  );

  const allProvidersParams = useMemo(
    () => ({
      providers: (selectedBaselineProvider ? [selectedBaselineProvider, ...(selectedProviders || [])] : []).map(
        (p) => p.id,
      ),
      ...timeSpanParams,
    }),
    [selectedBaselineProvider, selectedProviders, timeSpanParams],
  );

  const providersParams = useMemo(
    () => ({
      providers: (selectedProviders || []).map((p) => p.id),
      ...timeSpanParams,
    }),
    [selectedProviders, timeSpanParams],
  );

  const oraclesParams = useMemo(
    () => ({
      providers: (selectedProviders || []).filter((p) => p.type === "oracle").map((p) => p.id),
      ...timeSpanParams,
    }),
    [selectedProviders, timeSpanParams],
  );

  const exchangesParams = useMemo(
    () => ({
      providers: (selectedProviders || []).filter((p) => p.type === "exchange").map((p) => p.name),
      ...timeSpanParams,
    }),
    [selectedProviders, timeSpanParams],
  );

  const assetBaseline = useMemo(
    () => ({
      symbol: feedId!,
      baseline: selectedBaselineProvider?.name || "",
    }),
    [feedId, selectedBaselineProvider],
  );

  const pathAsset = useMemo(
    () => ({
      symbol: feedId!,
    }),
    [feedId],
  );

  const paramsByEndpoint = useMemo(
    () => ({
      [Endpoints.PriceFeeds]: {
        params: undefined,
        interpolations: undefined,
      },
      [Endpoints.PriceFeedPrices]: {
        params: allProvidersParams,
        interpolations: pathAsset,
      },
      [Endpoints.PriceFeedLatencyStatistics]: {
        params: providersParams,
        interpolations: assetBaseline,
      },
      [Endpoints.PriceFeedDeviationStatistics]: {
        params: providersParams,
        interpolations: assetBaseline,
      },
      [Endpoints.PriceFeedDeviationFromPriceIndex]: {
        params: providersParams,
        interpolations: pathAsset,
      },
      [Endpoints.PriceFeedDeviationFromBaseline]: {
        params: providersParams,
        interpolations: assetBaseline,
      },
      [Endpoints.PriceFeedDeviationDistribution]: {
        params: providersParams,
        interpolations: assetBaseline,
      },
      [Endpoints.PriceFeedVolumes]: {
        params: exchangesParams,
        interpolations: pathAsset,
        skip: !exchangesParams.providers.length,
      },
      [Endpoints.PriceFeedVwap]: {
        params: providersParams,
        interpolations: pathAsset,
      },
      [Endpoints.PriceFeedGrangerCausality]: {
        params: oraclesParams,
        interpolations: assetBaseline,
        skip: !oraclesParams.providers.length,
      },
      [Endpoints.FeedPrice]: {
        params: { timestamp: dayjs().subtract(timeSpanToHours[timeSpan], "hours").toISOString() },
        interpolations: pathAsset,
      },
    }),
    [allProvidersParams, pathAsset, providersParams, assetBaseline, exchangesParams, oraclesParams, timeSpan],
  );

  return paramsByEndpoint;
};

export const useFeedData = <T extends Endpoints = Endpoints>(endpoint: T) => {
  const { isLoading: isLoadingFeeds } = usePriceFeedsContext();
  const [timeSpan, setTimeSpan] = useState<PriceFeedTimeSpan>(TimeSpan.Week);
  const paramsByEndpoint = useApiParamsByEndpoint(timeSpan);
  const { params, interpolations, skip } = useMemo(() => paramsByEndpoint[endpoint], [paramsByEndpoint, endpoint]);
  const { isLoading, response } = useApiRequest(endpoint, params, interpolations, !isLoadingFeeds && !skip);

  const data = useMemo(
    () => ({
      response,
      isLoading: isLoading || isLoadingFeeds,
      timeSpan,
      setTimeSpan,
    }),
    [response, isLoading, isLoadingFeeds, timeSpan, setTimeSpan],
  );

  return data;
};
