import { Box, Paper, ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { TimeSpanPicker } from "src/pages/ccar-lending-page/components/time-span-picker";
import {
  PriceFeedTimeSpan,
  priceFeedTimeSpanOptions,
  useProvidersSelectContext,
} from "src/pages/oracle-portal/context/providers-select-context";
import { Endpoints } from "../../../api";
import { useFeedData } from "../use-price-feed-details";

const PriceHistoryChart = () => {
  const { providerNameById } = useProvidersSelectContext();
  const { response: prices, isLoading, timeSpan, setTimeSpan } = useFeedData(Endpoints.PriceFeedPrices);

  const series = (prices ? [...prices] : [])
    .sort((a, b) => (a.type < b.type ? 1 : -1))
    .reduce<Record<string, NumberChartSeries>>((acc, point) => {
      const pointId = `${point.origin}-${point.type}`;
      const currentSeriesData = acc[pointId]?.data || [];
      const newSeries: NumberChartSeries = {
        id: pointId,
        label: providerNameById?.[point.id] || point.origin,
        data: [...currentSeriesData, [point.timestamp * 1000, point.price]] as [number, number][],
        type: "line",
      };
      return { ...acc, [pointId]: newSeries };
    }, {});

  return (
    <Paper variant="card">
      <Paper variant="widget" sx={{ flex: 1, display: "flex" }}>
        <Box flex={1} width={0}>
          <ValueOverTimeChart
            key={`prices-chart-${Object.keys(series).join("-")}`}
            title="Price History in USD"
            description="The historical price movements of the asset"
            series={Object.values(series)}
            currency="USD"
            isLoading={isLoading}
            yAxisMax
            yAxisMin
            zoomable
            timeSpan={timeSpan}
            suffix={
              <TimeSpanPicker<PriceFeedTimeSpan>
                selectedTimeSpan={timeSpan}
                onSelectTimeSpan={setTimeSpan}
                timeSpanOptions={priceFeedTimeSpanOptions}
                hideLabel
              />
            }
          />
        </Box>
      </Paper>
    </Paper>
  );
};

export default PriceHistoryChart;
