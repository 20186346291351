import { Header, RenderData } from "@frontend/types";
import { Tabs, ChaosTable, TokenPairCell } from "@frontend/ui";
import { formatCurrency } from "@frontend/ui/utils/formatters";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { MarketPairCenterType, MarketPairCategory, useMarketPairsLazyQuery, MarketPairsQuery } from "../../generated";

const canterTypeToggleValues = [MarketPairCenterType.Cex, MarketPairCenterType.Dex];

type Props = {
  coinmarketcapId: number;
};

const MarketsTable = ({ coinmarketcapId }: Props) => {
  const [centerTypeToggleIdx, setCenterTypeToggleIdx] = useState<number>(0);
  const isCenterTypeCex = centerTypeToggleIdx === 0;

  const [loadMarketPairs, { data, loading }] = useMarketPairsLazyQuery();

  const [marketPairs, setMarketPairs] = useState<MarketPairsQuery["marketPairs"]>();
  const [resetPagination, setResetPagination] = useState<string>();

  useEffect(() => {
    void loadMarketPairs({
      variables: {
        query: {
          coinmarketcapId,
          centerType: canterTypeToggleValues[centerTypeToggleIdx],
          category: MarketPairCategory.Spot,
        },
      },
    });
  }, [centerTypeToggleIdx, coinmarketcapId, loadMarketPairs]);

  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Exchange",
      width: "15%",
    },
    {
      renderType: "TEXT",
      text: "Pair",
      width: "25%",
    },
    {
      renderType: "TEXT",
      text: "Price",
    },
    ...((isCenterTypeCex
      ? [
          {
            renderType: "TEXT",
            text: "+2% Order book depth",
          },
          {
            renderType: "TEXT",
            text: "-2% Order book depth",
          },
        ]
      : [
          {
            renderType: "TEXT",
            text: "TVL",
          },
        ]) as Header[]),
    {
      renderType: "TEXT",
      text: "24H Volume",
    },
  ];

  useEffect(() => {
    if (data) {
      setMarketPairs(data.marketPairs);
      setResetPagination(uuidv4());
    }
  }, [data]);

  const tableData: RenderData[][] = (marketPairs || []).map(
    (row) =>
      [
        {
          renderType: "TEXT",
          text: row.exchangeName,
        },
        {
          renderType: "CUSTOM",
          text: "",
          component: <TokenPairCell token1={row.baseSymbol} token2={row.quoteSymbol} />,
        },
        {
          renderType: "TEXT",
          text: formatCurrency(row.price),
          value: row.price,
        },
        ...(isCenterTypeCex
          ? [
              {
                renderType: "TEXT",
                text: row.depthUsdPositiveTwo ? formatCurrency(Math.round(row.depthUsdPositiveTwo)) : "-",
                value: row.depthUsdPositiveTwo || 0,
              },
              {
                renderType: "TEXT",
                text: row.depthUsdNegativeTwo ? formatCurrency(Math.round(row.depthUsdNegativeTwo)) : "-",
                value: row.depthUsdNegativeTwo || 0,
              },
            ]
          : [
              {
                renderType: "TEXT",
                text: row.liquidity ? formatCurrency(Math.round(row.liquidity)) : "-",
                value: row.liquidity || 0,
              },
            ]),
        {
          renderType: "TEXT",
          text: formatCurrency(Math.round(row.volumeUsd)),
          value: row.volumeUsd,
        },
      ] as RenderData[],
  );

  const marketCenterTypeToggle = (
    <Tabs
      value={centerTypeToggleIdx}
      tabs={[{ label: "CEX" }, { label: "DEX" }]}
      onChange={(_, i) => {
        setCenterTypeToggleIdx(i);
      }}
    />
  );

  return (
    <ChaosTable
      isLoading={loading}
      headers={headers}
      data={tableData}
      title="Markets"
      titleSuffixComponent={marketCenterTypeToggle}
      isFilterHidden
      pageSize={10}
      resetPagination={resetPagination}
      emptyState={{
        title: "No Data",
      }}
    />
  );
};

export default MarketsTable;
