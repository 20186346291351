import { Route, Routes } from "react-router-dom";
import { RoutePath } from "src/config/routes";
import { CCARPerpsTabsLayout } from "src/pages/ccar-perpetuals-page/ccar-perpetuals-tabs-layout";
import Markets from "../markets";
import { replaceHomePath, DefaultMarketPageTabRedirect, renderMarketPageLayout } from "./layout";
import { MarketDetails } from "../market-details";
import { GmPoolDetails } from "../pages";

const MarketsRoutes = () => (
  <Routes>
    <Route
      index
      element={
        <CCARPerpsTabsLayout>
          <Markets />
        </CCARPerpsTabsLayout>
      }
    />
    <Route path={replaceHomePath(RoutePath.CCARPerpetuals.MarketDetails)} element={<DefaultMarketPageTabRedirect />} />
    <Route
      path={replaceHomePath(RoutePath.CCARPerpetuals.MarketDetailsOverview)}
      element={renderMarketPageLayout(<MarketDetails />)}
    />
    <Route
      path={replaceHomePath(RoutePath.CCARPerpetuals.MarketDetailsPool)}
      element={renderMarketPageLayout(<GmPoolDetails />)}
    />
  </Routes>
);

export default MarketsRoutes;
