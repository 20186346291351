import { Box } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { usePublicSimulationsData } from "src/pages/parameter-recommendations-page/hooks/use-public-simulations-data";
import { GeneralInfoCard, ObserversSection } from "./components";
import { useAssetRecommendations } from "../use-asset-recommendations";

const SimulationResults = () => {
  const { simulationResultId } = useParams();
  const { isLoading, ltParam } = useAssetRecommendations();

  const simulationResultQuery = useMemo(
    () => ({
      simulationResultId: simulationResultId!,
      simulationId: ltParam?.result?.modelRunId,
    }),
    [simulationResultId, ltParam],
  );

  const { isLoading: isLoadingSimulationResults, response } = usePublicSimulationsData({
    endpoint: "simulation_result",
    query: simulationResultQuery,
    sendRequest: !!ltParam,
  });

  if (!ltParam || isLoading || isLoadingSimulationResults || !response?.simulationSnapshot) return <Loader />;

  return (
    <Box display="flex" flexWrap="nowrap" flexDirection="column" height="100%" width="100%" gap={3}>
      <GeneralInfoCard permutationInfo={response.permutation} simulation={response.simulationSnapshot} />
      <ObserversSection
        observations={response.result.observations}
        observers={response.simulationSnapshot.observers}
        observerGroupsMetadata={response.simulationSnapshot.observerGroupsMetadata}
        iterationsRange={[0, response.simulationSnapshot.iterations || 0]}
      />
    </Box>
  );
};

export default SimulationResults;
